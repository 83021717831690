import React from 'react'
import LeftNav from "../LeftNav";

export default function Sws() {
  return (
    <div className='sws-page'>
       <div className='sws-left-nav'>
          <LeftNav/>
       </div>
       <div className='sws-container'>
          <h1>Sws</h1>  
       </div>
    </div>
  )
}
