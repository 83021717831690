import React from 'react'
import LeftNav from "../LeftNav";

export default function Multipages() {
  return (
    <div className='multipages-page'>
       <div className='multipages-left-nav'>
          <LeftNav/>
       </div>
       <div className='multipages-container'>
          <h1>pages</h1>  
       </div>
    </div>
  )
}

