import React from 'react'
import LeftNav from "../LeftNav";

export default function Fans() {
  return (
    <div className='fans-page'>
       <div className='fans-left-nav'>
          <LeftNav/>
       </div>
       <div className='fans-container'>
          <h1>Fans</h1>  
       </div>
    </div>
  )
}
