import React , {useState} from 'react';
import { useSelector } from "react-redux";
import ModaleInf from "../ModalInf";

export default function Configuration() {
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const usersData = useSelector((state) => state.usersReducer);
  const [users, setUsers] = useState(usersData);
  const [dataToPropsModal, setDataToPropsModal] = useState({});
  const [superAdminModal, setSuperAdminModal] = useState(false);

    const handleModals = (val) => {
      setSuperAdminModal(true);
      setDataToPropsModal(val)
  };

  const calculeSouFP = (user) => {
    const array = user.friendsPartner.map((val , index) => (
       usersData && usersData.filter(valeur => 
            valeur._id === val 
     )
   )).filter(valeur1 => valeur1[0]._id !==  "6373a5ce14e0fdc176fa803c")
     .map((val1 , index) => (  val1[0].friends.length ))
     .reduce( (accumulator, currentValue) => accumulator + currentValue , 0)
 
   return array
 
   }

  const handlerFilter = (e) => {
    setUsers( 
   usersData && usersData.filter((el) =>(el.nom + " " + el.prenom).toUpperCase().includes(e.target.value.toUpperCase()))
       )
   }
   


const totaleInscPerDay = () => {
  const dateCondition = (new Date(new Date().setDate(new Date().getDate() - 1))).toLocaleDateString("en-GB").split("/").reverse().join("-")
  const dateNow = new Date().toLocaleDateString("en-GB").split("/").reverse().join("-")
  const result = usersData.filter(val => (val.createdAt.split("/").reverse().join("-").slice(0, 10) <= dateNow) &&  (dateCondition <= val.createdAt.split("/").reverse().join("-").slice(0, 10)) );
  return result
}

const totaleInscPerWeek = () => {
  const dateCondition = (new Date(new Date().setDate(new Date().getDate() - 7))).toLocaleDateString("en-GB").split("/").reverse().join("-")
  const dateNow = new Date().toLocaleDateString("en-GB").split("/").reverse().join("-")
  const result = usersData.filter(val => (val.createdAt.split("/").reverse().join("-").slice(0, 10) <= dateNow) &&  (dateCondition <= val.createdAt.split("/").reverse().join("-").slice(0, 10)) );
  return result
}

const totaleInscPerMonth = () => {
  const dateCondition = (new Date(new Date().setMonth(new Date().getMonth() - 1))).toLocaleDateString("en-GB").split("/").reverse().join("-")
  const dateNow = new Date().toLocaleDateString("en-GB").split("/").reverse().join("-")
  const result = usersData.filter(val => (val.createdAt.split("/").reverse().join("-").slice(0, 10) <= dateNow) &&  (dateCondition <= val.createdAt.split("/").reverse().join("-").slice(0, 10)) );
  return result
}

  const totaleInsc = () => {
    return usersData && usersData.length
  }



  const filterdate = () => {
    const result = usersData.filter(val => ((val.createdAt.split("/").reverse().join("-").slice(0, 10)  <= date2) &&  (date1 <= val.createdAt.split("/").reverse().join("-").slice(0, 10))) );
    return setUsers(result)
  }
  
  return (

    <div className='super-admin-config-back'>

      <section className='compter-d-w-m'>
        <div className="super-admin-list-amie-flex-box">
          <div onClick={() => setUsers(totaleInscPerDay())}
               className='superAdmin-box-label'><span>Inscription par jour</span></div>
          <div className='superAdmin-box-value'>{totaleInscPerDay().length}</div>
        </div>
        <div className="super-admin-list-amie-flex-box">
          <div onClick={() => setUsers(totaleInscPerWeek())}
               className='superAdmin-box-label'><span>Inscription par semaine</span></div>
          <div className='superAdmin-box-value'>{totaleInscPerWeek().length}</div>
        </div>
        <div className="super-admin-list-amie-flex-box">
          <div onClick={() => setUsers(totaleInscPerMonth())}
               className='superAdmin-box-label'><span>Inscription par mois</span></div>
          <div className='superAdmin-box-value'>{totaleInscPerMonth().length} </div>
        </div>
        <div className="super-admin-list-amie-flex-box">
          <div onClick={() => setUsers(usersData)}
               className='superAdmin-box-label'><span>Inscription totale</span> </div>
          <div className='superAdmin-box-value'>{totaleInsc()}</div>  
        </div>
      </section>
      
      <section className='count-date-box'>
      <input onChange={handlerFilter} className='recherche' placeholder='recherche' type="text"/>

      <section className='count-date'>
        <div className='container'>
         <input className='count-year-start'
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                placeholder='de'
                type="text"
                name="date1"
                id="date1"
                onChange={(e) => setDate1(e.target.value)}
                value={date1}/>
         <input className='count-year-end'
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                placeholder='a'
                type="text"
                name="date2"
                id="date2"
                onChange={(e) => setDate2(e.target.value)}
                value={date2}/>
          <div className='filter-date'
                onClick={() => filterdate()} 
                >Filter date</div>      
        </div>
      </section>
      </section>

<table>
  <thead>
  <tr>
    <th>Order</th>
    <th>Nom Utilisateur</th>
    <th>Date d’inscription</th>
    <th>Ami Parrain </th>
    <th>Nbr amis</th>
    <th>Nbr FP</th>
    <th>Ut sous FP</th>
    <th>Objectif</th>
    <th>Nbr Fans abonnés</th>
  </tr>
  </thead>

  <tbody>
  {users.map((val , index)=>(
  <tr onClick={() => handleModals(val)}  key={index}>
    <td className='tr-td-1' 
     >{Number(index) + 1}</td>
    <td className='tr-td-2'>{val.nom} {" "}  {val.prenom}</td>
    <td className='tr-td-3'>{val.createdAt.split('').slice(0, 10)} {" - "} {val.createdAt.split('').slice(11, 16)}</td>
    <td className='tr-td-2'>
    {usersData && usersData.filter((val1) => val1._id === (val.friends[0]))[0].nom}
    </td>
    <td className='tr-td-3'>{val.friends.length}</td>
    <td className='tr-td-3'>{val.friendsPartner.length} </td>
    <td className='tr-td-3'>{calculeSouFP(val)}</td>
    <td className='tr-td-3'>
      <div className='objective-box'>
        <div className='objective'>1000</div>
        <div>{Number(calculeSouFP(val) / 10) } %</div>
      </div>
    </td>
    <td className='tr-td-3'></td>
    </tr>
  ))}
  </tbody>
 </table>
 {superAdminModal && <ModaleInf userData={dataToPropsModal}   openPopup={(e) => setSuperAdminModal(e)}/>}
 </div>
  )
}
