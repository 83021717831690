import React, { useState } from "react";
import axios from "axios";


const AssistanceUser = ({ openPopup }) => {

  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [sujet, setSujet] = useState("");

  const handleAssistance = async (e) => {
    e.preventDefault();
    const getnom = document.getElementById("nom-box-input").value;
    const nomError = document.querySelector(".nom-box-input.error");

    const getprenom = document.getElementById("prenom-box-input").value;
    const prenomError = document.querySelector(".prenom-box-input.error");

    const getemail = document.getElementById("assistance-box-input").value;
    const emailError = document.querySelector(".email-box-input.error");

    const gettextarea = document.getElementById("textarea-box-input").value;
    const textareaError = document.querySelector(".textarea-box-input.error");

    nomError.innerHTML = "";
    prenomError.innerHTML = "";
    emailError.innerHTML = "";
    textareaError.innerHTML = "";

    if ( getemail === ""  || gettextarea === "" || getnom === ""  || getprenom === "") {

      if (  getnom === "" ) {
        nomError.innerHTML = "Vous devez entrer nom";
      }
      if (  getprenom === "" ) {
        prenomError.innerHTML = "Vous devez entrer prénom";
      }
     
     if (  getemail === "" ) {
           emailError.innerHTML = "Vous devez entrer E-mails";
     }
     if (  gettextarea === "" ) {
           textareaError.innerHTML = "Vous devez entrer Sujet";
     }
         
    } else {
      await axios({
        method: "post",
        url: `/api/user/assistance`,
        data: {
          nom,
          prenom,
          email,
          sujet
        },
      })
        .then(openPopup(false))
        .catch((err) => console.log(err))
    }
  };


  return (
    <>
      <div className="popup-assistance-container">
        <div className="modal">

          <span className="cross" onClick={() => openPopup(false)}>
            &#10005;
          </span>
          <form action="" onSubmit={handleAssistance} >
          <div className="assistance-box">
            <h4>Assistance Utilisiateur</h4>
            <input className="assistance-box-input" 
                   id="nom-box-input"
                   onChange={(e) => setNom(e.target.value)}
                   value={nom}
                   placeholder="Nom"/>
            <div className="nom-box-input error general-font-family-error"></div>
            <input className="assistance-box-input" 
                   id="prenom-box-input"
                   onChange={(e) => setPrenom(e.target.value)}
                   value={prenom}
                   placeholder="Prénom"/>
            <div className="prenom-box-input error general-font-family-error"></div>
            <input className="assistance-box-input" 
                   id="assistance-box-input"
                   onChange={(e) => setEmail(e.target.value)}
                   value={email}
                   placeholder="Email"/>
            <div className="email-box-input error general-font-family-error"></div>
            <textarea className="assistance-box-space-area" 
                      id="textarea-box-input"
                      onChange={(e) => setSujet(e.target.value)}
                      value={sujet}
                      placeholder="Sujet"/>  
            <div className="textarea-box-input error general-font-family-error"></div>
            <div className="envoyer-assistance-box">
                <div onClick={handleAssistance} className="button-assistance-box" type="submit">Envoyer</div>  
            </div>
          </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AssistanceUser;
