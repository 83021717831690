import React , {useState} from "react";
import { useSelector } from "react-redux";

const ModaleInf = ({ openPopup , userData }) => {
  const usersData = useSelector((state) => state.usersReducer);

  const calculeSouFP = (user) => {
    const array = user.friendsPartner.map((val , index) => (
       usersData && usersData.filter(valeur => 
            valeur._id === val 
     )
   )).filter(valeur1 => valeur1[0]._id !==  "6373a5ce14e0fdc176fa803c")
     .map((val1 , index) => (  val1[0].friends.length ))
     .reduce( (accumulator, currentValue) => accumulator + currentValue , 0)
 
   return array
 
   }

  return (
    <>
      <div className="popup-inf-container">
        <div className="modal-inf-user">
            <span className="information-user-cross" onClick={() => openPopup(false)}>
              &#10005;
            </span>
            <div className="copie-icone-box-title">
               <h4>Suivi d’avancement</h4>
            </div>
        
        <section className="information-inf-body">
        <div className="information-user-body1">
            <div className="information-user-body1-label">
              <h5 className="information-user-body1-label1">Nom : </h5>
              <h5 className="information-user-body1-label2">{userData.nom}</h5>
            </div>
            <div className="information-user-body1-label">
              <h5 className="information-user-body1-label1">Prénom : </h5>
              <h5 className="information-user-body1-label2">{userData.prenom}</h5>
            </div>
            <div className="information-user-body1-label">
              <h5 className="information-user-body1-label1">Date de naissance : </h5>
              <h5 className="information-user-body1-label2">{userData.naissance}</h5>
            </div>
            <div  className="information-user-body1-label">
              <h5 className="information-user-body1-label1">ID Parrain : </h5>
              <h5 className="information-user-body1-label2">{ usersData && usersData.filter((val1) => val1._id === (userData.friends[0]))[0].nom }</h5>
            </div>
            <div className="information-user-body1-label">
              <h5 className="information-user-body1-label1">Nombre d’amis : </h5>
              <h5 className="information-user-body1-label2">{userData.friends.length}</h5>
            </div>
            <div className="information-user-body1-label">
              <h5 className="information-user-body1-label1">Utilisateur sous FP : </h5>
              <h5 className="information-user-body1-label2">{calculeSouFP(userData)}</h5>
            </div>
            <div className="information-user-body1-label">
              <h5 className="information-user-body1-label1">Nombre fans abonnés  :</h5>
              <h5 className="information-user-body1-label2"></h5>
            </div>
            <div style={{width: "100%" , display : "flex" , justifyContent: "space-between"}}>
            <div className="information-user-body1-label">
              <h5 className="information-user-body1-label1">Objectif :  </h5>
              <h5 className="information-user-body1-label2">1 000</h5>
            </div>
            <div className="information-user-body1-label">
              <h5 className="information-user-body1-label1">Niveau d’avancement : </h5>
              <h5 className="information-user-body1-label2">{Number(calculeSouFP(userData) / 10) } %</h5>
            </div>
            </div>

            <div style={{width: "100%" , display : "flex" , justifyContent: "flex-end"}}>
            <div className="information-envoyer-body1-label">
               Envoyer
            </div>
            <div className="information-envoyer-body1-label">
                Imprimer
            </div>
            </div>

          </div>
        </section>
        
        </div>
      </div>
    </>
  );
};

export default ModaleInf;
