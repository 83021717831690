import React from 'react'
import LeftNav from "../LeftNav";

export default function Groups() {
  return (
    <div className='primes-page'>
       <div className='primes-left-nav'>
          <LeftNav/>
       </div>
       <div className='primes-container'>
          <h1>Primes</h1>  
       </div>
    </div>
  )
}
