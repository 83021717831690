import axios from "axios";

export const ADD_FRIEND = "ADD_FRIEND";



export const AddFriend = (idFriend , idUser) => {
    return (dispatch) => {
      return axios
        .post(`/api/user/addFriend`, {idFriend , idUser})
    };
  };