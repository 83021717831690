import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import UserList from "./UserList";
import axios from "axios";

export default function Signup({ closePopup }) {

  const [idpartner, setIdpartner] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [naissance, setNaissance] = useState("");
  const [adresse, setAdresse] = useState("");
  const [mobile, setMobile] = useState("");
  const [situation, setSituation] = useState("");
  const [pays, setPays] = useState("");
  const [langue, setLangue] = useState("");
  const [genre, setGenre] = useState("");

  const [password, setPassword] = useState("");
  const [controlPassword, setControlPassword] = useState("");
  const [codeparrain, setCodeParrain] = useState(window.location.href.split('').indexOf("#") === -1 ? "NoCodeParrain" : window.location.href.slice(window.location.href.split('').indexOf("#") + 2));
  const [userListModal, setUserListModal] = useState(false);

  const handleModals = (e) => {
    if (e.target.id === "freindparrain") {
      setUserListModal(true);
    }
  };

  const handleChangeSituation = (event) => {
    setSituation(event.target.value);
  };

  const handleChangePays = (event) => {
    setPays(event.target.value);
  };

  const handleChangeLangue = (event) => {
    setLangue(event.target.value);
  };

  const handleChangeGenre = (event) => {
    setGenre(event.target.value);
  };

  

  const handleNext = async (e) => {
    e.preventDefault();
    const getnom = document.getElementById("nom").value;
    const nomError = document.querySelector(".nom.error");
    const getprenom = document.getElementById("prenom").value;
    const prenomError = document.querySelector(".prenom.error");
    const getemail = document.getElementById("email").value;
    const emailError = document.querySelector(".email.error");
    // const getnaissance = document.getElementById("naissance").value;
    // const naissanceError = document.querySelector(".naissance.error");
    const getadresse = document.getElementById("adresse").value;
    const adresseError = document.querySelector(".adresse.error");
    const getmobile = document.getElementById("mobile").value;
    const mobileError = document.querySelector(".mobile.error");
    const passwordError = document.querySelector(".password.error");
    const passwordConfirmError = document.querySelector(
      ".password-confirm.error"
    );
    const getsituation = document.getElementById("situation").innerText;
    const situationError = document.querySelector(".situation.error");
    const terms = document.getElementById("terms");
    const termsError = document.querySelector(".terms.error");
    const getpays = document.getElementById("pays").innerText;
    const paysError = document.querySelector(".pays.error");
    const getlangue = document.getElementById("langue").innerText;
    const langueError = document.querySelector(".langue.error");
    const getGenre = document.getElementById("Genre").innerText;
    const GenreError = document.querySelector(".Genre.error");

    nomError.innerHTML = "";
    prenomError.innerHTML = "";
    emailError.innerHTML = "";
    // naissanceError.innerHTML = "";
    adresseError.innerHTML = "";
    mobileError.innerHTML = "";
    passwordConfirmError.innerHTML = "";
    termsError.innerHTML = "";
    situationError.innerText = "";
    paysError.innerText = "";
    langueError.innerText = "";
    GenreError.innerText = "";

    const dateConditionIscription = new Date(
      new Date().setFullYear(new Date().getFullYear() - 12)
    )
      .toLocaleDateString("en-GB")
      .split("/")
      .reverse()
      .join("-");

           // getnaissance > dateConditionIscription ||
           // getnaissance.split("").length !== 10 ||
    if (
      password === "" ||
      password !== controlPassword ||
      getnom === "" ||
      getprenom === "" ||
      getemail === "" ||
      getadresse === "" ||
      getmobile === "" ||
      !terms.checked ||
      getsituation === "Situation" ||
      getpays === "Pays" ||
      getlangue === "langue" ||
      getGenre === "Genre"  ||
      (idpartner === "" &&  codeparrain === "NoCodeParrain")
    ) {
      if (password !== controlPassword)
        passwordConfirmError.innerHTML = "mots de passe ne correspondent pas";
      if (password === "")
        passwordConfirmError.innerHTML = "Vous devez entrer mots de passe";
      if (getnom === "") nomError.innerHTML = "Vous devez entrer Nom";
      if (getprenom === "") prenomError.innerHTML = "Vous devez entrer Prenom";
      if (getemail === "") emailError.innerHTML = "Vous devez entrer E-mails";
      // if (getnaissance.split("").length !== 10)
      //   naissanceError.innerHTML = "Vous devez entrer Naissance correcte";
      // if (getnaissance > dateConditionIscription)
      //   naissanceError.innerHTML = "Votre âge doit être supérieure à 12 ans";
      if (getadresse === "")
        adresseError.innerHTML = "Vous devez entrer Adresse";
      if (getmobile === "") mobileError.innerHTML = "Vous devez entrer Mobile";
      if (!terms.checked)
        termsError.innerHTML = "Veuillez valider les conditions générales";
      if (getsituation === "Situation")
        situationError.innerText = "Vous devez entrer Situation";
      if (getpays === "pays") paysError.innerText = "Vous devez entrer Pays";
      if (getlangue === "langue")
        langueError.innerText = "Vous devez entrer langue";
      if (getGenre === "Genre")
        GenreError.innerText = "Vous devez entrer Genre";
      if (idpartner === "" &&  codeparrain === "NoCodeParrain") {
          alert("Vous devez sélectionner un ami")
      }
    } else {
      await axios({
        method: "post",
        url: `/api/user/register`,
        data: {
          nom,
          prenom,
          email,
          mobile,
          adresse,
          naissance,
          situation,
          pays,
          langue,
          password,
          codeparrain,
          genre,
          friends : idpartner._id
        },
      })
        .then((res) => {
            window.location.replace("https://buy.stripe.com/9AQ14x5iP83BeXK6os")
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className="SignUpForm">
      <div className="modal-overlay">
        <div className="modal-wrapper">
          <div className="modal">
            <div className="modal-header">
              <div className="modal-title" id="title">
                S'inscrire
              </div>
              <button
                type="button"
                className="modal-close-button"
                onClick={closePopup}
              >
                <span>&times;</span>
              </button>

            </div>
            <div className="modal-body">
                <div id="FirstBloc">
                  <div className="parrain">
                    { codeparrain === "NoCodeParrain" ? 
              (idpartner._id === undefined ? (
                <div>
                  <button
                    className="btn-connection"
                    onClick={handleModals}
                    id="freindparrain"
                  >
                    Ami P
                  </button>
                 </div> 
              ) : (
              <div className="parrien-amie-flex-inscr">
                <div className="parrien-amie-flex-inscr-box">
                   <img className="parrien-amie-flex-inscr-img" src="./img/random-user.png" alt="icon" />
                </div>   
                   <div>
                     <span id="freind">{idpartner.nom}</span>
                     {" "}
                     <span>{idpartner.prenom}</span>
                   </div>
              </div>
               )
              ) : <></>
              }
              {userListModal && <UserList openPopup={(e) => setUserListModal(e)} idPartner={(e) => setIdpartner(e)}/>}

              {/* {userListModal && <UserList openPopup={(e) => setUserListModal(e)} idPartner={(e) => setIdpartner(e)}/>} */}
             {/* /////////////////////////////////////////////////////////////////// */}


                  </div>
                  <form action="" onSubmit={handleNext} id="sign-up-form">
                  <div className="form-group">
                    <div className="bloc">
                      <div>
                        <div className="item">
                          <input
                            className="inputFiled"
                            type="text"
                            onChange={(e) => setNom(e.target.value)}
                            value={nom}
                            name="nom"
                            id="nom"
                            placeholder="Nom"
                          />
                          <div className="nom error general-error"></div>
                        </div>
                        <div className="item">
                          <input
                            className="inputFiled"
                            type="text"
                            name="prenom"
                            id="prenom"
                            onChange={(e) => setPrenom(e.target.value)}
                            value={prenom}
                            placeholder="Prénom"
                          />
                          <div className="prenom error general-error"></div>
                        </div>
                      </div>
                      <div>
                        {" "}
                        {/* <div className="item">
                          <input
                            className="inputFiled"
                            onFocus={(e) => (e.target.type = "date")}
                            onBlur={(e) => (e.target.type = "text")}
                            name="naissance"
                            id="naissance"
                            value={naissance}
                            onChange={(e) => setNaissance(e.target.value)}
                            placeholder="Date de naissance"
                          />
                          <div className="naissance error general-error"></div>
                        </div> */}
                        <div className="item">
                          <input
                            className="inputFiled"
                            type="text"
                            name="email"
                            id="email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            placeholder="E-mail"
                          />
                          <div className="email error general-error"></div>
                        </div>
                      </div>
                    </div>
                    <div className="bloc">
                      <div className="item">
                        <input
                          className="inputaddress"
                          type="text"
                          name="adresse"
                          id="adresse"
                          onChange={(e) => setAdresse(e.target.value)}
                          value={adresse}
                          placeholder="Adresse"
                        />
                        <div className="adresse error general-error"></div>
                      </div>{" "}
                    </div>
                    <div className="bloc">
                      <div className="item">
                        <input
                          className="inputFiled"
                          type="number"
                          name="mobile"
                          id="mobile"
                          onChange={(e) => setMobile(e.target.value)}
                          value={mobile}
                          placeholder="Mobile"
                        />
                        <div className="mobile error general-error"></div>
                      </div>
                    </div>
                    <div className="bloc-bis">
                      <div className="sub-bloc">
                        <div>
                          <Select
                            className="input-forme-grid4"
                            value={situation}
                            onChange={handleChangeSituation}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            id="situation"
                          >
                            <MenuItem value="" style={{ display: "none" }}>
                              <span>Situation</span>
                            </MenuItem>
                            <MenuItem value="Marie">Marié</MenuItem>
                            <MenuItem value="celibataire">Célibataire</MenuItem>
                            <MenuItem value="autre">Autre</MenuItem>
                          </Select>
                          <div className="situation error general-error"></div>
                        </div>
                        <div>
                          <Select
                            className="input-forme-grid4"
                            value={pays}
                            onChange={handleChangePays}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            id="pays"
                          >
                            <MenuItem value="" style={{ display: "none" }}>
                              <span>Pays</span>
                            </MenuItem>
                            <MenuItem value="Canada">Canada</MenuItem>
                            <MenuItem value="Royaume-Uni">Royaume-Uni</MenuItem>
                            <MenuItem value="Belgique">Belgique</MenuItem>
                            <MenuItem value="Monaco">Monaco</MenuItem>
                            <MenuItem value="Luxembourg">Luxembourg</MenuItem>
                            <MenuItem value="France">France</MenuItem>
                            <MenuItem value="Italie">Italie</MenuItem>
                            <MenuItem value="Espagne">Espagne</MenuItem>
                            <MenuItem value="Portugal">Portugal</MenuItem>
                            <MenuItem value="Allemagne">Allemagne</MenuItem>
                            <MenuItem value="USA">USA</MenuItem>
                            <MenuItem value="UAE">UAE</MenuItem>
                            <MenuItem value="KSA">KSA</MenuItem>
                            <MenuItem value="Tunisie">Tunisie</MenuItem>
                            <MenuItem value="Jordane">Jordane</MenuItem>
                          </Select>
                          <div className="pays error general-error"></div>
                        </div>
                      </div>
                      <div className="sub-bloc">
                        <div>
                          <Select
                            className="input-forme-grid4"
                            value={langue}
                            onChange={handleChangeLangue}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            id="langue"
                          >
                            <MenuItem value="" style={{ display: "none" }}>
                              <span>Langue</span>
                            </MenuItem>
                            <MenuItem value="Francais">Francais</MenuItem>
                            <MenuItem value="Anglais">Anglais</MenuItem>
                            <MenuItem value="Italien">Italien</MenuItem>
                            <MenuItem value="Suisse">Portugais</MenuItem>
                            <MenuItem value="Espagnol">Espagnol</MenuItem>
                            <MenuItem value="Allemagne">Allemand</MenuItem>
                            <MenuItem value="Allemagne">Arabe</MenuItem>
                          </Select>
                          <div className="langue error general-error"></div>
                        </div>
                        <div>
                          <Select
                            className="input-forme-grid4"
                            value={genre}
                            onChange={handleChangeGenre}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            id="Genre"
                          >
                            <MenuItem value="" style={{ display: "none" }}>
                              <span>Genre</span>
                            </MenuItem>
                            <MenuItem value="Homme">Homme</MenuItem>
                            <MenuItem value="Femme">Femme</MenuItem>
                            <MenuItem value="Autre">Autre</MenuItem>
                          </Select>
                          <div className="Genre error general-error"></div>
                        </div>
                      </div>
                    </div>
                    <div className="bloc">
                      <div className="item">
                        <input
                          className="inputFiled"
                          type="password"
                          name="password"
                          id="password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          placeholder="Mot de passe"
                        />
                        <div className="password error general-error"></div>
                      </div>
                      <div className="item">
                        <input
                          placeholder="Mot de passe de conf"
                          className="inputFiled"
                          type="password"
                          name="password"
                          id="password-conf"
                          onChange={(e) => setControlPassword(e.target.value)}
                          value={controlPassword}
                        />
                        <div className="password-confirm error general-error"></div>
                      </div>
                    </div>
                    <div>
                      <input type="checkbox" id="terms" />
                      <span style={{fontWeight: "bolder" , color :"#717171"}}>J’accepte les conditions générales</span>
                      <div className="terms error general-error"></div>
                    </div>
                  </div>
                  </form>
                  <div className="form-button">
                    <div type="submit" 
                    onClick={handleNext}
                    >
                      {" "}
                      Continuer
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
