import axios from "axios";

export const CONFIGCH_INFLUENCEUR_NUMBER = "CONFIGCH_INFLUENCEUR_NUMBER ";
export const GET_INFLUENCEUR_NUMBER = "GET_INFLUENCEUR_NUMBER";


export const configInf = (minInf, maxInf , PRAInf , PFAInfSilver , PFAInfGold , souspartnerInf ,minFanInf , maxFanInf , nFriendPartnerInf) => {
  return (dispatch) => {
    return axios({
      method: "post",
      url: `/api/superAdmin/configNumberInf`,
      data: {
        minInf,
        maxInf,
        PRAInf,
        PFAInfSilver,
        PFAInfGold,
        souspartnerInf,
        minFanInf,
        maxFanInf,
        nFriendPartnerInf
      },
    })
      .then((res) => {
        dispatch({
          type: CONFIGCH_INFLUENCEUR_NUMBER,
          payload: { minInf, maxInf ,  PRAInf , PFAInfSilver , PFAInfGold  , souspartnerInf , minFanInf, maxFanInf , nFriendPartnerInf},
        });
      })
      .catch((err) => console.log(err));
  };
};

export const getConfigInf = () => {
  return (dispatch) => {
    return axios
      .get(`/api/superAdmin/InfConfig`)
      .then((res) => {
        dispatch({ type: GET_INFLUENCEUR_NUMBER , payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};
