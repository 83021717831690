import React, {  useState ,useEffect } from "react";
import { useDispatch ,  useSelector} from "react-redux";
import { configUser , getConfigUser } from "../../actions/configUser.action";
import { configCh , getConfigCh } from "../../actions/configCh.action";
import { configInf , getConfigInf } from "../../actions/configInf.action";

export default function ResautageBody() {
    const dispatch = useDispatch();
    const configuser = useSelector((state) => state.configusersReducer);
    const configch = useSelector((state) => state.configChReducer);
    const configinf = useSelector((state) => state.configInfReducer);
    
    const [inputMin, setInputMin] = useState(0);
    const [inputMax, setInputMax] = useState(0);
    const [maxFan, setMaxFan] = useState(0);
    const [minFan, setMinFan] = useState(0);

    const [PRAGolde, setPRAGolde] = useState(0);
    const [PRASilver, setPRASilver] = useState(0);
    const [PFA, setPFA] = useState(0);

    const [souspartner, setSousPartner] = useState(0);

    const [nFriendPartner, setNFriendPartner] = useState(0);

////////////////////////////////////////////////////////////////////////////////////

    const [inputMinCh, setInputMinCh] = useState(0);
    const [inputMaxCh, setInputMaxCh] = useState(0);
    const [maxFanCh, setMaxFanCh] = useState(0);
    const [minFanCh, setMinFanCh] = useState(0);

    const [PRACh, setPRACh] = useState(0);
    const [PFAChGold, setPFAChGold] = useState(0);
    const [PFAChSilver, setPFAChSilver] = useState(0);

    const [souspartnerCh, setSousPartnerCh] = useState(0);
    const [nFriendPartnerCh, setNFriendPartnerCh] = useState(0);

////////////////////////////////////////////////////////////////////////////////////

    const [inputMinInf, setInputMinInf] = useState(0);
    const [inputMaxInf, setInputMaxInf] = useState(0);
    const [maxFanInf, setMaxFanInf] = useState(0);
    const [minFanInf, setMinFanInf] = useState(0);

    const [PRAInf, setPRAInf] = useState(0);
    const [PFAGoldInf, setPFAGoldInf] = useState(0);
    const [PFASilverInf, setPFASilverInf] = useState(0);

    const [souspartnerInf, setSousPartnerInf] = useState(0);
    const [nFriendPartnerInf, setNFriendPartnerInf] = useState(0);

    const handlerSend = () => {
      dispatch(configUser(inputMin , inputMax , PRASilver , PRAGolde  , PFA , souspartner ,minFan , maxFan , nFriendPartner))
      dispatch(getConfigUser())
    }

    const handlerSendCh = () => {
      dispatch(configCh(inputMinCh , inputMaxCh ,PRACh , PFAChSilver , PFAChGold , souspartnerCh , minFanCh , maxFanCh , nFriendPartnerCh))
      dispatch(getConfigCh())
    }

    const handlerSendInf = () => {
      dispatch(configInf(inputMinInf , inputMaxInf ,PRAInf , PFASilverInf , PFAGoldInf  , souspartnerInf , minFanInf, maxFanInf , nFriendPartnerInf))
      dispatch(getConfigInf())
    }

    useEffect(() => {
        setInputMin(configuser && configuser[0] && configuser[0].minUsers);
        setInputMax(configuser && configuser[0] && configuser[0].maxUsers);
        setMaxFan(configuser && configuser[0] && configuser[0].maxFan);
        setMinFan(configuser && configuser[0] && configuser[0].minFan);
        setPRASilver(configuser && configuser[0] && configuser[0].PRASilver);
        setPRAGolde(configuser && configuser[0] && configuser[0].PRAGolde);
        setPFA(configuser && configuser[0]&& configuser[0].PFA);
        setSousPartner(configuser && configuser[0] && configuser[0].souspartner);
        setNFriendPartner(configuser && configuser[0] && configuser[0].nFriendPartner);

        setInputMinCh(configch && configch[0] && configch[0].minCh);
        setInputMaxCh(configch && configch[0] && configch[0].maxCh);
        setMaxFanCh(configch && configch[0] && configch[0].maxFanCh);
        setMinFanCh(configch && configch[0] && configch[0].minFanCh);
        setPRACh(configch && configch[0] && configch[0].PRACh);
        setPFAChGold(configch && configch[0] && configch[0].PFAChGold);
        setPFAChSilver(configch && configch[0] && configch[0].PFAChSilver);
        setSousPartnerCh(configch && configch[0] && configch[0].souspartnerCh);
        setNFriendPartnerCh(configch && configch[0] && configch[0].nFriendPartnerCh);

        setInputMinInf(configinf && configinf[0] && configinf[0].minInf);
        setInputMaxInf(configinf && configinf[0] && configinf[0].maxInf);
        setMaxFanInf(configinf && configinf[0] && configinf[0].maxFanInf);
        setMinFanInf(configinf && configinf[0] && configinf[0].minFanInf);
        setPRAInf(configinf && configinf[0] && configinf[0].PRAInf);
        setPFAGoldInf(configinf && configinf[0] && configinf[0].PFAInfGold);
        setPFASilverInf(configinf && configinf[0] && configinf[0].PFAInfSilver);
        setSousPartnerInf(configinf && configinf[0] && configinf[0].souspartnerInf);
        setNFriendPartnerInf(configinf && configinf[0] && configinf[0].nFriendPartnerInf);
    }, []);


  return (
    <div className='super-admin-back'>
        <section className="super-admin-list-amie-flex">
           <section className='box-config-number'>
    
               <div className='box-config-number-title'>
                  <h1>Utilisateur</h1> 
               </div>
               <div className='box-config-number-element'>
               <div className='box-config-number-input'>
                   <h3>Min d'amis</h3>
                   <input className="box-config-number-input-coun"
                          type="number"
                          name="input-min"
                          id="input-min"
                          onChange={(e) => setInputMin(e.target.value)}
                          value={inputMin}/>
               </div>
               <div className='box-config-number-input'>
                   <h3>Max d'amis</h3>
                   <input className="box-config-number-input-coun"
                          type="number"
                          name="input-max"
                          id="input-max"
                          onChange={(e) => setInputMax(e.target.value)}
                          value={inputMax}
                          />
               </div>
               </div>

               <div className='box-config-number-element'>
               <div className='box-config-number-input'>
                   <h3>Min Fan</h3>
                   <input className="box-config-number-input-coun"
                          type="number"
                          name="fan-min"
                          id="fan-min"
                          onChange={(e) => setMinFan(e.target.value)}
                          value={minFan}/>
               </div>
               <div className='box-config-number-input'>
                   <h3>Max Fan</h3>
                   <input className="box-config-number-input-coun"
                          type="number"
                          name="maxFan"
                          id="maxFan"
                          onChange={(e) => setMaxFan(e.target.value)}
                          value={maxFan}
                          />
               </div>
               </div>

               <div className='box-config-number-element'>
               <div className='box-config-number-input'>
                   <h3>Prime R d'amis Gold</h3>
                   <input className="box-config-number-input-coun icon-rtl"
                          type="number"
                          name="PRAGolde"
                          id="PRAGolde"
                          onChange={(e) => setPRAGolde(e.target.value)}
                          value={PRAGolde}/>
               </div>
               <div className='box-config-number-input'>
                    <h3>N° Amie Partenaires</h3>
                    <input className="box-config-number-input-coun icon-rtl"
                          type="number"
                          name="nFriendPartnerCh"
                          id="nFriendPartnerCh"
                          onChange={(e) => setNFriendPartner(e.target.value)}
                          value={nFriendPartner}/>
               </div>
               </div>

               <div className='box-config-number-element'>
               <div className='box-config-number-input'>
                   <h3>Prime R d'amis Silver</h3>
                   <input className="box-config-number-input-coun icon-rtl"
                          type="number"
                          name="PRASilver"
                          id="PRASilver"
                          onChange={(e) => setPRASilver(e.target.value)}
                          value={PRASilver}/>
               </div>
               <div className='box-config-number-input'>

               </div>
               </div>
               
               {/* PFP fan nFriendPartner*/}

               <div className='box-config-number-element'>
               <div className='box-config-number-input'>
                   <h3>Prime fans Abonnés</h3>
                   <input className="box-config-number-input-coun icon-rtl"
                          type="number"
                          name="PFA"
                          id="PFA"
                          onChange={(e) => setPFA(e.target.value)}
                          value={PFA}
                          />
               </div>
               <div className='box-config-number-input'>
                   <h3>Prime sous Partner</h3>
                   <input className="box-config-number-input-coun icon-rtl"
                          type="number"
                          name="souspartner"
                          id="souspartner"
                          onChange={(e) => setSousPartner(e.target.value)}
                          value={souspartner}/>
               </div>
               </div>
           </section>
        </section>
        <section className="button-config-sauvegarder">
           <div className="button-config-flex-sauvegarder">
              <div className="button-sauvegarder" onClick={handlerSend}>Sauvegarder</div>
           </div>
        </section>

{/* ///////////////////////////////////////////////////////////////////// */}

        <section className="super-admin-list-amie-flex">
           <section className='box-config-number'>
    
               <div className='box-config-number-title'>
                  <h1>Challenger</h1> 
               </div>
               <div className='box-config-number-element'>
               <div className='box-config-number-input'>
                   <h3>Min d'amis</h3>
                   <input className="box-config-number-input-coun"
                          type="number"
                          name="input-minCh"
                          id="input-minCh"
                          onChange={(e) => setInputMinCh(e.target.value)}
                          value={inputMinCh}/>
               </div>
               <div className='box-config-number-input'>
                   <h3>Max d'amis</h3>
                   <input className="box-config-number-input-coun"
                          type="number"
                          name="input-maxCh"
                          id="input-maxCh"
                          onChange={(e) => setInputMaxCh(e.target.value)}
                          value={inputMaxCh}
                          />
               </div>
               </div>

               <div className='box-config-number-element'>
               <div className='box-config-number-input'>
                   <h3>Min Fan Challenger</h3>
                   <input className="box-config-number-input-coun"
                          type="number"
                          name="fan-minCh"
                          id="fan-minCh"
                          onChange={(e) => setMinFanCh(e.target.value)}
                          value={minFanCh}/>
               </div>
               <div className='box-config-number-input'>
                   <h3>Max Fan Challenger</h3>
                   <input className="box-config-number-input-coun"
                          type="number"
                          name="maxFanCh"
                          id="maxFanCh"
                          onChange={(e) => setMaxFanCh(e.target.value)}
                          value={maxFanCh}
                          />
               </div>
               </div>



               <div className='box-config-number-element'>
               <div className='box-config-number-input'>
                   <h3>P-Ch reseaux d'amis</h3>
                   <input className="box-config-number-input-coun icon-rtl"
                          type="number"
                          name="PRACH"
                          id="PRACH"
                          onChange={(e) => setPRACh(e.target.value)}
                          value={PRACh}/>
               </div>
               <div className='box-config-number-input'>
                    <h3>N° Amie Partenaires</h3>
                    <input className="box-config-number-input-coun icon-rtl"
                          type="number"
                          name="nFriendPartnerCh"
                          id="nFriendPartnerCh"
                          onChange={(e) => setNFriendPartnerCh(e.target.value)}
                          value={nFriendPartnerCh}/>
               </div>
               </div>

               <div className='box-config-number-element'>
               <div className='box-config-number-input'>
                   <h3>P-G-Ch fans Abonnés</h3>
                   <input className="box-config-number-input-coun icon-rtl"
                          type="number"
                          name="PFAChGold"
                          id="PFAChGold"
                          onChange={(e) => setPFAChGold(e.target.value)}
                          value={PFAChGold}
                          />
               </div>
               <div className='box-config-number-input'>
               <div className='box-config-number-input'>
                   <h3>P-Ch sous Partner</h3>
                   <input className="box-config-number-input-coun icon-rtl"
                          type="number"
                          name="souspartnerCh"
                          id="souspartnermaxFanCh"
                          onChange={(e) => setSousPartnerCh(e.target.value)}
                          value={souspartnerCh}/>
               </div>
               </div>
               </div>

               <div className='box-config-number-element'>
               <div className='box-config-number-input'>
                   <h3>P-S-Ch fans Abonnés</h3>
                   <input className="box-config-number-input-coun icon-rtl"
                          type="number"
                          name="PFAChSilver"
                          id="PFAChSilver"
                          onChange={(e) => setPFAChSilver(e.target.value)}
                          value={PFAChSilver}
                          />
               </div>
               <div className='box-config-number-input'>
               <div className='box-config-number-input'></div>
               </div>
               </div>

           </section>
        </section>
        <section className="button-config-sauvegarder">
           <div className="button-config-flex-sauvegarder">
              <div className="button-sauvegarder" onClick={handlerSendCh}>Sauvegarder</div>
           </div>
        </section>

{/* ///////////////////////////////////////////////////////////////////// */}

        <section className="super-admin-list-amie-flex">
           <section className='box-config-number'>
    
               <div className='box-config-number-title'>
                  <h1>Influenceur</h1> 
               </div>
               <div className='box-config-number-element'>
               <div className='box-config-number-input'>
                   <h3>Min d'amis</h3>
                   <input className="box-config-number-input-coun"
                          type="number"
                          name="input-minInf"
                          id="input-minInf"
                          onChange={(e) => setInputMinInf(e.target.value)}
                          value={inputMinInf}/>
               </div>
               <div className='box-config-number-input'>
                   <h3>Max d'amis</h3>
                   <input className="box-config-number-input-coun"
                          type="number"
                          name="input-maxInf"
                          id="input-maxInf"
                          onChange={(e) => setInputMaxInf(e.target.value)}
                          value={inputMaxInf}
                          />
               </div>
               </div>

               <div className='box-config-number-element'>
               <div className='box-config-number-input'>
                   <h3>Min Fan</h3>
                   <input className="box-config-number-input-coun"
                          type="number"
                          name="fan-min"
                          id="fan-min"
                          onChange={(e) => setMinFanInf(e.target.value)}
                          value={minFanInf}/>
               </div>

               
               <div className='box-config-number-input'>
                   <h3>Max Fan</h3>
                   <input className="box-config-number-input-coun"
                          type="number"
                          name="maxFanInf"
                          id="maxFanInf"
                          onChange={(e) => setMaxFanInf(e.target.value)}
                          value={maxFanInf}
                          />
               </div>
               </div>



               <div className='box-config-number-element'>
               <div className='box-config-number-input'>
                   <h3>Prime reseaux d'amis</h3>
                   <input className="box-config-number-input-coun icon-rtl"
                          type="number"
                          name="PRAInf"
                          id="PRAInf"
                          onChange={(e) => setPRAInf(e.target.value)}
                          value={PRAInf}/>
               </div>
               <div className='box-config-number-input'>
                    <h3>N° Amie Partenaires</h3>
                    <input className="box-config-number-input-coun icon-rtl"
                          type="number"
                          name="nFriendPartnerInf"
                          id="nFriendPartnerInf"
                          onChange={(e) => setNFriendPartnerInf(e.target.value)}
                          value={nFriendPartnerInf}/>
               </div>
              </div>

               <div className='box-config-number-element'>
               <div className='box-config-number-input'>
                   <h3>P-G-Inf fans Abonnés</h3>
                   <input className="box-config-number-input-coun icon-rtl"
                          type="number"
                          name="PFAInf"
                          id="PFAInf"
                          onChange={(e) => setPFAGoldInf(e.target.value)}
                          value={PFAGoldInf}
                          />
               </div>
               <div className='box-config-number-input'>
                   <h3>P-Inf sous Partner</h3>
                   <input className="box-config-number-input-coun icon-rtl"
                          type="number"
                          name="souspartnerInf"
                          id="souspartnerInf"
                          onChange={(e) => setSousPartnerInf(e.target.value)}
                          value={souspartnerInf}/>
               </div>
               </div>

               <div className='box-config-number-element'>
               <div className='box-config-number-input'>
                   <h3>P-S-Inf fans Abonnés</h3>
                   <input className="box-config-number-input-coun icon-rtl"
                          type="number"
                          name="PFASilverInf"
                          id="PFASilverInf"
                          onChange={(e) => setPFASilverInf(e.target.value)}
                          value={PFASilverInf}
                          />
               </div>
               <div className='box-config-number-input'></div>
               </div>
           </section>
        </section>
        <section className="button-config-sauvegarder">
           <div className="button-config-flex-sauvegarder">
              <div className="button-sauvegarder" onClick={handlerSendInf}>Sauvegarder</div>
           </div>
        </section>
        
    </div>
  )
}
