import React , { useContext , useState , useEffect  } from 'react';
import { NavLink } from 'react-router-dom';
import { UidContext } from "./AppContext";

const LeftNavSuperAdmin = () => {
  const data = useContext(UidContext);
  const superAdminId = data && data._id && data.role === "superAdmin" 
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [toggleMenu, setToggleMenu] = useState(false);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  useEffect(() => {
    const changeWidth = () => setScreenWidth(window.innerWidth) ;
    window.addEventListener('resize', changeWidth) ;
    return () =>  window.removeEventListener('resize', changeWidth)
  }, [])
  return (
    <>
    <div className="left-nav-container">
      <div className="icons">
      {superAdminId ?  (
        
        <div className="icons-bis">
          <>
            <div onClick={toggleNav} className="nav-btn">
             <img width={30} src="./img/icons/hamburger.svg" alt="icon" />
            </div>

    {(screenWidth < 800) && (
      <div className="nav-drop-box-res">

        <div className="items-res">
          <NavLink rel="canonical" to='/account-configuration' exact="true" className="left-nav-super-admin-res">
            <span>CU</span>
          </NavLink> 
        </div>

        <div className="items-res">
          <NavLink rel="canonical" to='/reseautage-configuration' exact="true" className="left-nav-super-admin-res">
            <span >CR</span>
          </NavLink> 
        </div>

        <div className="items-res">
          <NavLink rel="canonical" to='/configuration' exact="true" className="left-nav-super-admin-res">
            <span >CO</span>
          </NavLink> 
        </div>

        </div>
    )}

    {(screenWidth > 800) && (
      <div className="nav-drop-box">

        <div className="items">
          <NavLink rel="canonical" to='/account-configuration' exact="true" className={(props) => { return `${props.isActive ? 'active-left-nav1' : 'label-icon1'}`}}end>
            <span>Config Utilisateur</span>
          </NavLink> 
        </div>

        <div className="items">
          <NavLink rel="canonical" to='/reseautage-configuration' exact="true" className={(props) => { return `${props.isActive ? 'active-left-nav1' : 'label-icon1'}`}}end>
            <span >Config Réseautage</span>
          </NavLink> 
        </div>

        <div className="items">
          <NavLink rel="canonical" to='/configuration' exact="true" className={(props) => { return `${props.isActive ? 'active-left-nav1' : 'label-icon1'}`}}end>
            <span >Config</span>
          </NavLink> 
        </div>


        </div>
        
    )}
        </>

        </div>
        
              ) : <></> }
      </div>
    </div>
    

    </>
  );
};

export default LeftNavSuperAdmin;