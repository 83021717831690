import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { NavLink } from "react-router-dom";
import { getUser } from "../../actions/user.actions";
import { addFP } from "../../actions/addFP.action";
import ReseauxSocial from "../Reseaux/ReseauxSocial";
import SimulateurGain from "../SimulateurGain/index";
import Assistance from "../Assistance"

export default function Index() {
  const usersData = useSelector((state) => state.usersReducer);
  const userData = useSelector((state) => state.userReducer);

  const configuser = useSelector((state) => state.configusersReducer);
  const configch = useSelector((state) => state.configChReducer);
  const configinf = useSelector((state) => state.configInfReducer);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [partageModal, setPartageModal] = useState(false);
  const [simulateurModal, setSimulateurModal] = useState(false);
  const [goldPrice, setGoldPrice] = useState(0);
  const [silverPrice, setSilverPrice] = useState(0);


  const [counterFriends, setCounterFriends] = useState(0);
  const [counterFriendsPartner, setCounterFriendsPartner] = useState(0);

  const [openListAmie, setOpenListAmie] = useState(false);
  const [listAmieFilter, setListAmieFilter] = useState([]);
  const [idProps, setIdProps] = useState("");
  const [nomProps, setNomProps] = useState("");
  const [prenomProps, setPrenomProps] = useState("");
  const [picProps, setPicProps] = useState("");

  const [texting, setTexting] = useState(false)

  const dispatch = useDispatch();

  const handlerDataMessage = (ParaId , ParaNom , ParaPrenom , ParaPic) => {
    setTexting(true)
    setIdProps(ParaId)
    setNomProps(ParaNom)
    setPrenomProps(ParaPrenom)
    setPicProps(ParaPic)
  }

  const handlerFilter = (e) => {
    setOpenListAmie(true)
    setListAmieFilter( userData && userData.friends && userData.friends
      .map((val) => usersData && usersData
      .filter((el) => el._id === val && (el.nom + " " + el.prenom).toUpperCase().includes(e.target.value.toUpperCase()))[0])
      .filter((el1) => el1 !== undefined)
    )
  }

  const handleFP = async (idFP, idUser) => {
    await dispatch(addFP(idFP, idUser));
    dispatch(getUser(idUser));
  };

  const handlePartageModals = (e) => {
    if (e.target.id === "partage") {
      setPartageModal(true);
    }
  };

  const handlesimulateurModal = (e) => {
    setSimulateurModal(true);
  };
  useEffect(() => {
    setCounterFriends(userData && userData.friends && userData.friends.length);
    setCounterFriendsPartner(
      userData && userData.friendsPartner && userData.friendsPartner.length
    );
  }, [userData]);

  useEffect(() => {
    const changeWidth = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", changeWidth);
    return () => window.removeEventListener("resize", changeWidth);
  }, []);

  useEffect(async () => {
    const reseaudami = userData && userData.friends && userData.friends.length;

    const utilisateurCondition =
      (await (configuser[0] && configuser[0].minUsers <= reseaudami)) &&
      configuser[0] &&
      configuser[0].maxUsers >= reseaudami;
    const ChallengerCondition =
      (await (configch[0] && configch[0].minCh <= reseaudami)) &&
      configch[0] &&
      configch[0].maxCh >= reseaudami;
    const InfluenceurCondition =
      (await (configinf[0] && configinf[0].minInf <= reseaudami)) &&
      configinf[0] &&
      configinf[0].maxInf >= reseaudami;

    if (utilisateurCondition) {
      setGoldPrice(reseaudami * Number(configuser[0] && configuser[0].PRAGolde));
      setSilverPrice(reseaudami * Number(configuser[0] && configuser[0].PRASilver));
    }
    if (ChallengerCondition) {
      setGoldPrice(reseaudami * Number(configch[0] && configch[0].PRACh));
    }
    if (InfluenceurCondition) {
      setGoldPrice(
        reseaudami * Number(configinf[0] && configinf[0].PRAInf)
      );
    }
  }, [configuser, configch, configinf, userData]);

  return (
    <>
      {screenWidth > 1200 && (
        <>
          <section className="Accueil-container">
            <div className="story">
              <div className="label-story"> Mes amis </div>
              <div className="box-story"></div>
              <div className="box-story"></div>
              <div className="box-story"></div>
              <div className="box-story"></div>
              <div className="box-story"></div>
            </div>

            <section className="flex-storybody">
              <div>
                <div className="story-body">
                  <h4 className="h41">SoWaySo</h4>
                  <span></span>
                  <span></span>
                  <h4 className="h42">Votre premier Challenge STARTER</h4>
                  <p>
                     Les 3 premiers Mois: vous devez créer votre réseau d’amis et réseau FRIEND PARTNER.
                    <br />
                    Vous recevez 50 € Gold sur chaque 100 amis ajoutée et 50 € Silver sur les réseaux d’amis de
                    vos FRIEND PARTNER.
                    <br />
                    Pendant les 6 premiers mois: vous devez crée votre réseau des Fans abonnés et Vous recevez 500 € sur chaque 10 000 Fans abonnés et jusqu’a 5 M de Fans.
                  </p>
                  <img
                    width={20}
                    src="./img/icons/share-white.svg"
                    alt="icon"
                  />
                </div>
                <div className="story-body0">
                  <div className="title-box-body0">
                    <h4 className="title-story-body">Fabian</h4>
                    <div className="title-story-body0">
                      <h4>1 047 204</h4>
                      <h4 className="title-vues">Vues</h4>
                    </div>
                  </div>

                  <h4 className="title-story-body1">
                    SoWaySo valorise temps par des primes de référence !!
                  </h4>
                  <p>
                    Je reçois une prime de 100 € sur chaque 100 amis
                    intégrés sur mon nouveau réseau SoWaySo m’accorde une prime
                    50 € sur chaque 1000 vues sur mes publications SoBlog
                    (Texto, Photo, Mini vidéo) et aussi 20 000 € pour chaque
                    million de vues sur mes publications SoMedia
                  </p>
                  <div className="story-body0-footer">
                    <div className="story-body0-footer-iteam">
                      <p>678 159</p>
                      <img
                        width={17}
                        src="./img/icons/commentaire.svg"
                        alt="icon"
                      />
                    </div>
                    <div className="story-body0-footer-iteam">
                      <p>411 081</p>
                      <img
                        width={17}
                        src="./img/icons/share_blue.svg"
                        alt="icon"
                      />
                    </div>
                    <div className="story-body0-footer-iteam">
                      <p>892 773</p>
                      <img width={17} src="./img/icons/heard.svg" alt="icon" />
                    </div>
                  </div>
                </div>

                <div className="story-body0">
                  <div className="title-box-body0">
                    <h4 className="title-story-body">Muriel</h4>
                    <div className="title-story-body0">
                      <h4>1 047 204</h4>
                      <h4 className="title-vues">Vues</h4>
                    </div>
                  </div>
                  <h4 className="title-story-body1">
                   Mon nouveau réseau social de confiance
                   </h4>
                  <p>
                    Ici mes informations personnelles ne seront jamais vendues.
                    SoWaySo sécurise ma sérénité et intervient contre toutes les
                    tentatives frauduleuses. Sur SoWaySo les inscriptions sont
                    vérifiées pour lutter contre faux profile.
                  </p>
                  <div className="story-body0-footer">
                    <div className="story-body0-footer-iteam">
                      <p>678 159</p>
                      <img
                        width={17}
                        src="./img/icons/commentaire.svg"
                        alt="icon"
                      />
                    </div>
                    <div className="story-body0-footer-iteam">
                      <p>411 081</p>
                      <img
                        width={17}
                        src="./img/icons/share_blue.svg"
                        alt="icon"
                      />
                    </div>
                    <div className="story-body0-footer-iteam">
                      <p>892 773</p>
                      <img width={17} src="./img/icons/heard.svg" alt="icon" />
                    </div>
                  </div>
                </div>

                <div className="story-body0">
                  <div className="title-box-body0">
                    <h4 className="title-story-body">Christine</h4>
                    <div className="title-story-body0">
                      <h4>1 047 204</h4>
                      <h4 className="title-vues">Vues</h4>
                    </div>
                  </div>
                  <h4 className="title-story-body1">
                    SoWaySo m’accorde des avantages et des gains !! Génial 
                  </h4>
                  <p>
                    Je consulte, je partage des publications avec mes amis et
                    mon réseau de fan abonné et je gagne de l’argent ainsi j’ai
                    la possibilité d’envoyer des cadeaux à mes meilleurs fans !!
                    Waw je reçois un bonus jusqu’à 31 900 € par valise
                    d’investissement
                  </p>
                  <div className="story-body0-footer">
                    <div className="story-body0-footer-iteam">
                      <p>678 159</p>
                      <img
                        width={17}
                        src="./img/icons/commentaire.svg"
                        alt="icon"
                      />
                    </div>
                    <div className="story-body0-footer-iteam">
                      <p>411 081</p>
                      <img
                        width={17}
                        src="./img/icons/share_blue.svg"
                        alt="icon"
                      />
                    </div>
                    <div className="story-body0-footer-iteam">
                      <p>892 773</p>
                      <img width={17} src="./img/icons/heard.svg" alt="icon" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-story-right-bar">
                <div>
                  <div className="Simulation-accueil-logo">
                    <img
                      width={30}
                      src="./img/icons/utilisateur-bleu.svg"
                      alt="icon"
                    />
                  </div>
                     <div onClick={handlesimulateurModal} className="story-right-bar1">
                       Simulation des avantages  <br/> et des primes
                     </div>
                  {simulateurModal && <SimulateurGain openPopup={(e) => setSimulateurModal(e)}/>}
                </div>
                <div className="story-right-bar2-back">
                  <div className="story-right-bar2-img">
                    <div className="story-right-bar2">
                      <div style={{ fontWeight: "bolder", marginTop: "10px" }}>
                        Votre Solde : {Number(goldPrice + silverPrice)} €
                      </div>
                      <br />
                      <div className="votre-solde-style">
                        Votre solde actuel est 
                      </div>
                      <div className="montant-acc">
                        <div className="TC-Gold">
                          <div className="TC-Gold-valeur">{goldPrice}</div>
                          <div className="flex-TC-Gold">
                            <span className="flex-TC-Gold-element">€ </span>
                            <span className="flex-TC-Gold-element">Gold </span>
                          </div>
                        </div>
                        <div className="TC-Gold">
                          <div className="TC-Gold-valeur">{silverPrice}</div>
                          <div className="flex-TC-Gold">
                            <span className="flex-TC-Gold-element">€ </span>
                            <span className="flex-TC-Gold-element">
                              Silver{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
                <div className="story-right-bar3">
                  <div className="title-partage-box">Inviter mes Amis</div>
                  <div className="partage-box-acc">
                    <div
                      className="button-partage-box"
                      onClick={handlePartageModals}
                      id="partage"
                    >
                      Partager
                    </div>
                    <div
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `https://sowayso.com/#/${userData._id}`
                        );
                      }}
                    >
                      <div className="copie-icone-box">
                        <img
                          width={20}
                          src="./img/icons/copie.svg"
                          alt="copie"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="parag-partage-box">
                    Vous pouvez ajouter vos amis pour vous joindre sur SoWaySo
                  </div>
                  <br />
                  {partageModal && (
                    <ReseauxSocial openPopup={(e) => setPartageModal(e)} />
                  )}
                </div>
                <div className="story-right-bar4">
                  <div className="story-right-bar4-first-ligne">
                    <div className="story-right-bar4-first-ligne1">FP</div>
                    <div className="story-right-bar4-first-ligne2">
                      {counterFriendsPartner}
                    </div>
                  </div>

                  {/* /////////////////////////////////////////////////////////////////////// */}

                  {userData &&
                    userData.friendsPartner &&
                    userData.friendsPartner
                      .map(
                        (val, index) =>
                          usersData && usersData.filter((el) => el._id === val)
                      )
                      .slice(0, 3)
                      .map((value, index1) => (
                        <div
                          key={index1}
                          className="story-right-bar4-second-ligne"
                        >
                          <div className="story-right-bar4-second-ligne1">
                            <img
                              className="story-right-bar4-second-ligne11"
                              src={value[0].picture}
                              alt="icon"
                            />
                            <div className="story-right-bar4-second-ligne12">
                              <span>{value[0].nom}</span>{" "}
                              <span>{value[0].prenom}</span>
                            </div>
                          </div>
                          {/* <div className="story-right-bar4-second-ligne2">
                            Ajouter
                          </div> */}
                        </div>
                      ))}

                  {/* /////////////////////////////////////////////////////////////////////// */}
                </div>
                <div className="story-right-bar5">
                  <div className="story-right-bar5-first-ligne">
                    <div className="story-right-bar5-first-ligne1">
                      Liste d’Amis
                    </div>
                    <div className="story-right-bar5-first-ligne2">
                      {counterFriends}
                    </div>
                  </div>
                  {userData &&
                    userData.friends &&
                    userData.friends
                      .map(
                        (val, index) =>
                          usersData && usersData.filter((el) => el._id === val)
                      )
                      .slice(0, 3)
                      .map((value, index1) => (
                        <div
                          key={index1}
                          className="story-right-bar5-second-ligne"
                        >
                          <div className="story-right-bar5-second-ligne1">
                            <img
                              className="story-right-bar5-second-ligne11"
                              src={value[0].picture}
                              alt="icon"
                            />
                            <div className="story-right-bar5-second-ligne12">
                              <span>{value[0].nom}</span>{" "}
                              <span>{value[0].prenom}</span>
                            </div>
                          </div>
                          <div
                            onClick={() =>
                              handleFP(value[0]._id, userData && userData._id)
                            }
                            className="story-right-bar5-second-ligne2"
                          >
                            Friend P
                          </div>
                        </div>
                      ))}
                </div>

                <div style={{display : "none"}} className="story-right-bar5">
                  <div className="story-right-bar5-first-ligne">
                    <div className="story-right-bar5-first-ligne1">
                      Ajouter d’Amis
                    </div>
                    {/* <div className="story-right-bar5-first-ligne2">
                      {counterFriends}
                    </div> */}
                  </div>
                  {/* {
                    usersData && usersData[0] && usersData.slice(0, 3).map((val ,index)=>(
                      <h1>{val.nom}</h1>
                    ))
                  } */}
                  {usersData &&
                    usersData[0] &&
                    usersData.slice(0, 3).map((value, index1) => (
                      <div
                        key={index1}
                        className="story-right-bar5-second-ligne"
                      >
                        <div className="story-right-bar5-second-ligne1">
                          <img
                            className="story-right-bar5-second-ligne11"
                            src={value.picture}
                            alt="icon"
                          />
                          <div className="story-right-bar5-second-ligne12">
                            <span>{value.nom}</span> <span>{value.prenom}</span>
                          </div>
                        </div>
                        <div className="story-right-bar5-second-ligne2">
                          Ajouter
                        </div>
                      </div>
                    ))}
                </div>

                <div className="message-one-to-one">
                { 
                listAmieFilter.length !== 0 && openListAmie  && (
                <div className="list-amie-message">
                  <span onClick={() => setOpenListAmie(false)} className="exit-button">x</span>
                  {
                     listAmieFilter.map((val , index) => (
                      <span  key={index}>
                      <div onClick={() => handlerDataMessage(val._id , val.nom , val.prenom , val.picture)}
                           className="list-amie-message-cont">
                         <img
                            className="list-amie-message-pic"
                            src={val.picture}
                            alt="icon"
                          />
                          <span >{val.nom} {" "} </span>
                          <span >{val.prenom} {" "}  </span>
                      </div>
                      </span>
                    )
                   )
                  }
                </div>
                )
                }
                  <div className="message-logo">
                    <img src="./img/icons/logo-sowayso-icon.svg" alt="img-log" />
                  </div>
                  <div className="message-box-input">
                    <input onChange={handlerFilter} type="text" placeholder="Message Amie"/>
                  </div>
                </div>
              </div>
            </section>
            {texting ? (
                <div className="texting-box">

                   <Assistance 
                               idAuh={userData && userData._id} 
                               name={userData && userData.nom} 
                               picture={userData && userData.picture} 
                               textToId={idProps} 
                               textToName={nomProps}
                               textToPrenom={prenomProps}
                               textToPic={picProps}
                               close={() => setTexting(false)}/>
                </div> 
                ) : <></>
            }
            
          </section>
        </>
      )}

      {screenWidth < 1200 && (
        <>
          <section className="Accueil-container">
            <div className="story1">
              <div className="box-story1"></div>
              <div className="box-story1"></div>
              <div className="box-story1"></div>
            </div>

            <div className="accueil-gold-ligne">
              <p className="accueil-gold-ligne-nom">Bonjour {userData.nom} </p>
              <p className="accueil-gold-ligne-label">Votre Solde est: {Number(goldPrice + silverPrice)} €   </p>
              <span className="accueil-gold-ligne-gold">
                {goldPrice} € Gold
              </span>
              <span className="separation">|</span>
              <span className="accueil-gold-ligne-silver">{silverPrice} € Silver</span>
            </div>

            <section className="flex-res-acc">
              <div className="story-right-bar3-res">
                <div className="story-right-bar33-res">
                <div
                  className="button-partage-box-res"
                  onClick={handlePartageModals}
                  id="partage"
                >
                  {" "}
                  Partager{" "}
                </div>

                <div
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `https://sowayso.com/#/${userData._id}`
                        );
                      }}
                    >
                      <div className="copie-icone-box">
                        <img
                          width={15}
                          src="./img/icons/copie.svg"
                          alt="copie"
                        />
                      </div>
                  </div>
                </div>

                {partageModal && (
                  <ReseauxSocial openPopup={(e) => setPartageModal(e)} />
                )}
              </div>

              <div className="simulateur-box-accueil-res-col">
                <div className="simulateur-box-accueil-res">
                <div className="Simulation-accueil-logo-res">
                  <img
                    width={20}
                    src="./img/icons/utilisateur-bleu.svg"
                    alt="icon"
                  />
                </div>

                <div onClick={handlesimulateurModal} className="story-right-bar1-res">
                     Simulation
                </div>
                {simulateurModal && <SimulateurGain openPopup={(e) => setSimulateurModal(e)}/>}

                </div>
              </div>



            </section>
            <section className="flex-storybody-sidebar1">
              <div>
                <div className="story-body1">
                  <h4 className="h41">SoWaySo</h4>
                  <span></span>
                  <span></span>
                  <h4 className="h42">Votre premier Challenge STARTER</h4>
                  <p>
                     Les 3 premiers Mois: vous devez créer votre réseau d’amis et réseau FRIEND PARTNER.
                    <br />
                    Vous recevez 50 € Gold sur chaque 100 amis ajoutée et 50 € Silver sur les réseaux d’amis de
                    vos FRIEND PARTNER.
                    <br />
                    Pendant les 6 premiers mois: vous devez crée votre réseau des Fans abonnés et Vous recevez 500 € sur chaque 10 000 Fans abonnés et jusqu’a 5 M de Fans.
                  </p>
                  <img
                    width={10}
                    src="./img/icons/share-white.svg"
                    alt="icon"
                  />
                </div>

                <div className="story-body0-res">
                  <div className="title-box-body0-res">
                    <h4 className="title-story-body-res">Fabian</h4>
                    <div className="title-story-body0-res">
                      <h4 className="title-vues-h4-res">1 047 204</h4>
                      <h4 className="title-vues-res">Vues</h4>
                    </div>
                  </div>
                  <h4 className="title-story-body1-res">
                    SoWaySo valorise temps par des primes de référence !!
                  </h4>
                  <p className="title-story-body1p-res">
                    Je reçois une prime de 100 € sur chaque 100 amis
                    intégrés sur mon nouveau réseau SoWaySo m’accorde une prime
                    50 € sur chaque 1000 vues sur mes publications SoBlog
                    (Texto, Photo, Mini vidéo) et aussi 20 000 € pour chaque
                    million de vues sur mes publications SoMedia
                  </p>
                  <div className="story-body0-footer-res">
                    <div className="story-body0-footer-iteam-res">
                      <p>678 159</p>
                      <img
                        width={14}
                        src="./img/icons/commentaire.svg"
                        alt="icon"
                      />
                    </div>
                    <div className="story-body0-footer-iteam-res">
                      <p>411 081</p>
                      <img
                        width={14}
                        src="./img/icons/share_blue.svg"
                        alt="icon"
                      />
                    </div>
                    <div className="story-body0-footer-iteam-res">
                      <p>892 773</p>
                      <img width={14} src="./img/icons/heard.svg" alt="icon" />
                    </div>
                  </div>
                </div>

                <div className="story-body0-res">
                  <div className="title-box-body0-res">
                    <h4 className="title-story-body-res">Muriel</h4>
                    <div className="title-story-body0-res">
                      <h4 className="title-vues-h4-res"> 1 047 204</h4>
                      <h4 className="title-vues-res">Vues</h4>
                    </div>
                  </div>
                  <h4 className="title-story-body1-res">Mon nouveau réseau social de confiance</h4>
                  <p className="title-story-body1p-res">
                    Ici mes informations personnelles ne seront jamais vendues.
                    SoWaySo sécurise ma sérénité et intervient contre toutes les
                    tentatives frauduleuses. Sur SoWaySo les inscriptions sont
                    vérifiées pour lutter contre faux profile.
                  </p>
                  <div className="story-body0-footer-res">
                    <div className="story-body0-footer-iteam-res">
                      <p>678 159</p>
                      <img
                        width={14}
                        src="./img/icons/commentaire.svg"
                        alt="icon"
                      />
                    </div>
                    <div className="story-body0-footer-iteam-res">
                      <p>411 081</p>
                      <img
                        width={14}
                        src="./img/icons/share_blue.svg"
                        alt="icon"
                      />
                    </div>
                    <div className="story-body0-footer-iteam-res">
                      <p>892 773</p>
                      <img width={14} src="./img/icons/heard.svg" alt="icon" />
                    </div>
                  </div>
                </div>

                <div className="story-body0-res">
                  <div className="title-box-body0-res">
                    <h4 className="title-story-body-res">Christine</h4>
                    <div className="title-story-body0-res">
                      <h4 className="title-vues-h4-res">1 047 204</h4>
                      <h4 className="title-vues-res">Vues</h4>
                    </div>
                  </div>
                  <h4 className="title-story-body1-res">
                     SoWaySo m’accorde des avantages et des gains !! Génial 
                  </h4>
                  <p className="title-story-body1p-res">
                    Je consulte, je partage des publications avec mes amis et
                    mon réseau de fan abonné et je gagne de l’argent ainsi j’ai
                    la possibilité d’envoyer des cadeaux à mes meilleurs fans !!
                    Waw je reçois un bonus jusqu’à 31 900 € par valise
                    d’investissement
                  </p>
                  <div className="story-body0-footer-res">
                    <div className="story-body0-footer-iteam-res">
                      <p>678 159</p>
                      <img
                        width={14}
                        src="./img/icons/commentaire.svg"
                        alt="icon"
                      />
                    </div>
                    <div className="story-body0-footer-iteam-res">
                      <p>411 081</p>
                      <img
                        width={14}
                        src="./img/icons/share_blue.svg"
                        alt="icon"
                      />
                    </div>
                    <div className="story-body0-footer-iteam-res">
                      <p>892 773</p>
                      <img width={14} src="./img/icons/heard.svg" alt="icon" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-story-right-bar1">
                <div className="story-right-bar1"></div>
                <div className="story-right-bar2"></div>
                <div className="story-right-bar3"></div>
                <div className="story-right-bar4"></div>
                <div className="story-right-bar5"></div>
              </div>
            </section>
          </section>
        </>
      )}
    </>
  );
}
