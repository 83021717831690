import {CONFIGCH_INFLUENCEUR_NUMBER,  GET_INFLUENCEUR_NUMBER } from "../actions/configInf.action";
  
  const initialState = {};
  
  export default function configInfReducer(state = initialState, action) {
    switch (action.type) {
      case GET_INFLUENCEUR_NUMBER :
        return action.payload;
      case CONFIGCH_INFLUENCEUR_NUMBER:
          return {
            ...state,
            minInf: action.payload.minInf,
            maxInf: action.payload.maxInf,
            PRAInf: action.payload.PRAInf,
            PFAInfGold: action.payload.PFAInfGold,
            PFAInfSilver: action.payload.PFAInfSilver,
            souspartnerInf: action.payload.souspartnerInf,
            minFanInf: action.payload.minFanInf,
            maxFanInf: action.payload.maxFanInf,
            nFriendPartnerInf: action.payload.nFriendPartnerInf
          }
      default:
        return state;
    }
  }
  