import axios from "axios";

export const ADD_FP = "ADD_FP";



export const addFP = (idFP , idUser) => {
    return (dispatch) => {
      return axios
        .post(`/api/user/addFP`, {idFP , idUser})
        // .then((res) => {

        // });
    };
  };