import React, { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { UidContext } from "./AppContext";
import { useSelector } from "react-redux";
// import Assistance from "./Assistance"
import AssistanceUser from "../components/emailAssistance/AssistanceUser"

const LeftNav = () => {
  const uid = useContext(UidContext);
  const userData = useSelector((state) => state.userReducer);
  const configuser = useSelector((state) => state.configusersReducer);
  const configch = useSelector((state) => state.configChReducer);
  const configinf = useSelector((state) => state.configInfReducer);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [offset, setOffset] = useState(window.pageYOffset);
  const [userStatus, setUserStatus] = useState("");
  const [hideImage, setHideImage] = useState(
    window.location.href.split("/")[3]
  );
  // const [texting, setTexting] = useState(false)
  const [assistanceModal, setAssistanceModal] = useState(false);

  const handleAssistanceModals = (e) => {
      setAssistanceModal(true);
  };

  useEffect(() => {
    const changeWidth = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", changeWidth);
    return () => window.removeEventListener("resize", changeWidth);
  }, []);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);

    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const headerClose = (e) => {
    setHideImage(false);
  };

  useEffect(() => {
    const rest = userData && userData.friends && userData.friends.length;
    const utilisateurCondition =
      configuser[0] &&
      configuser[0].minUsers <= rest &&
      configuser[0] &&
      configuser[0].maxUsers >= rest;
    const ChallengerCondition =
      configch[0] &&
      configch[0].minCh <= rest &&
      configch[0] &&
      configch[0].maxCh >= rest;
    const InfluenceurCondition =
      configinf[0] &&
      configinf[0].minInf <= rest &&
      configinf[0] &&
      configinf[0].maxInf >= rest;

    if (utilisateurCondition) {
      setUserStatus("Utilisateur");
    } else if (ChallengerCondition) {
      setUserStatus("Challenger");
    } else if (InfluenceurCondition) {
      setUserStatus("Influenceur");
    } else {
      setUserStatus("");
    }
  }, [userData, configuser, configch, configinf]);

  return (
    <>
      <div className="left-nav-container">
        <div className="icons">
          {uid ? (
            <div className="icons-bis">
              <>
                <div className="nav-btn">
                  <img width={30} src="./img/icons/hamburger.svg" alt="icon" />
                </div>
                <div className="nav-drop-box">
                  <div className="items-test">
                    <div className="profile-box">
                      {(!(hideImage === "profilAmie") || screenWidth > 1000) &&
                        (!(hideImage === "profil") || screenWidth > 1000) &&
                        (offset < 50 || screenWidth > 1000) && (
                          <img
                            className="profile-image"
                            src={userData.picture}
                            alt="icon"
                          />
                        )}
                      <div className="profile-data-inf">
                        <p className="profile-user-name">
                          Bonjour {userData.nom}
                        </p>
                        <p className="bienvenue">
                          Bienvenue sur votre <br /> réseau social solidaire
                        </p>
                        <p className="user-status">{userStatus}</p>
                      </div>
                    </div>
                  </div>

                  {offset < 150 || screenWidth > 1000 ? (
                    <div className="under-navbare"></div>
                  ) : (
                    <div className="under-navbare1"></div>
                  )}

                  <section className="side-bar-scroll">
                    <div className="items">
                      <NavLink
                        rel="canonical" 
                        to="/profil"
                        exact="true"
                        className={(props) => {
                          return `${
                            props.isActive
                              ? "label-icon-active1"
                              : "label-icon1"
                          }`;
                        }}
                        end
                      >
                        <div className="cercle-img1">
                          <img src="./img/icons/profile.svg" alt="icon" />
                        </div>
                        <span className="leftnav-profile-label">
                          Mon Profile
                        </span>
                      </NavLink>
                    </div>

                    <div className="items">
                      <NavLink
                        rel="canonical" 
                        to="/Reseaux"
                        exact="true"
                        className={(props) => {
                          return `${
                            props.isActive
                              ? "label-icon-active1"
                              : "label-icon1"
                          }`;
                        }}
                        end
                      >
                        <div className="cercle-img1">
                          <img src="./img/icons/reseaux.svg" alt="icon" />
                        </div>
                        <span className="leftnav-profile-label">
                          Mon Réseau
                        </span>
                      </NavLink>
                    </div>

                    <div className="items">
                      <NavLink
                        rel="canonical" 
                        to="/Fans"
                        exact="true"
                        className={(props) => {
                          return `${
                            props.isActive
                              ? "label-icon-active1"
                              : "label-icon1"
                          }`;
                        }}
                        end
                      >
                        <div className="cercle-img1">
                          <img src="./img/icons/fans.svg" alt="icon" />
                        </div>
                        <span className="leftnav-profile-label">
                          Mes Fans et Abonnés
                        </span>
                      </NavLink>
                    </div>

                    <div className="items">
                      <NavLink
                        rel="canonical" 
                        to="/Acso"
                        exact="true"
                        className={(props) => {
                          return `${
                            props.isActive
                              ? "label-icon-active1"
                              : "label-icon1"
                          }`;
                        }}
                        end
                      >
                        <div className="cercle-img1">
                          <img
                            src="./img/icons/activiter_social.svg"
                            alt="icon"
                          />
                        </div>
                        <span className="leftnav-profile-label">
                          Mes Activités Social
                        </span>
                      </NavLink>
                    </div>

                    <div className="items">
                      <NavLink
                        rel="canonical" 
                        to="/Sws"
                        exact="true"
                        className={(props) => {
                          return `${
                            props.isActive
                              ? "label-icon-active1"
                              : "label-icon1"
                          }`;
                        }}
                        end
                      >
                        <div className="cercle-img1">
                          <img src="./img/icons/sws.svg" alt="icon" />
                        </div>
                        <span className="leftnav-profile-label">
                          Mes SWS Vues
                        </span>
                      </NavLink>
                    </div>

                    <div className="items">
                      <NavLink
                        rel="canonical" 
                        to="/Groups"
                        exact="true"
                        className={(props) => {
                          return `${
                            props.isActive
                              ? "label-icon-active1"
                              : "label-icon1"
                          }`;
                        }}
                        end
                      >
                        <div className="cercle-img1">
                          <img src="./img/icons/groupes.svg" alt="icon" />
                        </div>
                        <span className="leftnav-profile-label">
                          Mes Groupes
                        </span>
                      </NavLink>
                    </div>

                    <div className="items">
                      <NavLink
                        rel="canonical" 
                        to="/Multipages"
                        exact="true"
                        className={(props) => {
                          return `${
                            props.isActive
                              ? "label-icon-active1"
                              : "label-icon1"
                          }`;
                        }}
                        end
                      >
                        <div className="cercle-img1">
                          <img src="./img/icons/pages.svg" alt="icon" />
                        </div>
                        <span className="leftnav-profile-label">Mes Pages</span>
                      </NavLink>
                    </div>

                    <div className="items">
                      <NavLink
                        rel="canonical" 
                        to="/Primes"
                        exact="true"
                        className={(props) => {
                          return `${
                            props.isActive
                              ? "label-icon-gold-active"
                              : "label-icon-gold"
                          }`;
                        }}
                        end
                      >
                        <div className="cercle-img-gold">
                          <img src="./img/icons/primes.svg" alt="icon" />
                        </div>
                        <span className="leftnav-profile-label">
                          Mes Primes
                        </span>
                      </NavLink>
                    </div>

                    <div className="items">
                      <NavLink
                        rel="canonical" 
                        to="/Wallet"
                        exact="true"
                        className={(props) => {
                          return `${
                            props.isActive
                              ? "label-icon-gold-active"
                              : "label-icon-gold"
                          }`;
                        }}
                        end
                      >
                        <div className="cercle-img-gold">
                          <img src="./img/icons/wallet.svg" alt="icon" />
                        </div>
                        <span className="leftnav-profile-label">
                          Mes Wallet
                        </span>
                      </NavLink>
                    </div>
                    {/* <div onClick={() => setTexting(!texting)} className="items"> */}

                    <div className="items"
                         onClick={handleAssistanceModals}
                          >
                      <NavLink rel="canonical" to="" className="label-icon-green">
                        <div className="cercle-img-green">
                          <img
                            width={20}
                            src="./img/icons/assistance.svg"
                            alt="icon"
                          />
                        </div>
                        <span className="leftnav-profile-label">
                          Assistance Utilisateur
                        </span>
                      </NavLink>
                    </div>
                    {assistanceModal && (
                     <AssistanceUser openPopup={(e) => setAssistanceModal(e)} />
                    )}

                    <div style={{ marginTop: "20px" }} className="items">
                      <NavLink
                        rel="canonical" 
                        to="/Parametrage"
                        exact="true"
                        className={(props) => {
                          return `${
                            props.isActive
                              ? "label-icon-active1"
                              : "label-icon1"
                          }`;
                        }}
                        end
                      >
                        <div className="cercle-img1">
                          <img src="./img/icons/parametre.svg" alt="icon" />
                        </div>
                        <span className="leftnav-profile-label">
                          Paramétrage
                        </span>
                      </NavLink>
                    </div>
                  </section>
                </div>
              </>
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* {texting ? (
                <div className="texting-box">
                   <Assistance name={userData && userData.nom} close={() => setTexting(false)}/>
                </div> ) : <></>
        } */}

      </div>
    </>
  );
};

export default LeftNav;
