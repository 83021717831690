import {CONFIGUSER_USER_NUMBER ,  GET_USER_NUMBER } from "../actions/configUser.action";
  
  const initialState = {};
  
  export default function configusersReducer(state = initialState, action) {
    switch (action.type) {
      case GET_USER_NUMBER:
        return action.payload;
      case CONFIGUSER_USER_NUMBER:
          return {
            ...state,
            minUsers: action.payload.minUsers,
            maxUsers: action.payload.maxUsers,
            PRASilver: action.payload.PRAGolde,
            PRAGolde: action.payload.PRAGolde,
            PFA: action.payload.PFA,
            souspartner: action.payload.souspartner,
            minFan: action.payload.minFan,
            maxFan: action.payload.maxFan,
            nFriendPartner: action.payload.nFriendPartner
          }
      default:
        return state;
    }
  }
  