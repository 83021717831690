import React, { useState } from "react";
import axios from "axios";
import SignInForm from "./SignInForm";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { useParams } from 'react-router-dom';

const RecoverPassword = ({ openPopup }) => {
  // const { codeparrain } = useParams();
  const [formSubmit, setFormSubmit] = useState(false);
  const [emailRecover, setEmailRecover] = useState("");

  const handleRegister = async (e) => {
    e.preventDefault();
    const getemail = document.getElementById("emailRecover").value;
    const emailError = document.querySelector(".emailRecover.error");

    emailError.innerHTML = "";
    if ( getemail === ""  ) {
          emailError.innerHTML = "Vous devez entrer E-mails";
    } else {
      await axios({
        method: "post",
        url: `/api/user/recover`,
        data: {
         emailRecover
        },
      })
        .then((res) =>  setFormSubmit(true))
        .catch((err) => console.log(err))
    }
  };

  return (
    <>
        <div className="popup-signup-container">
          <div className="modal">
            <h2 className="email-recover-title">Recuperation mot de passe</h2>
            <span className="cross" onClick={() => openPopup(false)}>
              &#10005;
            </span>
            <hr className="email-recover-hr"/>
            <p className="email-recover-para">Veuillez entrer votre adresse e-mail pour recuperer votre mot de passe .</p>
            <form action="" onSubmit={handleRegister} id="sign-up-form">
              <section className="email-recover-section">
              <br />
                <div>
                  <input
                    className="input-forme-emailRecover"
                    type="text"
                    name="emailRecover"
                    id="emailRecover"
                    onChange={(e) => setEmailRecover(e.target.value)}
                    value={emailRecover}
                    placeholder="E-mail"
                  />
                  <div className="emailRecover error general-font-family-error"></div>
                </div>
              </section>
              <div className="btn-valider-inscription-flex">
                     <button onClick={handleRegister} className="btn-valider-inscription" type="submit">Envoyer</button>
              </div>
             
              {formSubmit && <h4 className="success"> Message de récupération mot de passe a été envoyé a votre email </h4>}
            </form>
          </div>
        </div>
    </>
  );
};

export default RecoverPassword;
