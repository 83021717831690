import React , {useState , useEffect} from "react";
import { useSelector} from "react-redux";

export default function SimulateurGain({ openPopup }) {
    const configuser = useSelector((state) => state.configusersReducer);
    const configch = useSelector((state) => state.configChReducer);
    const configinf = useSelector((state) => state.configInfReducer);

    const [sectionState, setSectionState] = useState("section1");

    const [RA, setRA] = useState(Number(configuser[0] && configuser[0].maxUsers));
    const [RALayout, setRALayout] = useState((Number(configuser[0] && configuser[0].maxUsers.replace(/\D/g, '')) || '').toLocaleString('fr'));
    const [RP, setRP] = useState(Number( configuser[0] && configuser[0].nFriendPartner)); 
    const [RPLayout, setRPLayout] = useState((Number(configuser[0] && configuser[0].nFriendPartner.replace(/\D/g, '')) || '').toLocaleString('fr'));
    const [RACh, setRACh] = useState(Number(configch[0] && configch[0].maxCh));
    const [RAChLayout, setRAChLayout] = useState((Number(configch[0] && configch[0].maxCh.replace(/\D/g, '')) || '').toLocaleString('fr'));
    const [RFACh, setRFACh] = useState(Number( configch[0] && configch[0].maxFanCh));
    const [RFAChLayout, setRFAChLayout] = useState((Number(configch[0] && configch[0].maxFanCh.replace(/\D/g, '')) || '').toLocaleString('fr'));
    const [RAInf, setRAInf] = useState(Number(configinf[0] && configinf[0].maxInf));
    const [RAInfLayout, setRAInfLayout] = useState((Number(configinf[0] && configinf[0].maxInf.replace(/\D/g, '')) || '').toLocaleString('fr'));
    const [RFAInf, setRFAInf] = useState(Number( configinf[0] && configinf[0].maxFanInf));
    const [RFAInfLayout, setRFAInfLayout] = useState((Number(configinf[0] && configinf[0].maxFanInf.replace(/\D/g, '')) || '').toLocaleString('fr'));

    const [RAGoldestatus, setRAGoldestatus] = useState(0);
    const [RASilverstatus, setRASilverstatus] = useState(0);
    const [RPstatus, setRPstatus] = useState(0);
    const [RAChstatus, setRAChstatus] = useState(0);
    const [RFAChGoldstatus, setRFAChGoldstatus] = useState(0);
    const [RFAChSilverstatus, setRFAChSilverstatus] = useState(0);
    const [RAInfstatus, setRAInfstatus] = useState(0);
    const [RFAInfGoldstatus, setRFAInfGoldstatus] = useState(0);
    const [RFAInfSilverstatus, setRFAInfSilverstatus] = useState(0);

    const handleChangeRA = (e) => {
     const { value } = e.target;
     if(value) {
       setRA(Number(value.split(/(\s+)/).filter( function(e) { return e.trim().length > 0; } ).join("")));
       setRALayout((Number(value.replace(/\D/g, '')) || '').toLocaleString('fr'))
     }else{
       setRA(0);
       setRALayout(0)
     }
    }

    const handleChangeRP = (e) => {
     const { value } = e.target;
     if(value) {
       setRP(Number(value.split(/(\s+)/).filter( function(e) { return e.trim().length > 0; } ).join("")));
       setRPLayout((Number(value.replace(/\D/g, '')) || '').toLocaleString('fr'))
     }else{
       setRP(0);
       setRPLayout(0)
     }
    }

    const handleChangeRACh = (e) => {
     const { value } = e.target;
     if(value) {
       setRACh(Number(value.split(/(\s+)/).filter( function(e) { return e.trim().length > 0; } ).join("")));
       setRAChLayout((Number(value.replace(/\D/g, '')) || '').toLocaleString('fr'))
     }else{
       setRACh(0);
       setRAChLayout(0);
     }
    }

    const handleChangeRFACh = (e) => {
     const { value } = e.target;
     if(value) {
       setRFACh(Number(value.split(/(\s+)/).filter( function(e) { return e.trim().length > 0; } ).join("")));
       setRFAChLayout((Number(value.replace(/\D/g, '')) || '').toLocaleString('fr'))
     }else{
       setRFACh(0);
       setRFAChLayout(0);
     }
    }

    const handleChangeRAInf = (e) => {
     const { value } = e.target;
     if(value) {
       setRAInf(Number(value.split(/(\s+)/).filter( function(e) { return e.trim().length > 0; } ).join("")));
       setRAInfLayout((Number(value.replace(/\D/g, '')) || '').toLocaleString('fr'))
     }else{
       setRAInf(0);
       setRAInfLayout(0);
     }
    }

    const handleChangeRFAInf = (e) => {
     const { value } = e.target;
     if(value) {
       setRFAInf(Number(value.split(/(\s+)/).filter( function(e) { return e.trim().length > 0; } ).join("")));
       setRFAInfLayout((Number(value.replace(/\D/g, '')) || '').toLocaleString('fr'))
     }else{
       setRFAInf(0);
       setRFAInfLayout(0);
     }
    }

    useEffect(() => {
         
     const utilisateurCondition = (Number(configuser[0] && configuser[0].minUsers) <= Number(RA)) && (Number(configuser[0] && configuser[0].maxUsers) >= Number(RA))
     const ChallengerCondition = (Number(configch[0] && configch[0].minCh) <= Number(RACh)) && (Number(configch[0] && configch[0].maxCh) >= Number(RACh))
     const InfluenceurCondition = (Number(configinf[0] && configinf[0].minInf) <= Number(RAInf)) && (Number(configinf[0] && configinf[0].maxInf) >= Number(RAInf))

     if(utilisateurCondition){
       setRAGoldestatus(Number(RA) * Number( configuser[0] && configuser[0].PRAGolde))
       setRASilverstatus(Number(RA) * Number( configuser[0] && configuser[0].PRASilver))
       setRPstatus(Number(RP) * Number( configuser[0] && configuser[0].souspartner))
     }else{
          setRAGoldestatus(0)
          setRASilverstatus(0)
          setRPstatus(0)
     }
     if(ChallengerCondition){
       setRAChstatus(Number(RACh) * Number( configch[0] && configch[0].PRACh))
       setRFAChGoldstatus(Number(RFACh) * Number( configch[0] && configch[0].PFAChGold))
       setRFAChSilverstatus(Number(RFACh) * Number( configch[0] && configch[0].PFAChSilver))
     }else{
          setRAChstatus(0)
          setRFAChGoldstatus(0)
          setRFAChSilverstatus(0)
     }
     if(InfluenceurCondition){
       setRAInfstatus(Number(RAInf) * Number( configinf[0] && configinf[0].PRAInf))
       setRFAInfGoldstatus(Number(RFAInf) * Number( configinf[0] && configinf[0].PFAInfGold))
       setRFAInfSilverstatus(Number(RFAInf) * Number( configinf[0] && configinf[0].PFAInfSilver))
     }else{
          setRAInfstatus(0)
          setRFAInfGoldstatus(0)
          setRFAInfSilverstatus(0)
     }
 }, [ RA  , RP , RACh  , RFACh , RAInf  ,RFAInf , RASilverstatus, RAGoldestatus , RPstatus , RAChstatus , RFAChSilverstatus ,RFAChGoldstatus , RAInfstatus , RFAInfGoldstatus , RFAInfSilverstatus])


  return (
     <>
     <div className="popup-simulateur-container">
       <div className="modal">
         <span className="cross" onClick={() => openPopup(false)}>
           &#10005;
         </span>

    <section className="simulateur-page">
        {/* <div className="simulateur-sowayso"> Simulation des gains sur &nbsp; <span>  SoWaySo</span> </div> */}
        <div className="simulateur-sowayso-multisection">
            <div onClick={() => setSectionState("section1")} className={ sectionState === "section1" ? `simulateur-sowayso-section1` : `simulateur-sowayso-section2`}>Mon reseaux d’amis et abonnees</div>
            <div onClick={() => setSectionState("section2")} className={ sectionState === "section2" ? `simulateur-sowayso-section1` : `simulateur-sowayso-section2`}>Mes Activites Sociales</div>
            {/* <div onClick={() => setSectionState("section3")} className={ sectionState === "section3" ? `simulateur-sowayso-section1` : `simulateur-sowayso-section2`}>Mes Investissements en euro</div> */}
        </div>
        <section className='simulateur-utilisateur'>

{/* /////////////////////////////////////////////////////////////////// */}
   { sectionState === "section1" && <>
            <div className='simulateur-utilisateur-box'>
                    <div className='simulateur-utilisateur-title'>Utilisateur</div>
                    <div className='simulateur-utilisateur-box1'>
                    <div className='simulateur-utilisateur-ra'>
                       <div className='simulateur-utilisateur-ra1'>
                           <div className='simulateur-utilisateur-ra11'>Réseau d’ami</div>
                           <input className="simulateur-utilisateur-ra12" 
                                  onChange={handleChangeRA}
                                  value={RALayout}
                                  placeholder="0"
                                  type="text"
                                  />
                       </div>
                       <div>
                          <div style={{marginBottom : "5px"}}  
                               className='simulateur-utilisateur-ra2'>{Number(RAGoldestatus.toString().replace(/\D/g, '') || '').toLocaleString('fr')} €</div>
                          <div className='simulateur-utilisateur-ra3'>{Number(RASilverstatus.toString().replace(/\D/g, '') || '').toLocaleString('fr')} €</div>
                       </div>
                      
                    </div>
                    <div className='simulateur-utilisateur-ra'>
                       <div className='simulateur-utilisateur-ra1'>
                           <div className='simulateur-utilisateur-ra11'>R Friends Partner</div>
                           <input className="simulateur-utilisateur-ra12" 
                                  onChange={handleChangeRP}
                                  value={RPLayout}
                                  placeholder="0"
                                  type="text"/>
                       </div>
                       <div className='simulateur-utilisateur-ra3'>{RPstatus.toFixed(3)} €</div>
                    </div>
                    <div className='simulateur-utilisateur-pr'>
                         <div className='simulateur-utilisateur-pr1'> Total des primes </div>
                         <div className='simulateur-utilisateur-pr2'>{(RAGoldestatus + RASilverstatus  + RPstatus).toFixed(3)} €</div>
                    </div>
                    </div>
            </div>

{/* /////////////////////////////////////////////////////////////////// */}

            <div className='simulateur-utilisateur-box'>
                    <div className='simulateur-utilisateur-title1'>Challengeur</div>
                    <div className='simulateur-utilisateur-box1'>
                    <div className='simulateur-utilisateur-ra'>
                       <div className='simulateur-utilisateur-ra1'>
                           <div className='simulateur-utilisateur-ra11'>Réseau d’ami</div>
                           <input className="simulateur-utilisateur-ra12" 
                                  onChange={handleChangeRACh}
                                  value={RAChLayout}
                                  placeholder="0"
                                  type="text"/>
                       </div>
                       <div className='simulateur-utilisateur-ra2'>{(RAChstatus).toFixed(3)} €</div>
                    </div>
                    <div className='simulateur-utilisateur-ra'>
                       <div className='simulateur-utilisateur-ra1'>
                           <div className='simulateur-utilisateur-ra11'>R Fans Aboneés</div>
                           <input className="simulateur-utilisateur-ra12" 
                                  onChange={handleChangeRFACh}
                                  value={RFAChLayout}
                                  placeholder="0"
                                  type="text"/>
                       </div>
                       <div>
                          <div style={{marginBottom : "5px"}} 
                               className='simulateur-utilisateur-ra2'>{RFAChGoldstatus.toFixed(3)} €</div>
                          <div className='simulateur-utilisateur-ra3'>{RFAChSilverstatus.toFixed(3)} €</div>
                       </div>
                    </div>
                    <div className='simulateur-utilisateur-pr'>
                         <div className='simulateur-utilisateur-pr1'> Total des primes </div>
                         <div className='simulateur-utilisateur-pr2'>{(RAChstatus  + RFAChGoldstatus + RFAChSilverstatus).toFixed(3)} €</div>
                    </div>
                    </div>
            </div>

{/* /////////////////////////////////////////////////////////////////// */}

            <div className='simulateur-utilisateur-box'>
                    <div className='simulateur-utilisateur-title2'>Influenceur</div>
                    <div className='simulateur-utilisateur-box1'>
                    <div className='simulateur-utilisateur-ra'>
                       <div className='simulateur-utilisateur-ra1'>
                           <div className='simulateur-utilisateur-ra11'>Réseau d’ami</div>
                           <input className="simulateur-utilisateur-ra12" 
                                  onChange={handleChangeRAInf}
                                  value={RAInfLayout}
                                  placeholder="0"
                                  type="text"/>
                       </div>
                       <div className='simulateur-utilisateur-ra2'>{RAInfstatus.toFixed(3)} €</div>
                    </div>
                    <div className='simulateur-utilisateur-ra'>
                       <div className='simulateur-utilisateur-ra1'>
                           <div className='simulateur-utilisateur-ra11'>R Fans Aboneés</div>
                           <input className="simulateur-utilisateur-ra12" 
                                  onChange={handleChangeRFAInf}
                                  value={RFAInfLayout}
                                  placeholder="0"
                                  type="text"/>
                       </div>
                       <div>
                          <div style={{marginBottom : "5px"}} 
                               className='simulateur-utilisateur-ra2'>{RFAInfGoldstatus.toFixed(3)} €</div>
                          <div className='simulateur-utilisateur-ra3'>{RFAInfSilverstatus.toFixed(3)} €</div>
                       </div>
                    </div>
                    <div className='simulateur-utilisateur-pr'>
                         <div className='simulateur-utilisateur-pr1'> Total des primes </div>
                         <div className='simulateur-utilisateur-pr2'>{(RAInfstatus + RFAInfGoldstatus + RFAInfSilverstatus).toFixed(3)} €</div>
                    </div>
                    </div>
            </div>
            </>
        }
{/* /////////////////////////////////////////////////////////////////// */}

    { sectionState === "section2" && <>
      
        <div className="section2-box">
            <div className="section2-box1">
                <div className="section2-box11">
                     <div className="section2-box111">Consulation Blog et Photo</div>
                     <div className="section2-box112">900 Mois</div>
                </div>          
                <div className="section2-box11">
                     <div className="section2-box111">Consulation Video et Music</div>
                     <div className="section2-box112">300 Mois</div>
                </div>  
                <div className="section2-box11">
                     <div className="section2-box111">Consulation commercial</div>
                     <div className="section2-box112">300 Mois</div>
                </div>  
                <div className="section2-box11">
                     <div className="section2-box111">Vous utilliserez</div>
                     <div className="section2-box1122">10 Pack</div>
                </div>  
                <div className="section2-box11">
                     <div className="section2-box111">Frais packs et publication</div>
                     <div className="section2-box1123">-480.00 €</div>
                </div>  
                {/* <div className="section2-box11">
                     <div className="section2-box111">La prime AcSo FP</div>
                     <div className="section2-box1124">0 Mois</div>
                </div>   */}
            </div>
            
{/* //////////////////////////////////////////////////////////////////// */}

            <div className="section2-box1">
                <div className="section2-box11">
                     <div className="section2-box111">Publication Blog et Photo</div>
                     <div className="section2-box1121">150 Mois</div>
                     <div className="section2-box1121">200 Vues</div>
                </div>          
                <div className="section2-box11">
                     <div className="section2-box111">Publication video et music</div>
                     <div className="section2-box1121">6 Mois</div>
                     <div className="section2-box1121">7000 Vues</div>
                </div>  
                <div className="section2-box11">
                     <div className="section2-box111">Publication commercial</div>
                     <div className="section2-box1121">0 Mois</div>
                     <div className="section2-box1121">0 Mois</div>
                </div>  
                <div className="section2-box11">
                     <div className="section2-box111">Vos primes SoBlog</div>
                     <div className="section2-box11231">750.00 €</div>
                     <div className="section2-box11241">750.00 €</div>
                </div>  
                <div className="section2-box11">
                     <div className="section2-box111">Vos primes SoMedia</div>
                     <div className="section2-box11231">420.00 €</div>
                     <div className="section2-box11241">420.00 €</div>
                </div>   
                <div className="section2-box11">
                     <div className="section2-box111">Vos primes Mark</div>
                     <div className="section2-box11231">0 €</div>
                     <div className="section2-box11241">0 €</div>
                </div>  
            </div>
        </div>
        <div className="section2-box111-totale"> 
           <div className="section2-box111-totale1"> Total des primes sur les activites sociales </div>
           <div className="section2-box111-totale2"> 2 340.00 € </div>
        </div>


    </>}

    {/* { sectionState === "section3" && <>
          <div className="section3-box">

           <div className="section3-container">   
            <div className="section3-box1">
              <div className="section3-box11">
                 Investissement boursier
              </div>
              <div className="section3-box12">
                   <span>Bonus</span> 0
              </div>
            </div>
            <div className="section3-box1">
              <div className="section3-box111">
                 Investissement solidaire
              </div>
              <div style={{width: "100px"}}></div>
            </div>
            </div> */}

            {/* //////////////////////////////////////////////////////////// */}

            {/* <div className="section3-container">  

             <div className="section3-box1">
              <div className="section3-box11">
                 Investissement solidaire
              </div>
              <div className="section3-box12">
                   <span>Bonus</span> 0
              </div>
            </div>

             <div className="section3-box1">
              <div className="section3-box111">
                   Capital d’invest
              </div>
              <div className="section3-box122">
                   <span>Bonus</span> 0
              </div>
             </div>

             <div className="section3-box1">
               <div className="section3-box1-ajuste">
                   <div className="section3-box112">
                     Revalorisation annuelle 
                   </div>
                   <div className="section3-box113">
                     0
                   </div>
              </div>
              <div style={{width : "100px"}}></div>
            </div>

            <div className="section3-box1">
              <div className="section3-box11">
                  Gain annuel sur investisement
              </div>
              <div className="section3-box121">
                    0
              </div>
            </div>

            </div>


          </div>
     </>} */}
        </section>
    </section>
    </div>
    </div>
    </>
  )
}
