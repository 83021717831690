import axios from "axios";
export const ACTIVATION_ACCOUNT = "ACTIVATION_ACCOUNT";
export const SUPPRESSION_ACCOUNT = "SUPPRESSION_ACCOUNT";

export const StatusVerification = (iduser , verifState) => {
    return (dispatch) => {
      return axios({
        method: "put",
        url: `/api/superAdmin/verificationAccount`,
        data: { 
                id: iduser ,
                verification : verifState
              },
      })
        .then((res) => {
          dispatch({ type: ACTIVATION_ACCOUNT , payload: { iduser , verifState } });
        })
        .catch((err) => console.log(err));
    };
  };

  export const StatusFinaleVerification = (iduser , verifState) => {
    return () => {
      return axios({
        method: "put",
        url: `/api/superAdmin/finaleverificationAccount`,
        data: { 
                id: iduser ,
                finaleverified : verifState
              },
      })
        .catch((err) => console.log(err));
    };
  };

  export const StatusActivation = (iduser , activationState) => {
    return () => {
      return axios({
        method: "put",
        url: `/api/superAdmin/statusActivation`,
        data: { 
                id: iduser ,
                activationState : activationState
              },
      })
        .catch((err) => console.log(err));
    };
  };

  export const SuppressionUser = (iduser) => {
    return (dispatch) => {
      return axios({
        method: "delete",
        url: `/api/superAdmin/deleteUser`,
        data: { 
                id: iduser ,
              },
      })
        .then((res) => {
           dispatch({ type: SUPPRESSION_ACCOUNT  , payload: iduser  });
        })
        .catch((err) => console.log(err));
    };
  };