import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useSelector } from "react-redux";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: "none",
  border: "none",
  boxShadow: "none",
}));
function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function GreetingRow(props) {
  const userData = useSelector((state) => state.userReducer);
  return (
    <>
      <ElevationScroll {...props}>
        <AppBar className="Greeting-Row">
          <Toolbar>
            <Box sx={{ flexGrow: 1 }} className="CActu-Item">
              <Grid container spacing={0}>
                <Grid item xs={4} className="first-Item">
                  <Item>
                    {" "}
                    <img
                      src={process.env.PUBLIC_URL + "../img/random-user.png"}
                      alt="user-pic" className="userProfil-picture"
                    />
                {/* <img src={process.env.REACT_APP_API_URL + userData.picture} alt="user-pic" />  */}
                  </Item>
                  <Item className="Greet-Item">
                    <span> Bonjour {userData.nom} {userData.prenom}</span>
                    <p className="greeting">Bienvenue sur TanitCoin</p>
                  </Item>
                </Grid>
                <Grid item xs={8}>
                  <Item className="Actu-Item">
                    {" "}
                    Votre solde actuel est 0,00 TC Gold et 0,00 TC Sliver
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </>
  );
}
