import React from 'react'
import LeftNav from "../LeftNav";

export default function Parametrage() {
  return (
    <div className='parametrage-page'>
       <div className='parametrage-left-nav'>
          <LeftNav/>
       </div>
       <div className='parametrage-container'>
          <h1>Parametrage</h1>  
       </div>
    </div>
  )
}


