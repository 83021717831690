import * as React from "react";
import Box from "@mui/material/Box";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

function createData(Date, Heure, Valeur, Etat) {
  return { Date, Heure, Valeur, Etat };
}

const rows = [
  createData("--/--/----", "--:--", "0,00", " "),
  createData("--/--/----", "--:--", "0,00", " "),
  createData("--/--/----", "--:--", "0,00", " "),
];

export default function TransfertAmis() {
  return (
    <Box sx={{ flexGrow: 1 }} className="TransfertEntreAmis-Container">
      <div className="Row-1">
        Vous pouvez recevoir et envoyé de solde en TanitCoin Silver entre votre
        réseau d’amis de SoWaySo
      </div>
      <div className="Row-2">
        <div className="Item">
          <span className="First-Item">Transfert TanitCoin Silver</span>
        </div>
        <div className="Item">
          <input type="number" placeholder="0,00" />
        </div>
        <div className="Item">
          <input type="number" placeholder="Ami/FP" />
        </div>
      </div>
      <div className="Row-3">
        <div className="Validation-text">
          Vous pouvez transférer à chaque ami une solde de 05 jusqu’à 1 000 TC
          Gold par transaction mensuellement.
        </div>
        <div>
          <button className="Validation-button">Valider le transfert</button>
        </div>
      </div>
      <div className="Row-4">
        Vous pouvez recevoir et envoyé des primes de collaboration marketing
        (challenge ou influence) sur SoWaySo
      </div>
      <div className="Row-5">
        <input type="number" placeholder="ID" />
        <input type="text" placeholder="Company" />
        <div className="GoldTC-Container">
          <input type="number" placeholder="0,00" className="GoldTC" />
          <label className="GoldTC-Label">75%</label>
        </div>
        <div className="SliverTC-Container">
        <input type="number" placeholder="0,00" className="SliverTC" />
        <label className="SliverTC-Label">25%</label>
        </div>
      </div>
      <div className="Row-6">
        <div className="Validation-text">
        Vous deviez avoir un profil professionnel VALIDE sur SoWaySo.
        </div>
        <div>
          <button className="Validation-button">Valider le transfert</button>
        </div>
      </div>
      <div className="Row-7">Historique des opérations de transfert </div>
      <div className="Row-8">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow className="Table-Row">
                <TableCell className="Table-Cell">Date</TableCell>
                <TableCell className="Table-Cell" align="right">
                  Heure
                </TableCell>
                <TableCell className="Table-Cell" align="right">
                  Valeur
                </TableCell>
                <TableCell className="Table-Cell" align="right">
                  Etat
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.Date}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.Date}
                  </TableCell>
                  <TableCell align="right">{row.Heure}</TableCell>
                  <TableCell align="right">{row.Valeur}</TableCell>
                  <TableCell align="right">{row.Etat}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Box>
  );
}
