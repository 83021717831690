import * as React from "react";
import Box from "@mui/material/Box";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

function createData(Date, Heure, Valeur, Etat) {
  return {Date, Heure, Valeur, Etat };
}

const rows = [
  createData('--/--/----', '--:--', '0,00',' '),
  createData('--/--/----', '--:--', '0,00',' '),
  createData('--/--/----', '--:--', '0,00',' ')
];

export default function AchatTCGold() {
  return (
    <Box sx={{ flexGrow: 1 }} className="AchatTCGold-Container">
      <div className="Row-1">
        Vous pouvez alimenter votre compte par l’achat TanitCoin Gold pour
        financer tous les opérations de payement et d’investissement
      </div>
      <div className="Row-2">
        <div className="Item">
          <span className="First-Item">Achat TanitCoin Gold</span>
        </div>
        <div className="Item">
          <input type="number" placeholder="0,00" />
        </div>
        <div className="Item">
        <input type="number" placeholder="Code CVV" />
        </div>
      </div>
      <div className="Row-3">
        <div className="Validation-text">
          Vous pouvez alimenter votre compte par l’achat de 10 jusqu’à 5 000 TC
          Gold par transaction sans frais supplémentaire.{" "}
        </div>
        <div>
       <button className="Validation-button">Valider l'achat</button>
        </div>
      </div>
      <div className="Row-4">
        <div>Solde TanitCoin Gold</div>
        <div>0,00</div>
      </div>
      <div className="Row-5">Historique des opérations d’achat </div>
      <div className="Row-6"> 
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow  className="Table-Row">
            <TableCell className="Table-Cell">Date</TableCell>
            <TableCell className="Table-Cell" align="right">Heure</TableCell>
            <TableCell className="Table-Cell" align="right">Valeur</TableCell>
            <TableCell className="Table-Cell"align="right">Etat</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.Date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
             <TableCell component="th" scope="row">
                {row.Date}
              </TableCell>
              <TableCell align="right">{row.Heure}</TableCell>
              <TableCell align="right">{row.Valeur}</TableCell>
              <TableCell align="right">{row.Etat}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer></div>
    </Box>
  );
}
