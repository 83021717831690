import React from "react";
import { useSelector } from "react-redux";

const ReseauxSocial = ({ openPopup }) => {
  const userData = useSelector((state) => state.userReducer);
  const formattedBody = `
  <section style='width: 100% ; margin-top; -40px ; font-family:Gill Sans,Gill Sans MT,Calibri,Trebuchet MS,sans-serif'> 
     <img src='https://i.ibb.co/v1HbHxF/sowaysolab-2.png' alt='sowaysolab-2' border="0" />
     <h3 style='color:#0588B3'>Bonjour,</h3>
     <p> <span style='color : #72B5CB ; aline'> je vous invite de  <span style='color : #4CA417'>rejoindre</span> mon réseau social solidaire   <span style='color:#0588B3'> SoWaySo.com </span></span>
      <span style='color : #717171 ; font-weight: bold;'>
      C’est le premier réseau social européen.
      C’est l’unique réseau social qui valorise votre activité sociale :</span>
      <span style='color : #717171'>
      Vous pouvez gagner jusqu’à 100 € pour chaque 100 amis ajoutés sur votre liste d’amis .
      Sur SoWaySo vous recevrez des primes jusqu’à 50€ pour chaque 1000 vues sur les publications et jusqu’à 20 000 € pour chaque million de vues.
      </span>
   <div style='width: 100px; margin: 20px auto 20px auto ;'> <a style='color: #fff; text-decoration: none;' href=https://sowayso.com/#/${userData._id}> <div style='padding-left: 5px;  background-color: #489E0C; height: 30px; display: flex; align-items: center; justify-content: center; font-size: large; font-weight: bold; border-radius: 5px; cursor: pointer;'>  Rejoindre </div>	 </a> </div> 
  </section>
  `;
  const mailToLink = "mailto:?body=" + encodeURIComponent(formattedBody);
  const mailToLink1 = "https://wa.me/?text=" + encodeURIComponent(formattedBody);

  return (
    <>
      <div className="popup-partage-container">
        <div className="modal">
          <div className="Partager-label-button">
          <h4>Partager mon identifiant </h4>
          <div onClick={() => {navigator.clipboard.writeText(`https://sowayso.com/#/${userData._id}`)}}>
                    <div className="copie-icone-box-res">
                        <img width={13} src="./img/icons/copie.svg" alt="copie" />
                    </div>
          </div>
          </div>
          <span className="cross" onClick={() => openPopup(false)}>
            &#10005;
          </span>
          {/* <hr
            style={{
              color: "#e7e9eb",
              backgroundColor: "#e7e9eb",
              height: "2px",
              marginTop: "5px",
              marginBottom: "5px",
            }}
          /> */}
          <div className="flex-icone-sociale-media" >
            <div class="fb-share-button">
              <a 
                rel="canonical"
                target="_blank"
                href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fsowayso.com%2F%23%2F${userData._id}&amp;src=sdkpreparse`}
                class="fb-xfbml-parse-ignore"
              >
                <img width={30} src="./img/icons/facebook.svg" alt="rocket" />
              </a>
            </div>

            <div class="fb-share-button">
              <a>
                <img width={30} src="./img/icons/instagram.svg" alt="instagram" />
              </a>
            </div>

            <div class="fb-share-button">
              <a>
                <img width={30} src="./img/icons/tik-tok.svg" alt="instagram" />
              </a>
            </div>

            <div>
              {/* <a
                target="_blank"
                href={`https://wa.me/?text=${userData.nom} ${userData.prenom} vous inviter a joindre SoWaySo https://sowayso.com/%23/${userData._id}`}
              > */}
             <a rel="canonical"
                target="_blank"
                href={`https://wa.me/?text=${userData.nom} ${userData.prenom} vous inviter a joindre SoWaySo https://sowayso.com/%23/${userData._id}`}
              > 
                <img width={30} src="./img/icons/whatsapp.svg" alt="rocket" />
              </a>
            </div>
            <div>
              {/* <a href={mailToLink}> */}
                <img width={30} src="./img/icons/gmail1.svg" alt="rocket" />
              {/* </a> */}
            </div>
            <div>
              <a rel="canonical" href="http://m.me/" target="_blank">
                <img width={30} src="./img/icons/messenger.svg" alt="rocket" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReseauxSocial;
