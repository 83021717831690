import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";
import LeftNav from "../LeftNav";
import UploadImg from "./UploadImg"
import ReseauxSocial from "../Reseaux/ReseauxSocial";
import SimulateurGain from "../SimulateurGain/index"
import Assistance from "../Assistance"


const UpdateProfil = () => {
  const userData = useSelector((state) => state.userReducer);
  const [counterFriends, setCounterFriends] = useState(0);
  const [counterFP, setCounterFP] = useState(0);
  const [partageModal, setPartageModal] = useState(false);
  const [simulateurModal, setSimulateurModal] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [texting, setTexting] = useState(false)



  const handlePartageModals = (e) => {
    if (e.target.id === "partage") {
      setPartageModal(true);
    }
  };

  const handlesimulateurModal = (e) => {
    setSimulateurModal(true);
  };

  useEffect(() => {
    const changeWidth = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", changeWidth);
    return () => window.removeEventListener("resize", changeWidth);
  }, []);

  useEffect(() => {
    setCounterFriends(userData && userData.friends && userData.friends.length);
    setCounterFP(userData && userData.friendsPartner && userData.friendsPartner.length);
  }, [userData]);

  return (
    <>
    <div className="profil-page">
    <div >
        <LeftNav />
    </div>
    <section className="profile-container">
      <div className="profile-header">
        <img className="fond-profile-img" src="./img/autumn.jpg" alt="img-log" />

        <div className="user-profile-img">
           <img className="user-profile-img1" src={userData.picture} alt="img-log" />
        </div>
        <div className="user-profile-img">
           <img className="connection-green" src="./img/icons/connection_green.svg" alt="img-log" />
        </div>


        <Popup
          trigger={
          <div className="edite-fond-profile-img">
            <img className="edite-fond-profile-img1" src="./img/icons/camera.svg" alt="img-log" />
         </div>
          }
          position={["bottom center", "bottom right", "bottom left"]}
          closeOnDocumentClick
        >
          <UploadImg/>
        </Popup>
      </div>

      <div className="profile-information">
        <div className="profile-information1">
        <div 
            onClick={() => setTexting(!texting)}
            className="button-message-amie"
            >
                    <img
                     src="./img/icons/logo-sowayso-icon.svg"
                     alt="copie"
                      /> 
        </div>
        {texting ? (
                <div className="texting-box">
                   <Assistance name={userData && userData.nom} close={() => setTexting(false)}/>
                </div> 
                ) : <></>
            }
          <div className="profile-information1-ajuste">{counterFriends} Amis</div>
          <div className="profile-information1-ajuste">{counterFP} FP</div>
          <div className="profile-information1-ajuste">0 Fans</div>
        </div>
        <div className="profile-information2">
          <h3> {userData.nom} {userData.prenom}</h3>
        </div>
        <div className="profile-information3">
          <div className="profile-information31">
            <div className="profile-information31-box">
            <div className="profile-information311"
                 onClick={handlePartageModals}
                 id="partage">Partager</div>
            <div onClick={() => {navigator.clipboard.writeText(`https://sowayso.com/#/${userData._id}`)}}>
                    <div className="copie-icone-box-res">
                        <img width={13} src="./img/icons/copie.svg" alt="copie" />
                    </div>
            </div>
            </div>
            {partageModal && ( <ReseauxSocial openPopup={(e) => setPartageModal(e)} /> )}
          </div>
          <div className="profile-information32">
            <img onClick={handlesimulateurModal} width={20} src="./img/icons/utilisateur-bleu.svg" alt="utilisateur-bleu" />
           { (screenWidth > 650) &&  <div onClick={handlesimulateurModal} className="profile-information322">Simulation</div>}
            {simulateurModal && <SimulateurGain openPopup={(e) => setSimulateurModal(e)}/>}
          </div>
        </div>
      </div>
       
    <div className="post-profile">

      <div className="box-profile">
        <div className="fist-box-profile">

          <div className="fist-box-profile1">
            <img className="fist-box-profile11" src={userData.picture} alt="img-log" />
            <input type="text" placeholder="Bio" className="fist-box-profile12" />
          </div>
          <div className="fist-box-profile2">
            <div className="flex-profile2">
              <img className="fist-box-profile21" src="./img/icons/video.svg" alt="camera" />
              <div className="fist-box-profile22">Live Video</div>
            </div>
            <div className="flex-profile2">
              <img className="fist-box-profile23" src="./img/icons/gallery.svg" alt="img-log" />
              <div className="fist-box-profile24">Photo/video</div>
            </div>
          </div>
          <div className="fist-box-profile3">
            <div className="button-publier">Publier</div>
          </div>
        </div>
        <div className="second-box-profile">Introduction</div>
      </div> 

      <div className="box-profile1">
        <div className="fist-box-profile1">
                  <h4 className="h41">SoWaySo</h4>
                  <span></span>
                  <span></span>
                  <h4 className="h42">Votre premier Challenge STARTER</h4>
                  <p>
                     Les 3 premiers Mois: vous devez créer votre réseau d’amis et réseau FRIEND PARTNER.
                    <br />
                    Vous recevez 50 € Gold sur chaque 100 amis ajoutée et 50 € Silver sur les réseaux d’amis de
                    vos FRIEND PARTNER.
                    <br />
                    Pendant les 6 premiers mois: vous devez crée votre réseau des Fans abonnés et Vous recevez 500 € sur chaque 10 000 Fans abonnés et jusqu’a 5 M de Fans.
                  </p>
                  <img
                    width={20}
                    src="./img/icons/share-white.svg"
                    alt="icon"
                  />
        </div>
        <div className="second-box-profile1"></div>
      </div> 

    </div>

    </section>
    </div>
    </>
  );
};

export default UpdateProfil;
