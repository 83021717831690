import { combineReducers } from 'redux';
import userReducer from './user.reducer';
import usersReducer from './users.reducer';
import postReducer from './post.reducer';
import errorReducer from './error.reducer';
import allPostsReducer from './allPosts.reducer';
import trendingReducer from './trending.reducer';
import configusersReducer from "./configuser.reducer"
import configChReducer from "./configCh.reducer "
import configInfReducer from "./configInf.reducer"

export default combineReducers({
  configInfReducer,
  configChReducer,
  configusersReducer,
  userReducer,
  usersReducer,
  postReducer,
  errorReducer,
  allPostsReducer,
  trendingReducer
});