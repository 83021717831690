import React from "react";


const AssistanceUser = ({ openPopup }) => {


  return (
    <>
      <div className="popup-assistance-investisseur-container">
        <div className="modal">

          <span className="cross" onClick={() => openPopup(false)}>
            &#10005;
          </span>
          <div className="assistance-box">
            <h4>Relations investisseurs</h4>
            <input className="assistance-box-input" 
                   placeholder="Company"/>
            <input className="assistance-box-input" 
                   placeholder="Nom Prénom"/>
            <input className="assistance-box-input" 
                   placeholder="Email"/>
            <textarea className="assistance-box-space-area" 
                   placeholder="Sujet"/>  
            <div className="envoyer-assistance-box">
                <div className="button-assistance-box">Envoyer</div>  
                <p>Invest.SWS@sowayso.com</p>
            </div>
             
          </div>
        </div>
      </div>
    </>
  );
};

export default AssistanceUser;
