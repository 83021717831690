import React , {useState , useEffect} from 'react';
import { useDispatch , useSelector } from "react-redux";
import { StatusVerification , StatusFinaleVerification , StatusActivation } from "../../actions/superAdminMAC.action";
import { SuppressionUser } from "../../actions/superAdminMAC.action";
import { getUsers } from "../../actions/users.actions";
import Popup from "reactjs-popup";
import ModaleInformationUser from "./ModaleInformationUser";

export default function SuperAdminBody() {
  const dispatch = useDispatch();
  const [informationUserModal, setInformationUserModal] = useState(false);
  const [dataToPropsModal, setDataToPropsModal] = useState({});
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const usersData = useSelector((state) => state.usersReducer);
  const [users, setUsers] = useState(usersData);

  const handlerFilter = (e) => {
   setUsers( 
  usersData && usersData.filter((el) =>(el.nom + " " + el.prenom).toUpperCase().includes(e.target.value.toUpperCase()))
      )
  }


  const handleModalsInformationUser = (val) => {
    setInformationUserModal(true);
    setDataToPropsModal(val)
};

const handlerSuppression = (iduser ) => {
  let confirm = window.confirm(`est-ce que vous été sur de supprimer le compte de ${usersData.nom}`)
   if (confirm == true) {
     dispatch(SuppressionUser(iduser))
   } else {
       console.log("no suppression")
   }
 };


const totaleInscPerDay = () => {
  const dateCondition = (new Date(new Date().setDate(new Date().getDate() - 1))).toLocaleDateString("en-GB").split("/").reverse().join("-")
  const dateNow = new Date().toLocaleDateString("en-GB").split("/").reverse().join("-")
  const result = usersData.filter(val => (val.createdAt.split("/").reverse().join("-").slice(0, 10) <= dateNow) &&  (dateCondition <= val.createdAt.split("/").reverse().join("-").slice(0, 10)) );
  return result
}

const totaleInscPerWeek = () => {
  const dateCondition = (new Date(new Date().setDate(new Date().getDate() - 7))).toLocaleDateString("en-GB").split("/").reverse().join("-")
  const dateNow = new Date().toLocaleDateString("en-GB").split("/").reverse().join("-")
  const result = usersData.filter(val => (val.createdAt.split("/").reverse().join("-").slice(0, 10) <= dateNow) &&  (dateCondition <= val.createdAt.split("/").reverse().join("-").slice(0, 10)) );
  return result
}

const totaleInscPerMonth = () => {
  const dateCondition = (new Date(new Date().setMonth(new Date().getMonth() - 1))).toLocaleDateString("en-GB").split("/").reverse().join("-")
  const dateNow = new Date().toLocaleDateString("en-GB").split("/").reverse().join("-")
  const result = usersData.filter(val => (val.createdAt.split("/").reverse().join("-").slice(0, 10) <= dateNow) &&  (dateCondition <= val.createdAt.split("/").reverse().join("-").slice(0, 10)) );
  return result
}

  const totaleInsc = () => {
    return usersData && usersData.length
  }

  const handlerStateActivtion = (iduser , activationState , client) => {
    let confirm = window.confirm(`est-ce que vous été sur de changer le statue ${activationState  ? "Activer" : "Déactiver"} pour le compte de ${client}`)
    if (confirm == true) {
      dispatch(StatusActivation(iduser , activationState))
      dispatch(getUsers())
      // setStatusCounte(!statusCounte)
    } else {
        console.log("no action")
    }
  };

  const handlerStatusVerification = (iduser , verifState , client) => {
    let confirm = window.confirm(`est-ce que vous été sur de changer le statue ${verifState === "en-cours" ? "en-cours" : "valider"}  pour le compte de ${client}`)
    if (confirm == true) {
      dispatch(StatusVerification(iduser , verifState))
      dispatch(getUsers())
      // setStatusCounte(!statusCounte)
    } else {
        console.log("no action")
    }
  };

  const handlerStatusFinaleVerification = (iduser , verifState , client) => {
    let confirm = window.confirm(`est-ce que vous été sur de changer le statue ${verifState === "en-cours" ? "en-cours" : "valider"} pour le compte de ${client}`)
    if (confirm == true) {
      dispatch(StatusFinaleVerification(iduser , verifState))
      dispatch(getUsers())
      // setStatusCounte(!statusCounte)
    } else {
        console.log("no action")
    }
  };


  const filterdate = () => {
    const result = usersData.filter(val => ((val.createdAt.split("/").reverse().join("-").slice(0, 10)  <= date2) &&  (date1 <= val.createdAt.split("/").reverse().join("-").slice(0, 10))) );
    return setUsers(result)
  }

  useEffect(() => {
    setUsers(usersData)
}, [usersData ])
  
  return (

    <div className='super-admin-back'>

      <section className='compter-d-w-m'>
        <div className="super-admin-list-amie-flex-box">
          <div onClick={() => setUsers(totaleInscPerDay())}
               className='superAdmin-box-label'><span>Inscription par jour</span></div>
          <div className='superAdmin-box-value'>{totaleInscPerDay().length}</div>
        </div>
        <div className="super-admin-list-amie-flex-box">
          <div onClick={() => setUsers(totaleInscPerWeek())}
               className='superAdmin-box-label'><span>Inscription par semaine</span></div>
          <div className='superAdmin-box-value'>{totaleInscPerWeek().length}</div>
        </div>
        <div className="super-admin-list-amie-flex-box">
          <div onClick={() => setUsers(totaleInscPerMonth())}
               className='superAdmin-box-label'><span>Inscription par mois</span></div>
          <div className='superAdmin-box-value'>{totaleInscPerMonth().length} </div>
        </div>
        <div className="super-admin-list-amie-flex-box">
          <div onClick={() => setUsers(usersData)}
               className='superAdmin-box-label'><span>Inscription totale</span> </div>
          <div className='superAdmin-box-value'>{totaleInsc()}</div>  
        </div>
      </section>

      <section className='count-date-box'>
      <input onChange={handlerFilter} className='recherche' placeholder='recherche' type="text"/>
      <section className='count-date'>
      

        <div className='container'>
         <input className='count-year-start'
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                placeholder='de'
                type="text"
                name="date1"
                id="date1"
                onChange={(e) => setDate1(e.target.value)}
                value={date1}/>
         <input className='count-year-end'
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                placeholder='a'
                type="text"
                name="date2"
                id="date2"
                onChange={(e) => setDate2(e.target.value)}
                value={date2}/>
          <div className='filter-date'
                onClick={() => filterdate()} 
                >Filter date</div>      
        </div>
      </section>
      </section>

<table>
  <thead>
  <tr>
    <th>Order</th>
    <th>Nom Utilisateur</th>
    <th>Date d’inscription</th>
    <th>Frais d’inscription</th>
    <th>Date d’activation</th>
    <th>Frais d’activation</th>
    <th>Réseaux d’ami</th>
    <th>Signaliement</th>
  </tr>
  </thead>

  <tbody>
  {users.map((val , index)=>(
  <tr  key={index}>
    <td className='tr-td-1' 
     >{Number(index) + 1}</td>
    <td onClick={() => handleModalsInformationUser(val)} className='tr-td-2'>{val.nom} {" "}  {val.prenom}</td>
    <td className='tr-td-3'>{val.createdAt.split('').slice(0, 10)} {" - "} {val.createdAt.split('').slice(11, 16)}</td>
    <td className='tr-td-4'>
      {
          val.verified === "en-cours" ? 
          <div className='tr-td-4-flex'>
               <Popup
                 trigger={
                    <div className='en-cours'>En cours</div> 
                 }
                 position={["bottom center", "bottom right", "bottom left"]}
                 closeOnDocumentClick
                 >
                 <div className='en-cours-button'
                      onClick={() => handlerStatusVerification(val._id , "en-cours" , val.nom + " " + val.prenom)}
                      >En-cours</div>
                 <div className='valider-button'
                      onClick={() => handlerStatusVerification(val._id , "valider" , val.nom + " " + val.prenom)}
                       >Valider</div>
               </Popup>
             <div className='envoyer'>Envoyer</div> 
          </div>
          
          : 
          (val.verified === "valider" ? 

          <div className='tr-td-4-flex'>
             <Popup
                 trigger={
                    <div className='Valider'>Valider</div>
                 }
                 position={["bottom center", "bottom right", "bottom left"]}
                 closeOnDocumentClick
                 >
                 <div className='en-cours-button'
                      onClick={() => handlerStatusVerification(val._id , "en-cours" , val.nom + " " + val.prenom)}
                      >En-cours</div>
                 <div className='valider-button'
                      onClick={() => handlerStatusVerification(val._id , "valider" , val.nom + " " + val.prenom)}
                       >Valider</div>
               </Popup>
             <div className='envoyer'>Envoyer</div>
          </div> 
          : 
            <></>
          )
      }
    </td>
    <td className='tr-td-3'>{val.updatedAt.split('').slice(0, 10)} {" - "} {val.updatedAt.split('').slice(11, 16)}</td>
    <td className='tr-td-4'>
    {
          val.finaleverified === "en-cours" ? 
          <div className='tr-td-4-flex'>
               <Popup
                 trigger={
                    <div className='en-cours'>En cours</div> 
                 }
                 position={["bottom center", "bottom right", "bottom left"]}
                 closeOnDocumentClick
                 >
                 <div className='en-cours-button'
                      onClick={() => handlerStatusFinaleVerification(val._id , "en-cours" , val.nom + " " + val.prenom)}
                      >En-cours</div>
                 <div className='valider-button'
                      onClick={() => handlerStatusFinaleVerification(val._id , "valider" , val.nom + " " + val.prenom)}
                       >Valider</div>
               </Popup>
             <div className='envoyer'>Envoyer</div> 
          </div>
          
          : 
          (val.finaleverified === "valider" ? 

          <div className='tr-td-4-flex'>
             <Popup
                 trigger={
                    <div className='Valider'>Valider</div>
                 }
                 position={["bottom center", "bottom right", "bottom left"]}
                 closeOnDocumentClick
                 >
                 <div className='en-cours-button'
                      onClick={() => handlerStatusFinaleVerification(val._id , "en-cours" , val.nom + " " + val.prenom)}
                      >En-cours</div>
                 <div className='valider-button'
                      onClick={() => handlerStatusFinaleVerification(val._id , "valider" , val.nom + " " + val.prenom)}
                       >Valider</div>
               </Popup>
             <div className='envoyer'>Envoyer</div>
          </div> 
          : 
            <></>
          )
      }
    </td>
    <td className='tr-td-5'>{val.friends && val.friends.length}</td>
    <td className='tr-td-6'>
      {
          val.activation  ? 
          
          <Popup
          trigger={
            <div className='tr-td-6-activer'>Activer</div> 
          }
          position={["bottom center", "bottom right", "bottom left"]}
          closeOnDocumentClick
        >
          <div className='deactiver-button' 
               onClick={() => handlerStateActivtion(val._id , false , val.nom + " " + val.prenom)} >Déactiver</div>
          <div className='suppression-button' 
               onClick={() => handlerSuppression(val._id)} >Supprimer</div>
        </Popup>
        
          : 
          
          <Popup
          trigger={
            <div onClick={() => handlerSuppression(val._id)}
                 className='tr-td-6-deactiver'>Déactiver</div>
          }
          position={["bottom center", "bottom right", "bottom left"]}
          closeOnDocumentClick
        >
          <div className='activer-button' 
               onClick={() => handlerStateActivtion(val._id , true , val.nom + " " + val.prenom)} >Activer</div>
          <div className='suppression-button' 
               onClick={() => handlerSuppression(val._id)} >Supprimer</div>
        </Popup>
      }
     </td>
    </tr>
  ))}
  </tbody>
    {informationUserModal && <ModaleInformationUser userData={dataToPropsModal}  openPopup={(e) => setInformationUserModal(e)}/>}
 </table>

    </div>
  )
}
