import React from 'react'
import LeftNav from "../LeftNav";

export default function Acso() {
  return (
    <div className='acso-page'>
       <div className='acso-left-nav'>
          <LeftNav/>
       </div>
       <div className='acso-container'>
          <h1>AcSo</h1>  
       </div>
    </div>
  )
}
