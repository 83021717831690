import { GET_USERS } from "../actions/users.actions";
import { SUPPRESSION_ACCOUNT } from "../actions/superAdminMAC.action";

const initialState = {};

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      return action.payload;
    // case ACTIVATION_ACCOUNT:
    //     return state.map((user) => {
    //       if (user._id === action.payload.iduser) {
    //         return {
    //           ...user,
    //           verified : action.payload.verifState,
    //         };
    //       }
    //       return user;
    //     });
        
    case SUPPRESSION_ACCOUNT:
          return state.filter((user) => user._id !== action.payload);
    default:
      return state;
  }
}
