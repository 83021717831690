import React, { useEffect, useState } from "react";
import Routes from "./components/Routes";
import { UidContext } from "./components/AppContext";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getUser } from "./actions/user.actions";
import { getConfigUser } from "./actions/configUser.action";
import { getConfigCh } from "./actions/configCh.action";
import { getConfigInf } from "./actions/configInf.action";



const App = () => {
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchToken = async () => {
      await axios({
        method: "get",
        url: `/api/jwtid`,
        withCredentials: true,
      })
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log("No tokenn"));
    };
    fetchToken();

    if (data && data._id) {
      dispatch(getUser(data && data._id));
      dispatch(getConfigUser())
      dispatch(getConfigCh())
      dispatch(getConfigInf())
    }else{
      dispatch(getConfigUser())
      dispatch(getConfigCh())
      dispatch(getConfigInf())
    }
  }, [data && data._id, dispatch]);

  return (
    <UidContext.Provider value={data}>
      <Routes />
    </UidContext.Provider>
  )
}

export default App;
