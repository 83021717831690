import * as React from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function RightBar() {
  return (
    <div>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        className="RightBar"
      >
        <Item className="Item">Simulation des avantages et primes</Item>
        <p className="Item-title1">Utilisateur</p>
        <Item className="Item">
          <p>
            Vous recevez des primes jusqu’à 500 TC/€ suite à la création de
            votre nouveau réseau d’ami et FP sur SoWaySo.
          </p>
        </Item>
        <p className="Item-title2">Challengeur</p>
        <Item className="Item">
          <p>
            Vous pouvez participer à notre challenge Starter et gagner une prime
            jusqu’à 3 000 TC/€ après la création de votre réseau d’ami et
            d’abonnés sur SoWaySo.
          </p>
        </Item>
        <p className="Item-title3">Influenceuse (r)</p>
        <Item className="Item">
          <p>
            SoWaySo vous offre jusqu’à 7 000 TC/€ pour chaque 100 K d’abonnés
            sur votre profil et votre page professionnel
          </p>
        </Item>
      </Stack>
    </div>
  );
}
