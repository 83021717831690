import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadPicture } from "../../actions/user.actions";

const UploadImg = () => {
  const [file, setFile] = useState();
  const [loadingImg, setLoadingImg] = useState(false);
  const [imgEmty, setImgEmty] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userReducer);

  const handlePicture = (e) => {
    e.preventDefault();
    const data = new FormData();
    // data.append("name", userData.nom);
    data.append("userId", userData && userData._id);
    data.append("file", file);
    if(file !== undefined) {
      setLoadingImg(!loadingImg)
      dispatch(uploadPicture(data, userData._id));
    }else {
      setImgEmty(!imgEmty)
    }
  };

  return (
    <>
      <input
        className="input-update-image-profile"
        type="file"
        id="file"
        name="file"
        accept=".jpg, .jpeg, .png"
        onChange={(e) => setFile(e.target.files[0])}
      />
      <br/>
      {
        !imgEmty && (
        !loadingImg  ?
         <div onClick={handlePicture} className="update-image-profile" >Envoyer</div> 
         : 
         <div className="update-image-loading-profile" >téléchargements photo ...</div> 
        )
      }
      {
        imgEmty && <div onClick={handlePicture} className="update-image-loading-profile" >Vous devez enter une image</div> 
      }
      
   </>
  );
};

export default UploadImg;
