import React , {useEffect , useState} from "react";
import { useDispatch , useSelector } from "react-redux";
import ModaleConfidentiel from "./ModaleConfidentiel"

const ModaleInformationUser = ({ openPopup , userData }) => {
  
  const usersData = useSelector((state) => state.usersReducer);

  const configuser = useSelector((state) => state.configusersReducer);
  const configch = useSelector((state) => state.configChReducer);
  const configinf = useSelector((state) => state.configInfReducer);
  
  const [reseaudami, setReseaudami] = useState(userData.friends.length);
  const [RFreindsPartner, setRFreindsPartner] = useState(userData.friendsPartner.length);
  const [RFansabonnees, setRFansabonnees] = useState(0);

  const [preseaustatus, setPreseaustatus] = useState(0);
  const [primeRFPstatus, setPrimeRFPstatus] = useState(0);
  const [primeRFAstatus, setPrimeRFAstatus] = useState(0);

  const [Confidentiel, setConfidentielModal] = useState(false);

  const calculeSouFP = () => {
   const array = userData.friendsPartner.map((val , index) => (
      usersData && usersData.filter(valeur => 
           valeur._id === val 
    )
  )).filter(valeur1 => valeur1[0]._id !==  "6373a5ce14e0fdc176fa803c")
    .map((val1 , index) => (  val1[0].friends.length ))
    .reduce( (accumulator, currentValue) => accumulator + currentValue , 0)

  return array

  }

  const handleModalsConfidentiel = (val) => {
    setConfidentielModal(true);
    // setDataToPropsModal(val)
};


  useEffect(() => {
    
    const utilisateurCondition = (configuser[0] && configuser[0].minUsers <= reseaudami) && (configuser[0] && configuser[0].maxUsers >= reseaudami)
    const ChallengerCondition = (configch[0] && configch[0].minCh <= reseaudami) && (configch[0] && configch[0].maxCh >= reseaudami)
    const InfluenceurCondition = (configinf[0] && configinf[0].minInf <= reseaudami) && (configinf[0] && configinf[0].maxInf >= reseaudami)
  
     if(utilisateurCondition){
      setPreseaustatus(reseaudami * Number( configuser[0] && configuser[0].PRAGolde))
      setPrimeRFPstatus(RFreindsPartner * Number( configuser[0] && configuser[0].nFriendPartner)  * Number( configuser[0] && configuser[0].souspartner))
      setPrimeRFAstatus(RFansabonnees * Number( configuser[0] && configuser[0].PFA))
    }else if(ChallengerCondition){
      setPreseaustatus(reseaudami * Number( configch[0] && configch[0].PRACh))
      setPrimeRFPstatus(RFreindsPartner * Number( configch[0] && configch[0].nFriendPartnerCh) * Number( configch[0] && configch[0].souspartnerCh))
      setPrimeRFAstatus(RFansabonnees * Number( configch[0] && configch[0].PFAChGold))
    }else if(InfluenceurCondition){
      setPreseaustatus(reseaudami * Number( configinf[0] && configinf[0].PRAInf))
      setPrimeRFPstatus(RFreindsPartner * Number( configinf[0] && configinf[0].nFriendPartnerInf) * Number( configinf[0] && configinf[0].souspartnerInf))
      setPrimeRFAstatus(RFansabonnees * Number( configinf[0] && configinf[0].PFAInf))
  }else{
      setPreseaustatus(reseaudami * Number( configuser[0] && configuser[0].PRAGolde))
  }
}, [ configuser , configch , configinf , reseaudami ,RFreindsPartner ,RFansabonnees ])


  return (
    <>
      <div className="popup-information-user-container">
        <div className="modal-information-user">
            <span className="information-user-cross" onClick={() => openPopup(false)}>
              &#10005;
            </span>
            <div className="copie-icone-box-title">
            <h4>Information Utilisateur</h4>
            <div className="copie-icone-box">
                <img width={13} src="./img/icons/copie.svg" alt="copie" />
            </div>
            </div>
        
        <section className="information-user-body">
          <div className="information-user-body1">
            <img className="information-user-body1-img" src={userData.picture} alt="icon" />
            <div onClick={() => {navigator.clipboard.writeText(userData.nom)}} 
                 className="information-user-body1-label">
              <h5 className="information-user-body1-label1">Nom : </h5>
              <h5 className="information-user-body1-label2">{userData.nom}</h5>
              <div className="copie-icone-box">
                <img width={13} src="./img/icons/copie.svg" alt="copie" />
              </div>
            </div>
            <div  onClick={() => {navigator.clipboard.writeText(userData.prenom)}} 
                  className="information-user-body1-label">
              <h5 className="information-user-body1-label1">Prénom : </h5>
              <h5 className="information-user-body1-label2">{userData.prenom}</h5>
              <div className="copie-icone-box">
                <img width={13} src="./img/icons/copie.svg" alt="copie" />
              </div>
            </div>
            <div  onClick={() => {navigator.clipboard.writeText(userData.naissance)}} 
                  className="information-user-body1-label">
              <h5 className="information-user-body1-label1">Date de naissance : </h5>
              <h5 className="information-user-body1-label2">{userData.naissance}</h5>
              <div className="copie-icone-box">
                <img width={13} src="./img/icons/copie.svg" alt="copie" />
              </div>
            </div>
            <div  
                  className="information-user-body1-label">
              <h5 className="information-user-body1-label1">Ville : </h5>
              <h5 className="information-user-body1-label2"></h5>
              <div className="copie-icone-box">
                <img width={13} src="./img/icons/copie.svg" alt="copie" />
              </div>
            </div>
            <div className="information-user-body1-label">
              <h5 className="information-user-body1-label1">Code postale : </h5>
              <h5 className="information-user-body1-label2"></h5>
              <div className="copie-icone-box">
                <img width={13} src="./img/icons/copie.svg" alt="copie" />
              </div>
            </div>
            <div  onClick={() => {navigator.clipboard.writeText(userData.email)}} 
                  className="information-user-body1-label">
              <h5 className="information-user-body1-label1">Email :  </h5>
              <h5 className="information-user-body1-label2">{userData.email}</h5>
              <div className="copie-icone-box">
                <img width={13} src="./img/icons/copie.svg" alt="copie" />
              </div>
            </div>
            <div  onClick={() => {navigator.clipboard.writeText(userData.mobile)}}
                  className="information-user-body1-label">
              <h5 className="information-user-body1-label1">Mobile : </h5>
              <h5 className="information-user-body1-label2">{userData.mobile}</h5>
              <div className="copie-icone-box">
                <img width={13} src="./img/icons/copie.svg" alt="copie" />
              </div>
            </div>
            <div  onClick={() => {navigator.clipboard.writeText(userData.situation)}}
                  className="information-user-body1-label">
              <h5 className="information-user-body1-label1">Situation : </h5>
              <h5 className="information-user-body1-label2">{userData.situation}</h5>
              <div className="copie-icone-box">
                <img width={13} src="./img/icons/copie.svg" alt="copie" />
              </div>
            </div>
            <div  onClick={() => {navigator.clipboard.writeText(userData.genre)}}
                  className="information-user-body1-label">
              <h5 className="information-user-body1-label1">Genre : </h5>
              <h5 className="information-user-body1-label2">{userData.genre}</h5>
              <div className="copie-icone-box">
                <img width={13} src="./img/icons/copie.svg" alt="copie" />
              </div>
            </div>
              <div  onClick={() => {navigator.clipboard.writeText(userData.langue)}}
                  className="information-user-body1-label">
                <h5 className="information-user-body1-label1">Langue : </h5>
                <h5 className="information-user-body1-label2">{userData.langue}</h5>
              <div className="copie-icone-box">
                <img width={13} src="./img/icons/copie.svg" alt="copie" />
              </div>
            </div>
          </div>
          <div className="information-user-body2">
            <div className="information-user-body21-state">
              <h5>
                  Etat d’inscription 
              </h5>
              <div className="information-user-body21-state-color"></div>
            </div>
            <div className="information-user-body22-state">
              <h5>
                  Etat d’activation
              </h5>
              <div className="information-user-body22-state-color"></div>
            </div>
            <div className="information-user-body22-inscription">
                 Inscription : {userData.naissance}
            </div>

            <div className="information-user-body22-P-box">
            <div className="information-user-body22-label">Ami P</div>
            <div className="information-user-body22-P">
                {usersData && usersData.filter((val) => val._id === (userData && userData.friends[0]))[0].nom}
            </div>
            </div>

            <div className="information-user-body22-Identifant-FP">
              <div className="information-user-body22-Identifant-FP-label">
                  Identifant FP:
              </div>
              <div className="copie-icone-box">
                <img width={13} src="./img/icons/copie.svg" alt="copie" />
              </div>
            </div>

            <div className="information-user-body22-Identifant-FP">
              <div className="information-user-body22-Identifant-FP-label">
                  Nbre d’amis :           
              </div>
              <div className="information-user-body22-Identifant-FP-valeur">
                {userData.friends.length}
              </div>
            </div>
           
            <div className="information-user-body22-Identifant-FP">
              <div className="information-user-body22-Identifant-Prime-label">
                   Prime amis :            
              </div>
              <div className="information-user-body22-Identifant-Prime-valeur">
                {preseaustatus }{" "} €
              </div>
            </div>

            <div className="information-user-body22-Identifant-FP">
              <div className="information-user-body22-Identifant-FP-label">
                   Nbre FP :             
              </div>
              <div className="information-user-body22-Identifant-FP-valeur">
                {userData.friendsPartner.length}
              </div>
            </div>

            <div className="information-user-body22-Identifant-FP">
              <div className="information-user-body22-Identifant-FP-label">
                   Nbre sous FP :              
              </div>
              <div className="information-user-body22-Identifant-FP-valeur">
                 {calculeSouFP()}
              </div>
            </div>

            <div className="information-user-body22-Identifant-FP">
              <div className="information-user-body22-Identifant-silver-label">
                   Prime FP :
              </div>
              <div className="information-user-body22-Identifant-silver-valeur">
                {primeRFPstatus}
              </div>
            </div>
          </div>
        </section>

        <section className="button-section">
              <div className="button-section1">Modifier</div>
              <div className="button-section1">Valider</div>
              <div onClick={() => handleModalsConfidentiel()}
                   className="button-section1">
                Confidentiel
              </div>
        </section>
        {Confidentiel && <ModaleConfidentiel userData={userData}  openPopup={(e) => setConfidentielModal(e)}/>}

        </div>
      </div>
    </>
  );
};

export default ModaleInformationUser;
