import React, { useState } from "react";
import RecoverPassword from "./RecoverPassword";
import axios from "axios";

const SignInForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [recoverPasswordModal, setRecoverPasswordModal] = useState(false);


  const handleModals = (e) => {
    if (e.target.id === "forgot-password") {
      setRecoverPasswordModal(true);
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const emailError = document.querySelector(".email.error");
    const passwordError = document.querySelector(".password.error");

    axios({
      method: "post",
      url: `/api/user/login`,
      withCredentials: true,
      data: {
        email,
        password,
      },
    })
      .then((res) => {
        if (res.data.errors) {
          emailError.innerHTML = res.data.errors.email;
          passwordError.innerHTML = res.data.errors.password;
        } else {
          window.location = "/";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <form action="" onSubmit={handleLogin} id="sign-up-form">
      {/* <label htmlFor="email">Email</label>
      <br /> */}
      <input
        type="text"
        name="email"
        id="email"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        placeholder="Email"
      />
      <div className="email error"></div>
      <br />
      {/* <label htmlFor="password">Mot de passe</label>
      <br /> */}
      <input
        type="password"
        name="password"
        id="password"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        placeholder="password"
      />
      <div className="password error"></div>
      <br />
      <input type="submit" value="Se connecter" />
      <h5 onClick={handleModals} id="forgot-password" className="forgot-password"> Mot de passe oublié ?</h5>
      {recoverPasswordModal && <RecoverPassword openPopup={(e) => setRecoverPasswordModal(e)}/>}
    </form>
  );
};

export default SignInForm;
