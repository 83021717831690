import React  from "react";
import { useSelector } from "react-redux";

const UserList = ({ openPopup , idPartner}) => {
    const usersData = useSelector((state) => state.usersReducer);
  return (
    <>
      <div className="popup-userlist-container">
        <div className="modal-user-list">
          <h2>Liste des utilisateur</h2>
          <span className="cross" onClick={() => openPopup(false)}>
            &#10005;
          </span>
          <hr
            style={{
              color: "#e7e9eb",
              backgroundColor: "#e7e9eb",
              height: "2px",
              marginTop: "5px",
            }}
          />
          <section className="list-amie-flex-inscr">
          <div onClick={() => (idPartner({_id : "6373a5ce14e0fdc176fa803c" , nom : "SWS" , prenom : "Partner"}) , openPopup(false))}className="list-amie-flex-inscr-box">
          <img className="list-amie-flex-inscr-img" src="./img/random-user.png" alt="icon" />
          <div className='list-amie-box-userName'>
            <span>SWS </span>
            {" "}
            <span>Partner</span>
          </div>
        </div>

          </section>

          {/* <section className="list-amie-flex-inscr">
        {usersData.filter(el => el._id === "635bace8cffe9018416a74ba").map((val , index)=>(
          <div onClick={() => (idPartner(val) , openPopup(false))} key={index} className="list-amie-flex-inscr-box">
          <img className="list-amie-flex-inscr-img" src="./img/random-user.png" alt="icon" />
          <div className='list-amie-box-userName'>
            <span>SWS </span>
            {" "}
            <span>Partner</span>
          </div>
        </div>
        ))}
          </section> */}
        </div>
      </div>
    </>
  );
};

export default UserList;

