import axios from "axios";

export const DELETE_FRIEND = "DELETE_FRIEND";



export const deleteFriend = (idFriend , idUser) => {
    return (dispatch) => {
      return axios
        .post(`/api/user/deleteFriend`, {idFriend , idUser})
    };
  };