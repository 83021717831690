import React , { useContext , useCallback } from 'react';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { UidContext } from "../AppContext";
import {Route , Routes} from 'react-router-dom';
import Home from '../../pages/Home';
import Profil from '../../pages/Profil';
import Trending from '../../pages/Trending';
import Navbar from '../Navbar';
import Acso from '../Acso';
import Fans from '../Fans';
import Groups from '../Groups';
import Multipages from '../Multipages';
import Parametrage from '../Parametrage';
import Primes from '../Primes';
import Reseaux from '../Reseaux';
import Sws from '../Sws';
import Wallet from '../Wallet';
import ProfilAmie from '../ProfilAmie'
// import SimulateurGain from "../SimulateurGain"

import TanitCoin from '../TanitCoin';
import SuivieCompte from '../TanitCoin/SuivieDeCompte';
import TransfertAmis from '../TanitCoin/TransfertAmis';
import AchatTCGold from '../TanitCoin/AchatTCGold';
import MesPrimes from '../TanitCoin/Mes-primes';
import MesTransactions from '../TanitCoin/MesTransactions';
import Convertion from '../TanitCoin/Convertions';
import MesGestionsWallet from '../TanitCoin/MesGestionsWallet';
import MesInvest from '../TanitCoin/MesInvest';
import Parametrages from '../TanitCoin/Parametrages';

import SuperAdmin from "../SuperAdmin"
import NetWorking from "../ConfigReseautage"
import ConfigurationIndex from "../SuperAdmin/Configuration"



const Index = () => {
  const data = useContext(UidContext);
  const uid = data && data._id && data.role === "user" 
  const superAdminId = data && data._id && data.role === "superAdmin" 
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    // await console.log(container);
    await console.log("container");
  }, []);

  return (
    <div>
      {uid ? <Navbar /> : <></> }
      {superAdminId ? <Navbar /> : <></> }
      {/* <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        background: {
          color: {
            value: "transparent",
          },
        },
        fpsLimit: 70,
        interactivity: {
          events: {
            onClick: {
              enable: false,
              mode: "push",
            },
            onHover: {
              enable: false,
              mode: "repulse",
            },
            resize: false,
          },
          modes: {
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 200,
              duration: 2,
            },
          },
        },
        particles: {
          color: {
            value: "#3296B8",
          },
          links: {
            color: "#3296B8",
            distance: 150,
            enable: true,
            opacity: 0.12,
            width: 1,
          },
          collisions: {
            enable: false,
          },
          move: {
            directions: "none",
            enable: true,
            outModes: {
              default: "bounce",
            },
            random: false,
            speed: 2,
            straight: false,
          },
          number: {
            density: {
              enable: false,
              area: 800,
            },
            value: 150,
          },
          opacity: {
            value: 0.12,
          },
          shape: {
            type: "circle",
          },
          size: {
            value: { min: 1, max: 5 },
          },
        },
        detectRetina: true,
      }}
    /> */}
      <Routes>
        <Route exact path="/" element={<Home />} />,
        <Route exact path="/profil" element={<Profil />} />
        <Route path="/profilAmie" element={<ProfilAmie />} />
        <Route path="/trending" element={<Trending />} />
        <Route path="#/:codeparrain" element={<Home />} />
        <Route path="/Acso" element={<Acso />} />
        <Route path="/Fans" element={<Fans />} />
        <Route path="/Groups" element={<Groups />} />
        <Route path="/Multipages" element={<Multipages />} />
        <Route path="/Parametrage" element={<Parametrage />} />
        <Route path="/Primes" element={<Primes />} />
        <Route path="/Reseaux" element={<Reseaux />} />
        <Route path="/Sws" element={<Sws />} />
        <Route path="/Wallet" element={<Wallet />} />
        {/* <Route path="/SimulateurGain" element={<SimulateurGain />} /> */}
         {/* tanitcoin */}
        {/* <Route path="/TanitCoin" element={<TanitCoin />} />
        <Route path="/suivie-compte" element={<SuivieCompte />} />
        <Route path="/Achat-TC-gold" element={<AchatTCGold />} />
        <Route path="/Transfert-entre-Amis" element={<TransfertAmis />} />
        <Route path="/Mes-primes" element={<MesPrimes />} />
        <Route path="/Mes-transactions" element={<MesTransactions />} />
        <Route path="/conversion-tc-euro" element={<Convertion />} />
        <Route path="/Mes-gestions-wallet" element={<MesGestionsWallet />} />
        <Route path="/Mes-invest" element={<MesInvest />} />
        <Route path="/Parametrages" element={<Parametrages />} /> */}
         {/* SuperAdmin */}
         <Route path="/account-configuration" element={<SuperAdmin />} />
         <Route path="/reseautage-configuration" element={<NetWorking />} />
         <Route path="/configuration" element={<ConfigurationIndex />} />
      </Routes>

    </div>
  );
};

export default Index;