import {CONFIGCH_CHALLENGER_NUMBER,  GET_CHALLENGER_NUMBER } from "../actions/configCh.action";
  
  const initialState = {};
  
  export default function configChReducer(state = initialState, action) {
    switch (action.type) {
      case GET_CHALLENGER_NUMBER :
        return action.payload;
      case CONFIGCH_CHALLENGER_NUMBER:
          return {
            ...state,
            minCh: action.payload.minCh,
            maxCh: action.payload.maxCh,
            PRACh: action.payload.PRACh,
            PFAChGold: action.payload.PFAChGold,
            PFAChSilver: action.payload.PFAChSilver,
            souspartnerCh: action.payload.souspartnerCh,
            minFanCh: action.payload.minFanCh,
            maxFanCh: action.payload.maxFanCh,
            nFriendPartnerCh: action.payload.nFriendPartnerCh
          }
      default:
        return state;
    }
  }