import axios from "axios";
export const CONFIGUSER_USER_NUMBER = "CONFIGUSER_USER_NUMBER";
export const GET_USER_NUMBER = "GET_USER_NUMBER";

export const configUser = (minUsers, maxUsers , PRASilver , PRAGolde , PFA , souspartner , minFan, maxFan, nFriendPartner) => {
  return (dispatch) => {
    return axios({
      method: "put",
      url: `/api/superAdmin/configNumberUsers`,
      data: {
        minUsers,
        maxUsers,
        PRASilver,
        PRAGolde,
        PFA,
        souspartner,
        minFan,
        maxFan,
        nFriendPartner
      },
    })
      .then((res) => {
        dispatch({
          type: CONFIGUSER_USER_NUMBER,
          payload: { minUsers, maxUsers , PRASilver , PRAGolde  , PFA  , souspartner , minFan, maxFan ,nFriendPartner},
        });
      })
      .catch((err) => console.log(err));
  };
};

export const getConfigUser = () => {
  return (dispatch) => {
    return axios
      .get(`/api/superAdmin/usersConfig`)
      .then((res) => {
        dispatch({ type: GET_USER_NUMBER, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};





