import * as React from "react";
import Box from "@mui/material/Box";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

function createData(Date, Reference, Vues, Prime, Etat) {
  return { Date, Reference, Vues, Prime, Etat };
}

const rows = [
  createData("--/--/----", " ", "vues", "0,00", " "),
  createData("--/--/----", " ", "vues", "0,00", " "),
  createData("--/--/----", " ", "vues", "0,00", " "),
];

export default function MesPrimes() {
  return (
    <Box sx={{ flexGrow: 1 }} className="Mes-Primes">
      <div className="Row-1"> Les primes du réseautage </div>
      <div className="Row-2">
        <div>Amis</div>
        <div className="Gold">0,00</div>
        <div>FP</div>
      </div>
      <div className="Row-3">
        <div>Fans</div>
        <div className="Gold">0,00</div>
        <div>0,00</div>
        <div>Vues Fp</div>
        <div>0,00</div>
      </div>
      <div className="Row-4">
        <div className="Col-Row-4">
          Les primes sur les activités Sociale
          <div className="First-Row">
            <div>Vues Amis</div>
            <div className="Gold">0,00</div>
            <div>0,00</div>
          </div>
          <div className="Second-Row">
          <div>Vues Public</div>
            <div className="Gold">0,00</div>
            <div>0,00</div>
          </div>
        </div>
        <div className="Col-Row-4">
          Les primes des vues publicitaires
          <div className="First-Row">
            <div>Vues FP</div>
            <div>Pack</div>
           
          </div>
          <div className="Second-Row">
          <div className="float-right">0,00</div>
          </div>
        </div>
      </div>

      <div className="Row-5">
        <div>Total des primes reçu sur SoWaySo</div>
        <div className="Total-Gold">0,00</div>
        <div className="Total-Sliver">0,00</div>
      </div>
      <div className="Row-6"> Suivie des vues sur mes publication </div>
      <div className="Row-7">
        {" "}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow className="Table-Row">
                <TableCell className="Table-Cell">Date</TableCell>
                <TableCell className="Table-Cell" align="right">
                  Referene
                </TableCell>
                <TableCell className="Table-Cell" align="right">
                  Vues
                </TableCell>
                <TableCell className="Table-Cell" align="right">
                  Primes
                </TableCell>
                <TableCell className="Table-Cell" align="right">
                  Etat
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.Date}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.Date}
                  </TableCell>
                  <TableCell align="right">{row.Reference}</TableCell>
                  <TableCell align="right">{row.Vues}</TableCell>
                  <TableCell align="right">{row.Prime}</TableCell>
                  <TableCell align="right">{row.Etat}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>{" "}
      </div>
    </Box>
  );
}
