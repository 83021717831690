import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { UidContext } from "./AppContext";
import Assistance from "./Assistance"
import Logout from "./Log/Logout";

const Navbar = () => {
  const uid = useContext(UidContext);
  const userData = useSelector((state) => state.userReducer);
  const [recherche, setRecherche] = useState("");
  const [texting, setTexting] = useState(false)
  {
    /* <NavLink exact="true" to="/profil">
                <h5>Bienvenue {userData.nom}</h5>
              </NavLink> */
  }

  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  useEffect(() => {
    const changeWidth = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", changeWidth);
    return () => window.removeEventListener("resize", changeWidth);
  }, []);

  return (
    <nav>
    {screenWidth > 1000 && (
      <div className="nav-container">
        <div onClick={toggleNav} className="logo">
          <NavLink exact="true" to="/">
            <div className="logo">
              <img src="./img/sowaysolab.png" alt="icon" />
            </div>
          </NavLink>
        </div>

        <div className="nav-drop-box">
          <div className="icons-flex-nav">
            <div className="nav-link">
              <img width={25} src="./img/icons/soblog.svg" alt="img-log" />
              <p className="parag-soBlog">SoBlog</p>
            </div>

            <div className="nav-link">
              <img width={25} src="./img/icons/media.svg" alt="icon" />
              <p className="parag-soMedia">SoMedia</p>
            </div>

            <div className="nav-link">
              <img width={25} src="./img/icons/ecommerce.svg" alt="icon" />
              <p className="parag-soMark">SoMark</p>
            </div>
          </div>

          {screenWidth > 650 && (
              <input
                className="input-forme-recherche-navbar"
                type="text"
                name="recherche"
                id="recherche"
                onChange={(e) => setRecherche(e.target.value)}
                value={recherche}
                placeholder="Recherche"
              />
          )}
           <div className="nav-link-globale">
            <div className="nav-link-icon">
            <NavLink exact="true" to="/">
              <div className="home-icone-navbar">
                 <img width={25} src="./img/icons/home1.svg" alt="icon" />
              </div>
            </NavLink>
            </div>

            <div className="nav-link-icon">
              <div className="home-icone-navbar">
                 <img width={25} src="./img/icons/notification.svg" alt="icon" />
              </div>
            </div>



            <div className="nav-link-icon"
                 onClick={() => setTexting(!texting)}>
              <div className="home-icone-navbar">
                 <img width={25} src="./img/icons/commenter.svg" alt="icon" />
              </div>
            </div>

            {texting ? (
                <div className="texting-box">
                   <Assistance name={userData && userData.nom} 
                   close={() => setTexting(false)}
                   />
                </div> 
                ) : <></>
            }

            <div className="nav-link-icon">
              <div className="home-icone-navbar">
                 <Logout />
              </div>
            </div>

            </div>

            {/* <div className="logo-tanitcoin" exact="true" >
               <div className="logo-tanitcoin0">€</div>
            </div> */}

            {/* <div className="logo-tanitcoin" exact="true" 
            to="/TanitCoin"
            >
                  <img className="logo-tanitcoin0" src="./img/icons/star_gold.svg" alt="icon" />
               <div className="logo-tanitcoin0">€</div>
            </div> */}
             {/* <div className="logo-tanitcoin" exact="true"> */}
                  <img className="logo-social-wolette" src="./img/icons/social-wallet.jpeg" alt="icon" />
            {/* </div> */}
        </div>
      </div>
    )
  }


{/* /////////////////////////////////////////////////////////////////////////// */}

{screenWidth < 1000 && (
      <div className="nav-container">
       <section className="first-line">
         {!toggleMenu &&
        <div className="logo-res">
          <NavLink exact="true" to="/">
              <img src="./img/sowaysolab.png" alt="icon" />
          </NavLink>
        </div>
        }
     { screenWidth < 650 &&
        ((toggleMenu) ? (
          <>
              <input
                className="input-forme-recherche-navbar-mobile"
                type="text"
                name="recherche"
                id="recherche"
                onChange={(e) => setRecherche(e.target.value)}
                value={recherche}
                placeholder="Recherche"
              />
              <span className="cross" onClick={toggleNav}>x</span>
           </>
          ) : (
            <img width={25} onClick={toggleNav} src="./img/icons/loop.svg"/>
          ))
        }

      {/* {!toggleMenu &&
          <div className="logo-tanitcoin">
            <div exact="true">
              <div className="logo-tanitcoin0">
                <img src="./img/icons/star_gold.svg" alt="icon" />
              </div>
            </div>
          </div>
        } */}


      {!toggleMenu &&
                  <img className="logo-social-wolette" src="./img/icons/social-wallet.jpeg" alt="icon" />
        }
        </section>

        <section className="first-line">
          <div className="icons-flex-nav">
            
            <div className="nav-link-res">
              <img width={16} src="./img/icons/soblog.svg" alt="img-log" />
              <p className="parag-soBlog">SoBlog</p>
            </div>

            <div className="nav-link-res">
              <img width={16} src="./img/icons/media.svg" alt="icon" />
              <p className="parag-soMedia">SoMedia</p>
            </div>

            <div className="nav-link-res">
              <img width={16} src="./img/icons/ecommerce.svg" alt="icon" />
              <p className="parag-soMark">SoMark</p>
            </div>

          </div>

          {toggleMenu || screenWidth > 650 && (
              <input
                className="input-forme-recherche-navbar"
                type="text"
                name="recherche"
                id="recherche"
                onChange={(e) => setRecherche(e.target.value)}
                value={recherche}
                placeholder="Recherche"
              />
          )}

          <div className="nav-link-globale-res">
            <div className="nav-link-icon">
            <NavLink exact="true" to="/">
            <div className="home-icone-navbar-res">
                 <img width={16} src="./img/icons/home1.svg" alt="icon" />
              </div>
            </NavLink>
            </div>

            <div className="nav-link-icon">
              <div className="home-icone-navbar-res">
                 <img width={16} src="./img/icons/notification.svg" alt="icon" />
              </div>
            </div>

            <div className="nav-link-icon">
               <div className="home-icone-navbar-res"
                   onClick={() => setTexting(!texting)} >
                 <img width={16} src="./img/icons/commenter.svg" alt="icon" />
              </div>
            </div>

            {texting ? (
                <div className="texting-box">
                   <Assistance name={userData && userData.nom} 
                   close={() => setTexting(false)}
                   />
                </div> 
                ) : <></>
            }

            <div className="nav-link-icon">
              <div className="home-icone-navbar-res">
                 <Logout />
              </div>
            </div>
          </div>
          </section>
      </div>
    )
 }
    </nav>
  );
};

export default Navbar;
