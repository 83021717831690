import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function SuivieDeCompte() {
  return (
    <Box sx={{ flexGrow: 1 }} className="suividecompte">
      <Grid container spacing={1}>
        <Grid container item spacing={3}>
        <Grid item xs={3}>
        <Item className='Item Gold-Item'>Solde Gold</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='Item Gold-Item'>0</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='Item Sliver-Item'>Solde Silver</Item>
      </Grid>
        <Grid item xs={3}>
        <Item className='Item Sliver-Item'>0</Item>
      </Grid>
        </Grid>
        <Grid container item spacing={3}>
        <Grid item xs={3}>
        <Item className='Item Gold'>Dernier opération d’achat TC</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='Item'>0</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='Item'>Date</Item>
      </Grid>
        <Grid item xs={3}>
        <Item className='Item'>0</Item>
      </Grid>
        </Grid>
        <Grid container item spacing={3}>
        <Grid item xs={3}>
        <Item className='Item Gold'>Pack AcSo</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='Item'>0</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='Item'>Publications</Item>
      </Grid>
        <Grid item xs={3}>
        <Item className='Item'>0</Item>
      </Grid>
        </Grid>
        <Grid container item spacing={3}>
        <Grid item xs={3}>
        <Item className='Item Gold'>Solde reçu TC</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='Item'>0</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='Item'>Solde reçu TC</Item>
      </Grid>
        <Grid item xs={3}>
        <Item className='Item'>0</Item>
      </Grid>
        </Grid>
        <Grid container item spacing={3}>
        <Grid item xs={3}>
        <Item className='Item Gold'>Transfert TC</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='Item'>0</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='Item'>Transfert TC</Item>
      </Grid>
        <Grid item xs={3}>
        <Item className='Item'>0</Item>
      </Grid>
        </Grid>
        <Grid container item spacing={3}>
        <Grid item xs={3}>
        <Item className='Item Gold'>Prime reçu TC</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='Item'>0</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='Item'>Prime reçu TC</Item>
      </Grid>
        <Grid item xs={3}>
        <Item className='Item'>0</Item>
      </Grid>
        </Grid>
        <Grid container item spacing={3}>
        <Grid item xs={3}>
        <Item className='Item Gold'>Mes achats SoMark</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='Item'>0</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='Item'>Mes achats SoMark</Item>
      </Grid>
        <Grid item xs={3}>
        <Item className='Item'>0</Item>
      </Grid>
        </Grid>
        <Grid container item spacing={3}>
        <Grid item xs={3}>
        <Item className='Item Gold'>Invest Golden Wallet</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='Item'>0</Item>
      </Grid>
      <Grid item xs={3}>
        <Item className='Item'>Mes ventes SoMark</Item>
      </Grid>
        <Grid item xs={3}>
        <Item className='Item'>0</Item>
      </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
