import React, { useContext , useState } from "react";
import {  useSelector} from "react-redux";
import { UidContext } from "../components/AppContext";
import LeftNav from "../components/LeftNav";
import Accueil from "../components/Accueil"
import LeftNavSuperAdmin from "../components/LeftNavSuperAdmin"
import SuperAdmin from "../components/SuperAdmin"


import searchIcon from "../styles/assets/SVGIcons/search.svg";
import chatIcon from "../styles/assets/SVGIcons/chat.svg";
import handshake from "../styles/assets/SVGIcons/handshake.svg";
import plusIcon from "../styles/assets/SVGIcons/add-user.svg";
import addpost from "../styles/assets/SVGIcons/edit-svgrepo-com.svg";
import user from "../styles/assets/SVGIcons/user.svg";
import Challengeur from "../styles/assets/SVGIcons/Challengeur.svg";
import Influencer from "../styles/assets/SVGIcons/Influencer.svg";
import sowayso from "../styles/assets/SVGIcons/sowayso.svg";
import visa from '../styles/assets/SVGIcons/visa.svg'
import masterCard from '../styles/assets/SVGIcons/masterCard.svg'
import americancard from '../styles/assets/SVGIcons/americancard.svg'
import chaticon from '../styles/assets/SVGIcons/ChatIcon.svg'
import Signup from "../components/Log/Signup";
import RecoverPassword from "../components/Log/RecoverPassword";
import SimulateurGain from "../components/SimulateurGain/index";
import axios from "axios";

import AssistanceInvestisseurs from "../components/emailAssistance/AssistanceInvestisseurs";
import AssistanceUser from "../components/emailAssistance/AssistanceUser"


const Home = () => {
  
  const configuser = useSelector((state) => state.configusersReducer);
  const configch = useSelector((state) => state.configChReducer);
  const configinf = useSelector((state) => state.configInfReducer);

  const data = useContext(UidContext);
  const uid = data && data._id && data.role === "user"
  const superAdminId = data && data._id && data.role === "superAdmin"
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [recoverPasswordModal, setRecoverPasswordModal] = useState(false);
  const [assistanceInvModal, setAssistanceInvModal] = useState(false);
  const [assistanceModal, setAssistanceModal] = useState(false);
  const [simulateurModal, setSimulateurModal] = useState(false);

  const MaxFriendUser = configuser && configuser[0] && configuser[0].maxUsers
  const MaxFPUser = configuser && configuser[0] && configuser[0].nFriendPartner
  const PRAGolde = configuser && configuser[0] && configuser[0].PRAGolde
  const PRASilver = configuser && configuser[0] && configuser[0].PRASilver
  
  const MaxFriendCh = configch && configch[0] && configch[0].maxCh
  const MaxFanCh = configch && configch[0] && configch[0].maxFanCh
  const PFanChGold = configch && configch[0] && configch[0].PFAChGold
  const PFanChSilver = configch && configch[0] && configch[0].PFAChSilver
  const PRACh = configch && configch[0] && configch[0].PRACh

  const MaxFriendInf = configinf && configinf[0] && configinf[0].maxInf
  const MaxFanInf = configinf && configinf[0] && configinf[0].maxFanInf
  const PFanInfGold = configinf && configinf[0] && configinf[0].PFAInfGold
  const PFanInfSilver = configinf && configinf[0] && configinf[0].PFAInfSilver
  const PRAInf = configinf && configinf[0] && configinf[0].PRAInf


  const handlesimulateurModal = (e) => {
    setSimulateurModal(true);
  };

  const handleAssistanceModals = (e) => {
    if (e.target.id === "assistance") {
      setAssistanceModal(true);
    }
  };

  const handleAssistanceInvModals = (e) => {
    if (e.target.id === "assistanceInv") {
      setAssistanceInvModal(true);
    }
  };

  const handleModals = (e) => {
    if (e.target.id === "forgot-password") {
      setRecoverPasswordModal(true);
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    // const emailError = document.querySelector(".email.error");
    // const passwordError = document.querySelector(".password.error");

    axios({
      method: "post",
      url: `/api/user/login`,
      withCredentials: true,
      data: {
        email,
        password,
      },
    })
      .then((res) => {
        // if (res.data.errors) {
        //   emailError.innerHTML = res.data.errors.email;
        //   passwordError.innerHTML = res.data.errors.password;
        // } else {
          window.location = "/";
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="area">
      {superAdminId ? <LeftNavSuperAdmin /> : <></>}
      {uid ? <LeftNav />: <></>}
      {uid ? <div className="home">
               <Accueil/>  
             </div>
             :
             <></>}
             
      {!uid && !superAdminId && (
          <>

{/* /////////////////////////////////////////////////////////////////////////////////// */}

<div id="Research_Approach" className="HomePage">
        <div className="top-Bar">
        <div className="search-bar">
            <img src={searchIcon} alt="searchIcon" className="searchIcon" />
            <input type="search" placeholder="Recherche" className="search" />
          </div>
          <div className="logos">
            <img
              className="sowaylogo"
              src="img/logos/SoWaySoFinal2022.png"
              alt="sowayso"
            />
            {/* <img
              className="tanitCoinlogo"
              src="img/logos/TANITCOINPNG.png"
              alt="tanitCoin"
            /> */}
          </div>
        </div>
        <div className="container-body">
        <div className="Container-1">
         <div className="left">
            <div className="title">
              VOTRE RÉSEAU SOCIAL SOLIDAIRE 
            </div>
            <div className="sub-title">
              <img src={chatIcon} alt="chat" className="chatIcon" />
              "Sur les autres plateformes je passe beaucoup de temps sans aucun
              gain"
            </div>
            <div className="sub-title0">
              SOWAYSO VALORISE VOTRE ACTIVITE PAR <br/>
              <span className="textFo">DES PRIMES DE QUALITE.</span>
            </div>
          </div>
          <div className="right">
            <div className="signup-box">
              <div>
                <button className="btn-connection"  onClick={() => setOpen(true)}>S'inscrire</button>
                {open ? <Signup closePopup={() => setOpen(false)} /> : null}   
              </div>
              <div>
                <input className="pre-inscri-input"
                       type="text"
                       name="email"
                       id="email"
                       onChange={(e) => setEmail(e.target.value)}
                       value={email}
                       placeholder="Email"/>
              </div>
              <div>
                <input
                  className="pre-inscri-input"
                  type="password"
                  name="password"
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;"
                />
              </div>
              <button className="btn-connection" onClick={handleLogin} >Se connecter</button>
              <div>
                <a onClick={handleModals} id="forgot-password"  className="Forgetpwd">
                  Mot de passe oublié ?
                </a>
              </div>
               {recoverPasswordModal && <RecoverPassword openPopup={(e) => setRecoverPasswordModal(e)}/>}
            </div>

            <div className="simulateur-box">
              <div>
                <button onClick={handlesimulateurModal}  className="btn-connection" type="submit">Simulation des gains sur SoWaySo</button>
                {simulateurModal && <SimulateurGain openPopup={(e) => setSimulateurModal(e)}/>}
              </div>
              <div className="desc">
                Grâce à votre activité sociale solidaire
                vous recevrez plus que <span>120 €</span> par jour.
              </div>
            </div>
          </div>
        </div>
        <div className="title-first">
          <span className="sowayso">SOCIAL SOLIDAIRE POUR </span> 
          <span className="SOMEDIA">UN MONDE MEILLEUR</span>
        </div>
        <div className="Container-2">
          <div className="box">
            <div className="Icon-box">
              <img src={handshake} alt="handshake" className="Icon" />
            </div>
            <div className="sous-icon"> Pour chaque</div>
            <div className="amis">
              {" "}
              <span>100</span> Nouveaux amis
            </div>
            <div>
              {" "}
              <img
                src="img/logos/arrow-down.png"
                alt="handshake"
                className="arrowdonwIcon"
              />{" "}
            </div>
            <div className="under-arrow1">
              {" "}
              Jusqu’à <p>100 €</p>
            </div>
            <div className="under-arrow-noel">
                  <div className="under-arrow-noel1">
                     <img className="under-arrow-noel11" width={60} src="./img/icons/chapeaux-noel.svg" alt="icon" />
                     <div className="under-arrow-noel12">Spécial</div>
                     <div className="under-arrow-noel13">Nöel</div>
                  </div>
                  <div className="under-arrow-noel2">x3</div>
                  <div className="under-arrow-noel3">300 €</div>
            </div>
          </div>
          <div className="box">
            <div className="Icon-box">
              <img
                src={plusIcon}
                alt="plusIcon"
                className="Icon"
                style={{ width: "70px" }}
              />
            </div>
            <div className="sous-icon"> Pour chaque</div>
            <div className="amis">
              <span>100k</span> Fans abonnés
            </div>
            <div>
              <img
                src="img/logos/arrow-down.png"
                alt="handshake"
                className="arrowdonwIcon"
              />
            </div>
            <div className="under-arrow">
              Jusqu’à <p>5000 €</p>
            </div>
          </div>
          <div className="box">
            <div className="Icon-box">
              <img
                src={addpost}
                alt="addpost"
                className="Icon"
                style={{ width: "65px" }}
              />
            </div>
            <div className="sous-icon"> Pour chaque</div>
            <div className="amis">
              <span>1000</span> Vues sur vos publications
            </div>
            <div>
              <img
                src="img/logos/arrow-down.png"
                alt="arrowdonwIcon"
                className="arrowdonwIcon"
              />
            </div>
            <div className="under-arrow">
              Jusqu’à <p>50 €</p>
            </div>
          </div>
        </div>
        
          <div className="Container-3">
          <div className="row">
            <div className="left-col">
              <img src={chaticon} alt="chat" className="chatIcon" />
              <p>
              "Je passe des dizaines de minutes et des 
               heures sur les réseaux sociaux sans 
               recevoir aucun avantage" 
              </p>
            </div> 
            <div className="right-col">
              SUR SOWAYSO VOUS RECEVEZ JUSQU’A <br/>
              <span className="TanitCoin">{" "}100 €{" "}</span> 
              POUR CHAQUE 100 NOUVEAUX AMIS 
              VOUS RECEVEZ JUSQU’A{" "}
              <span className="TanitCoin">5 000 €</span> POUR CHAQUE 
              100K DE NOUVEAUX FANS ABONNÉS SUR VOTRE 
              PROFIL ET LA PAGE PROFESSIONNELLE. 
            </div>
          </div>
          <div className="row">
            <div className="left-col">
              <img src={chaticon} alt="chat" className="chatIcon" />
              <p>
              "Je publie régulièrement des textes, 
               des photos et des mini-vidéos sans 
               aucun gain sur le nombre des vues"
              </p>
            </div>
            <div className="right-col">
              SOWAYSO VOUS OFFRE JUSQU’A
              <span className="TanitCoin"> 50 €</span> POUR CHAQUE 1 000 VUES
              SUR LES PUBLICATIONS <span className="SOBLOG">SOBLOG</span>.
            </div>
          </div>
          <div className="row">
            <div className="left-col">
              <img src={chaticon} alt="chat" className="chatIcon" />
              <p>
              "Je suis influenceur et producteur de 
               media digital, je publie mes contenus 
               sur le web et je reçois seulement 900 € 
               pour chaque million de vues "
              </p>
            </div>
            <div className="right-col">
              SUR SOWAYSO VOUS RECEVEZ UNE PRIME JUSQU’A{" "}
              <span className="TanitCoin">20 000 €</span> POUR CHAQUE MILLION 
              VUES SUR VOS PUBLICATIONS{" "}
              <span className="SOMEDIA">SOMEDIA </span>
              VLOG, VIDEO ARTISTIQUE ET CLIP MUSICAL.
            </div>
          </div>
          {/* <div className="row">
            <div className="left-col">
              <img src={chaticon} alt="chat" className="chatIcon" />
              <p>
                "Je suis un investisseur en crypto monnaies, j’ai perdu beaucoup
                d’argent à cause de la crise et j’ai observé un grand risque de
                ponzi "
              </p>
            </div>
            <div className="right-col">
              <span className="TanitCoin">TANITCOIN</span> VOUS OFFRE PLUSIEURS PLANS
              D’INVESTISSEMENTS PERFORMENTS, SÉCURISES AVEC DES 
              GARANTIES DE RENTABILITÉ JUSQU’A <span className="TanitCoin">11,96% </span>
              L’ACHAT DE GOLDEN WALLET VOUS DONNE DROIT À
              UN BONUS DE{" "}
              <span className="TanitCoin">2,76% € {" "}</span> 
              PAR VALISE D’INVESTISSEMENTS.
            </div>
          </div> */}
        </div>

        <div className="TITLE"> 
        <span className="sowayso">VOS AVANTAGES </span> 
        <span className="SOMEDIA"> STARTER </span>
        </div>
        <div className="Container-4">
          <div className="box">
            <div className="Icon-box">
              <img
                src={user}
                alt="user"
                className="Icon"
                style={{ width: "68px" }}
              />
            </div>
            <div className="label">Utilisateur </div>
            <div className="sub-box">
              <div className="first">
                Jusqu’à <p>{MaxFriendUser} Amis </p>{" "}
              </div>
              <div className="second">
                <span>{MaxFPUser}</span> FP
              </div>
            </div>
            <div className="prime-ajustement">Votre prime </div>
            <div className="Jusqua-ajustement">Jusqu’à </div>
            <div className="after-Jusqua-ajustement">1 500 € </div>
            <div className="noel">
                  <div className="under-arrow-noel1">
                     <img className="under-arrow-noel11" width={60} src="./img/icons/chapeaux-noel.svg" alt="icon" />
                     <div className="under-arrow-noel12">Spécial</div>
                     <div className="under-arrow-noel13">Nöel</div>
                  </div>
                  <div className="under-arrow-noel2">x3</div>
                  <div className="under-arrow-noel3">{Number(((MaxFriendUser * PRAGolde ) + (MaxFriendUser * PRASilver)).toString().replace(/\D/g, '') || '').toLocaleString('fr')} €</div>
                  
            </div>
          </div>
          <div className="box">
            <div className="Icon-box">
              <img
                src={Challengeur}
                alt="Challengeur"
                className="Icon"
              />
            </div>
            <div className="label">Challengeur</div>
            <div className="sub-box-beta">
              <div className="first">
                Jusqu’à 
                <p>{MaxFriendCh} Amis</p>
                <p>+</p>
                <p>{Number(MaxFanCh) / 1000} K</p>
                Fans Abonnés 
              </div>
            </div>
            <div className="prime-ajustement">Votre prime </div>
            <div className="Jusqua-ajustement">Jusqu’à </div>
            <div className="after-Jusqua-ajustement-challengeur">{(Number((MaxFriendCh * PRACh ).toString().replace(/\D/g, '') || '') + Number((MaxFanCh * PFanChGold).toString().replace(/\D/g, '') || '') + Number((MaxFanCh * PFanChSilver).toString().replace(/\D/g, '') || '')).toLocaleString('fr')} € </div>
          </div>
          <div className="box">
            {" "}
            <div className="Icon-box">
              <img
                src={Influencer}
                alt="Influencer"
                className="Icon"
              />
            </div>
            <div className="label">Influenceur </div>
            <div className="sub-box-beta1">
              <div className="first">
                Jusqu’à 
                <p>{MaxFriendInf} Amis</p>
                <p>+</p>
                <p>{Number(MaxFanInf) / 1000000} M</p>
                Fans Abonnés 
              </div>
            </div>
            <div className="prime-ajustement">Votre prime </div>
            <div className="Jusqua-ajustement">Jusqu’à </div>
            <div className="after-Jusqua-ajustement-influenceur">{(Number((MaxFriendInf * PRAInf).toString().replace(/\D/g, '') || '') + Number((MaxFanInf * PFanInfGold).toString().replace(/\D/g, '') || '') + Number((MaxFanInf * PFanInfSilver).toString().replace(/\D/g, '') || '')).toLocaleString('fr')} € </div>
          </div>
        </div>
        <div className="Container-5">
          <div className="left">
            <span>3</span>
            <span className="mois">mois</span>
          </div>
          <div className="right">
            <div>
            <span>*</span><span style={{fontWeight: "bolder"}}>Trois mois</span> de réseautage d’amis.{" "}
            </div>
            <div>
            <span>*</span><span style={{fontWeight: "bolder"}}>Six mois pour</span> la création de réseau des Fans Abonnés.{" "}
            </div>
          </div>
        </div>

         <div className="TITLE" >
         <span style={{color: "#2E8FB7"}} >POUR COMMENCER</span>
         {/* <span className="SOMEDIA">mes avantages</span> */}
            </div>
            <div className="Container-6">    
          <div className="row-1">
            Vous devez vous <span onClick={() => setOpen(true)}> inscrire</span>
            {/* <span className="TanitCoin">TanitCoin.</span> */}
          </div>
          {/* <div className="row-2">
            et commencer par{" "}
            <span className="sowayso">
             <strong> la création de votre réseau d’amis SoWaySo.</strong>
            </span>{" "}
          </div> */}
          <div className="row-3">
            {/* <div className="left">
             <div className="amont-pre-regi">5€</div>  
              <p className="amont-pre-regi-para">Frais d’inscription SoWaySo</p>
            </div> */}
            <div className="amont-pre-regi-tanitCoin">
              5.99 €  <p className="amont-pre-regi-tanitCoin-para">Frais d’inscription SoWaySo</p>
            </div>
          </div>
          <div className="amont-activer">
              et <span> ACTIVER </span> de votre compte <br/> pour la reception de vos primes.
          </div>
        </div> 
        </div>
        <div className="Container-footer">
        <div className="Container-7">
          <div className="col-1">
            <img src={sowayso} alt="sowayso" />
          </div>
          <div className="col-2">
            
            <div className="sub-col-2">
             <div className="title"
                  onClick={handleAssistanceModals}
                  id="assistance">Assistance Utilisiateur</div>
             <div className="title">Condition d’utilisation</div>
             <div className="title">Recrutement</div>
            </div>

            {assistanceModal && (
                <AssistanceUser openPopup={(e) => setAssistanceModal(e)} />
                )}

            <div className="sub-col-2">
             <div className="title"
                  onClick={handleAssistanceInvModals}
                  id="assistanceInv"
                  >Relations investisseurs</div>
             <div className="title">Certifications</div>
             <div className="title">Confidentialité</div>
            </div>

            {assistanceInvModal && (
                <AssistanceInvestisseurs openPopup={(e) => setAssistanceInvModal(e)} />
                )}

            <div className="sub-col-2">
              <span className="title">Solution de paiement accepté</span>
              <div className="cards">
               <img src={visa} alt="visa" style={{margin: 2}} />
               <img src={masterCard} alt="masterCard"  style={{margin: 2}}/>
               <img src={americancard} alt="americancard"  style={{margin: 2}} />
              </div>
              <div className="contacts">
              Des questions ? Appelez le : <p><a style={{color : "#fff"}}  target="_blank" 
              // href="https://wa.me/330228231486"
              >02 28 23 14 86</a></p>
              
              </div>
            </div>
          </div>
        </div>
        <div className="Container-7-1">
        
          <div className="Container-7-1-box">
             <a className="href-button" href="#Research_Approach">
             <img
              src="img/icons/blue-arrow-scroll.svg"
              alt="sowayso"
            />
             </a>
             <p>Tous Droits Réservés ⒸCopyright 2022 </p>
          </div>
        
        </div>
      </div>
      </div>
  
{/* /////////////////////////////////////////////////////////////////////////////////// */}
          </>
        )}
      </div>
    </>
  );
};

export default Home;
