import axios from "axios";

export const DELETE_FP = "DELETE_FP";



export const deleteFP = (idFP , idUser) => {
    return (dispatch) => {
      return axios
        .post(`/api/user/deleteFP`, {idFP , idUser})
    };
  };