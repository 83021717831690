import React , {useState} from "react";

const ModaleConfidentiel = ({ openPopup , userData }) => {

  return (
    <>
      <div className="popup-confidentiel-container">
        <div className="modal-confidentiel">
            <span className="information-user-cross" onClick={() => openPopup(false)}>
              &#10005;
            </span>
            <div className="copie-icone-box-title">
               <h4>Information Utilisateur</h4>
            </div>
        
        <section className="information-confidentiel-body">
        <div className="information-confidentiel-body1">
           <div className="information-confidentiel-body1-label">
              <h5 className="information-confidentiel-body1-label1">Nom : </h5>
              <h5 className="information-confidentiel-body1-label2">{userData && userData.nom}</h5>
            </div>
            <div className="information-confidentiel-body1-label">
              <h5 className="information-confidentiel-body1-label1">Premon : </h5>
              <h5 className="information-confidentiel-body1-label2">{userData && userData.prenom}</h5>
            </div>
        </div>     
        </section>
        <section className="information-confidentiel-body">
        <div className="information-confidentiel-body1">
           <div className="information-confidentiel-body1-label">
              <h5 className="information-confidentiel-body1-label1">IBAN : </h5>
              <h5 className="information-confidentiel-body1-label2">FR612 1234 1234 1234 1234 1234 123</h5>
            </div>
            <div className="copie-icone-box">
                <img width={13} src="./img/icons/copie.svg" alt="copie" />
            </div>
        </div>     
        </section>

        <section className="information-confidentiel-body">
        <div className="information-confidentiel-body1">
           <div className="information-confidentiel-body1-label">
              <h5 className="information-confidentiel-body1-label1">Adresse du titulaire :  </h5>
              <h5 className="information-confidentiel-body1-label2">43 rue de france, Paris, 75000 </h5>
            </div>
            <div className="copie-icone-box">
                <img width={13} src="./img/icons/copie.svg" alt="copie" />
            </div>
        </div>     
        </section>

        <section className="information-confidentiel-body">
        <div className="information-confidentiel-body1">
           <div className="information-confidentiel-body1-label">
              <h5 className="information-confidentiel-body1-label1">Adresse du banque :   </h5>
              <h5 className="information-confidentiel-body1-label2">02 rue de la reine Paris, 75000  </h5>
            </div>
            <div className="copie-icone-box">
                <img width={13} src="./img/icons/copie.svg" alt="copie" />
            </div>
        </div>     
        </section>

        <section className="information-confidentiel-body">
        <div className="information-confidentiel-body1">
           <div className="information-confidentiel-body1-label">
              <h5 className="information-confidentiel-body1-label1">Carte banciare  :   </h5>
              <h5 className="information-confidentiel-body1-label2"> 5555 5555 5555 5555</h5>
            </div>
            <div className="copie-icone-box">
                <img width={13} src="./img/icons/copie.svg" alt="copie" />
            </div>
        </div>     
        </section>

        <section className="information-confidentiel-body">
        <div className="information-confidentiel-body1">
           <div className="information-confidentiel-body1-label">
              <h5 className="information-confidentiel-body1-label1">Exp date  :   </h5>
              <h5 className="information-confidentiel-body1-label2"> 03/2024</h5>
            </div>
            <div className="copie-icone-box">
                <img width={13} src="./img/icons/copie.svg" alt="copie" />
            </div>

            <div className="information-confidentiel-body1-label">
              <h5 className="information-confidentiel-body1-label1">CVC :   </h5>
              <h5 className="information-confidentiel-body1-label2"> 524 </h5>
            </div>

            <div className="copie-icone-box">
                <img width={13} src="./img/icons/copie.svg" alt="copie" />
            </div>
        </div>      
        </section>
        
         
        </div>
      </div>
    </>
  );
};

export default ModaleConfidentiel;
