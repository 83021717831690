import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import LeftNav from "../LeftNav";
import { getUser } from "../../actions/user.actions";
import { addFP } from "../../actions/addFP.action";
import { deleteFP } from "../../actions/deleteFP.action";
// import { AddFriend } from "../../actions/addFriend.action";
import { deleteFriend } from "../../actions/deleteFriend.action";
import SimulateurGain from "../SimulateurGain/index";
import ReseauxSocial from "../Reseaux/ReseauxSocial";


export default function Reseaux() {
  const userData = useSelector((state) => state.userReducer);
  const usersData = useSelector((state) => state.usersReducer);

  const configuser = useSelector((state) => state.configusersReducer);
  const configch = useSelector((state) => state.configChReducer);
  const configinf = useSelector((state) => state.configInfReducer);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [partageModal, setPartageModal] = useState(false);
  const [simulateurModal, setSimulateurModal] = useState(false);
  const [goldPrice, setGoldPrice] = useState(0);
  const [silverPrice, setSilverPrice] = useState(0);

  const [counterFriends, setCounterFriends] = useState(0);
  const [counterFriendsPartner, setCounterFriendsPartner] = useState(0);


  const dispatch = useDispatch();

  useEffect(() => {
    setCounterFriends(userData && userData.friends && userData.friends.length);
    setCounterFriendsPartner(
      userData && userData.friendsPartner && userData.friendsPartner.length
    );
  }, [userData]);

  const handlesimulateurModal = (e) => {
    setSimulateurModal(true);
  };

  const handleFP = async (idFP, idUser) => {
    await dispatch(addFP(idFP, idUser));
    dispatch(getUser(idUser));
  };

  const handleDeleteFP = async (idFP, idUser) => {
    await dispatch(deleteFP(idFP, idUser));
    dispatch(getUser(idUser));
  };
  
  const handleDeleteFriend = async (idFriend, idUser) => {
    await dispatch(deleteFriend(idFriend, idUser));
    dispatch(getUser(idUser));
  };

  // const handleAddFriend = async (idFriend, idUser) => {
  //   await dispatch(AddFriend(idFriend, idUser));
  //   dispatch(getUser(idUser));
  // };

  const handlePartageModals = (e) => {
    if (e.target.id === "partage") {
      setPartageModal(true);
    }
  };

  useEffect(async () => {
    const reseaudami = userData && userData.friends && userData.friends.length;

    const utilisateurCondition =
      (await (configuser[0] && configuser[0].minUsers <= reseaudami)) &&
      configuser[0] &&
      configuser[0].maxUsers >= reseaudami;
    const ChallengerCondition =
      (await (configch[0] && configch[0].minCh <= reseaudami)) &&
      configch[0] &&
      configch[0].maxCh >= reseaudami;
    const InfluenceurCondition =
      (await (configinf[0] && configinf[0].minInf <= reseaudami)) &&
      configinf[0] &&
      configinf[0].maxInf >= reseaudami;

    if (utilisateurCondition) {
      setGoldPrice(reseaudami * Number(configuser[0] && configuser[0].PRAGolde));
      setSilverPrice(reseaudami * Number(configuser[0] && configuser[0].PRASilver));
    }
    if (ChallengerCondition) {
      setGoldPrice(reseaudami * Number(configch[0] && configch[0].PRACh));
    }
    if (InfluenceurCondition) {
      setGoldPrice(
        reseaudami * Number(configinf[0] && configinf[0].PRAInf)
      );
    }
  }, [configuser, configch, configinf, userData]);

  useEffect(() => {
    const changeWidth = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", changeWidth);
    return () => window.removeEventListener("resize", changeWidth);
  }, []);

  return (
    <>
      <div className="reseaux-page">
        <div className="reseaux-left-nav">
          <LeftNav />
        </div>
        {screenWidth > 1200 && (
          <>
            <div className="story">
              <div className="label-story"> Mes amis </div>
              <div className="box-story"></div>
              <div className="box-story"></div>
              <div className="box-story"></div>
              <div className="box-story"></div>
              <div className="box-story"></div>
            </div>
            <section className="flex-storybody">
              <div>
                <div className="story-body">
                  <h4 className="h41">SoWaySo</h4>
                  <span></span>
                  <span></span>
                  <h4 className="h42">Votre premier Challenge STARTER</h4>
                  <p>
                     Les 3 premiers Mois: vous devez créer votre réseau d’amis et réseau FRIEND PARTNER.
                    <br />
                    Vous recevez 50 € Gold sur chaque 100 amis ajoutée et 50 € Silver sur les réseaux d’amis de
                    vos FRIEND PARTNER.
                    <br />
                    Pendant les 6 premiers mois: vous devez crée votre réseau des Fans abonnés et Vous recevez 500 € sur chaque 10 000 Fans abonnés et jusqu’a 5 M de Fans.
                  </p>
                  <img
                    width={20}
                    src="./img/icons/share-white.svg"
                    alt="icon"
                  />
                </div>

{/* //////////////////////////////////////////////////////////////////////////// */}

                <div className="reseau-mes-amis">
                <div className="reseau-mes-amis-title-box">
                   <h4 className="reseau-mes-amis-title">Mes amis</h4>
                   <h4 className="reseau-mes-amis-count">{counterFriends}</h4>
                </div>


                <div className="reseau-mes-amis0">

                {userData &&
                    userData.friends &&
                    userData.friends
                      .map(
                        (val, index) =>
                          usersData && usersData.filter((el) => el._id === val)
                      )
                      .map((value, index1) => (
                        <div key={index1} className="reseau-mes-amis-container">
                        <div className="reseau-mes-amis-box">
                          <div className="reseau-mes-amis-img-border">
                          {console.log("test1" , value)}
                            <Link rel="canonical" to='/profilAmie' state={{ Friend : value[0]}}>
                              <img className="reseau-mes-amis-img" src={value[0].picture} alt="icon" />
                            </Link>
                          </div>
                          <h5 className="reseau-mes-amis-name">
                              <span>{value[0].nom}</span>{" "}
                              <span>{value[0].prenom}</span>
                          </h5>
                        </div>
                        <div>
                           <div onClick={() => handleFP(value[0]._id, userData && userData._id)}
                            className="reseau-mes-amis-add">Freind partner</div>
                           <div onClick={() => handleDeleteFriend(value[0]._id, userData && userData._id)}
                            className="reseau-mes-amis-delete">supprimer</div>
                        </div>
                     </div>
                      ))}

                 </div>

                </div>

{/* //////////////////////////////////////////////////////////////////////////////////// */}

                <div className="reseau-mes-amis">
                <div className="reseau-mes-amis-title-box">
                   <h4 className="reseau-mes-amis-title">Mes FRIEND PARTNER</h4>
                   <h4 className="reseau-mes-amis-count">{counterFriendsPartner}</h4>
                </div>
                

                <div className="reseau-mes-amis0">

                {userData &&
                    userData.friendsPartner &&
                    userData.friendsPartner
                      .map(
                        (val, index) =>
                          usersData && usersData.filter((el) => el._id === val)
                      )
                      .map((value, index1) => (

                        <div key={index1} className="reseau-mes-amis-container">
                        <div className="reseau-mes-amis-box">
                          <div className="reseau-mes-amis-img-border">
                          <Link rel="canonical"  to='/profilAmie' state={{ Friend : value[0]}}>
                             <img className="reseau-mes-amis-img" src={value[0].picture} alt="icon" />
                          </Link>
                          </div>
                          <h5 className="reseau-mes-amis-name">
                              <span>{value[0].nom}</span>{" "}
                              <span>{value[0].prenom}</span>
                          </h5>
                        </div>
                        <div>
                          <div onClick={() => handleDeleteFP(value[0]._id, userData && userData._id)}
                           className="reseau-mes-amis-delete-PARTNER">supprimer</div>
                        </div>
                       </div>
                      ))}
                      
                 </div>

                </div>
{/* /////////////////////////////////////////////////////////////////////////////////// */}

               <div style={{marginBottom : "100px" , display : "none"}} className="reseau-mes-amis">

               <div className="reseau-mes-amis-title-box">
                   <h4 className="reseau-mes-amis-title">Suggestions d’amis</h4>
                </div>
                

                <div className="reseau-mes-amis0">

                {usersData &&
                    usersData[0] &&
                    usersData.map((value, index1) => (
                     <div key={index1} className="reseau-mes-amis-container">
                      <div className="reseau-mes-amis-box">
                        <div className="reseau-mes-amis-img-border">
                          {console.log("test2" , value)}
                          <Link rel="canonical"  to='/profilAmie' state={{ Friend : value}}>
                           <img className="reseau-mes-amis-img" src={value.picture} alt="icon" />
                         </Link>
                        </div>
                        <h5 className="reseau-mes-amis-name">
                          <span>{value.nom}</span> <span>{value.prenom}</span>
                        </h5>
                      </div>
                      <div>
                      <div 
                        //  onClick={() => handleAddFriend(value._id, userData && userData._id)}
                         className="reseau-mes-amis-add">Ajouter</div>
                      </div>
                   </div>
                    ))}


                 </div>

                </div>
{/* ////////////////////////////////////////////////////////////////////////////////////////// */}
                

              </div>
              <div className="box-story-right-bar">
                <div>
                  <div className="Simulation-accueil-logo">
                    <img
                      width={30}
                      src="./img/icons/utilisateur-bleu.svg"
                      alt="icon"
                    />
                  </div>
                  <div onClick={handlesimulateurModal}
                       className="story-right-bar1">
                    Simulation des avantages <br /> et des primes
                  </div>
                  {simulateurModal && <SimulateurGain openPopup={(e) => setSimulateurModal(e)}/>}
                </div>
                <div className="story-right-bar2-back">
                  <div className="story-right-bar2-img">
                    <div className="story-right-bar2">
                      <div style={{ fontWeight: "bolder", marginTop: "10px" }}>
                         Votre Solde : {Number(goldPrice + silverPrice)} €
                      </div>
                      <br />
                      <div className="votre-solde-style">
                         Votre solde actuel est
                      </div>
                      <div className="montant-acc">
                        <div className="TC-Gold">
                          <div className="TC-Gold-valeur">{goldPrice}</div>
                          <div className="flex-TC-Gold">
                            <span className="flex-TC-Gold-element">€ </span>
                            <span className="flex-TC-Gold-element">Gold </span>
                          </div>
                        </div>
                        <div className="TC-Gold">
                          <div className="TC-Gold-valeur">{silverPrice}</div>
                          <div className="flex-TC-Gold">
                            <span className="flex-TC-Gold-element">€ </span>
                            <span className="flex-TC-Gold-element">
                              Silver{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
                <div className="story-right-bar3">
                  <div className="title-partage-box">Inviter mes Amis</div>
                  <div className="partage-box-acc">
                    <div
                      className="button-partage-box"
                      onClick={handlePartageModals}
                      id="partage"
                    >
                      Partager
                    </div>
                    <div
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `https://sowayso.com/#/${userData._id}`
                        );
                      }}
                    >
                      <div className="copie-icone-box">
                        <img
                          width={20}
                          src="./img/icons/copie.svg"
                          alt="copie"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="parag-partage-box">
                    Vous pouvez ajouter vos amis pour vous joindre sur SoWaySo
                  </div>
                  <br />
                  {partageModal && (
                    <ReseauxSocial openPopup={(e) => setPartageModal(e)} />
                  )}
                </div>
              </div>
            </section>
          </>
        )}

        {screenWidth < 1200 && (
          <>
            <div className="story1">
              <div className="box-story1"></div>
              <div className="box-story1"></div>
              <div className="box-story1"></div>
            </div>

            <div className="accueil-gold-ligne">
              <p className="accueil-gold-ligne-nom">Bonjour {userData.nom} </p>
              <p className="accueil-gold-ligne-label">Votre Solde : {Number(goldPrice + silverPrice)} €</p>
              <span className="accueil-gold-ligne-gold">
                {goldPrice} € Gold
              </span>
              <span className="separation">|</span>
              <span className="accueil-gold-ligne-silver">{silverPrice} € Silver</span>
            </div>

            {/* <section className="flex-res-acc">
              <div className="story-right-bar3-res">
               <div className="story-right-bar33-res">
                <div
                  className="button-partage-box-res"
                  onClick={handlePartageModals}
                  id="partage"
                >
                  {" "}
                  Partager{" "}
                </div>
                <div
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `https://sowayso.com/#/${userData._id}`
                        );
                      }}
                    >
                      <div className="copie-icone-box">
                        <img
                          width={20}
                          src="./img/icons/copie.svg"
                          alt="copie"
                        />
                      </div>
                    </div>
                  </div>
                <div className="title-partage-box-res">
                  Inviter mes Amis pour <br /> rejoindre sur SoWaySo
                </div>
                {partageModal && (
                  <ReseauxSocial openPopup={(e) => setPartageModal(e)} />
                )}
              </div>

              <div className="simulateur-box-accueil-res">
                <div className="Simulation-accueil-logo-res">
                  <img
                    width={20}
                    src="./img/icons/utilisateur-bleu.svg"
                    alt="icon"
                  />
                </div>
                <div onClick={handlesimulateurModal}
                     className="story-right-bar1-res">
                  Simulation des avantages et des primes
                </div>
                {simulateurModal && <SimulateurGain openPopup={(e) => setSimulateurModal(e)}/>}
              </div>

              
                    
            </section> */}

            <section className="flex-res-acc">
              <div className="story-right-bar3-res">
                <div className="story-right-bar33-res">
                <div
                  className="button-partage-box-res"
                  onClick={handlePartageModals}
                  id="partage"
                >
                  {" "}
                  Partager{" "}
                </div>

                <div
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `https://sowayso.com/#/${userData._id}`
                        );
                      }}
                    >
                      <div className="copie-icone-box">
                        <img
                          width={15}
                          src="./img/icons/copie.svg"
                          alt="copie"
                        />
                      </div>
                  </div>
                </div>

                {partageModal && (
                  <ReseauxSocial openPopup={(e) => setPartageModal(e)} />
                )}
              </div>

              <div className="simulateur-box-accueil-res-col">
                <div className="simulateur-box-accueil-res">
                <div className="Simulation-accueil-logo-res">
                  <img
                    width={20}
                    src="./img/icons/utilisateur-bleu.svg"
                    alt="icon"
                  />
                </div>

                <div onClick={handlesimulateurModal} className="story-right-bar1-res">
                     Simulation
                </div>
                {simulateurModal && <SimulateurGain openPopup={(e) => setSimulateurModal(e)}/>}

                </div>
              </div>



            </section>






            <section className="flex-storybody-sidebar1">
              <div>
              <div className="story-body1">
                  <h4 className="h41">SoWaySo</h4>
                  <span></span>
                  <span></span>
                  <h4 className="h42">Votre premier Challenge STARTER</h4>
                  <p>
                     Les 3 premiers Mois: vous devez créer votre réseau d’amis et réseau FRIEND PARTNER.
                    <br />
                    Vous recevez 50 € Gold sur chaque 100 amis ajoutée et 50 € Silver sur les réseaux d’amis de
                    vos FRIEND PARTNER.
                    <br />
                    Pendant les 6 premiers mois: vous devez crée votre réseau des Fans abonnés et Vous recevez 500 € sur chaque 10 000 Fans abonnés et jusqu’a 5 M de Fans.
                  </p>
                  <img
                    width={20}
                    src="./img/icons/share-white.svg"
                    alt="icon"
                  />
                </div>
              </div>
              
{/* /////////////////////////////////////////////////////////////////////////////// */}

              <div className="reseau-mes-amis-res">
              <div className="reseau-mes-amis-title-box">
                   <h4 className="reseau-mes-amis-title">Mes amis</h4>
                   <h4 className="reseau-mes-amis-count">{counterFriends}</h4>
              </div>

                <div className="reseau-mes-amis0">

                {userData &&
                    userData.friends &&
                    userData.friends
                      .map(
                        (val, index) =>
                          usersData && usersData.filter((el) => el._id === val)
                      )
                      .slice(0, 3)
                      .map((value, index1) => (
                        <div key={index1} className="reseau-mes-amis-container">
                        <div className="reseau-mes-amis-box">
                          <div className="reseau-mes-amis-img-border">
                          <Link rel="canonical"  to='/profilAmie' state={{ Friend : value[0]}}>
                             <img className="reseau-mes-amis-img" src={value[0].picture} alt="icon" />
                           </Link>
                          </div>
                          <h5 className="reseau-mes-amis-name">
                              <span>{value[0].nom}</span>{" "}
                              <span>{value[0].prenom}</span>
                          </h5>
                        </div>
                        <div>
                        <div onClick={() => handleFP(value[0]._id, userData && userData._id)}
                            className="reseau-mes-amis-add">Freind partner</div>
                        <div onClick={() => handleDeleteFriend(value[0]._id, userData && userData._id)}
                            className="reseau-mes-amis-delete">supprimer</div>
                        </div>
                     </div>
                      ))}

                 </div>

                </div>

{/* //////////////////////////////////////////////////////////////////////////////////// */}

              <div className="reseau-mes-amis-res">
                <div className="reseau-mes-amis-title-box">
                   <h4 className="reseau-mes-amis-title">Mes FRIEND PARTNER</h4>
                   <h4 className="reseau-mes-amis-count">{counterFriendsPartner}</h4>
                </div>

                <div className="reseau-mes-amis0">

                {userData &&
                    userData.friendsPartner &&
                    userData.friendsPartner
                      .map(
                        (val, index) =>
                          usersData && usersData.filter((el) => el._id === val)
                      )
                      .map((value, index1) => (

                        <div key={index1} className="reseau-mes-amis-container">
                        <div className="reseau-mes-amis-box">
                          <div className="reseau-mes-amis-img-border">
                          <Link rel="canonical"  to='/profilAmie' state={{ Friend : value[0]}}>
                           <img className="reseau-mes-amis-img" src={value[0].picture} alt="icon" />
                          </Link>
                          </div>
                          <h5 className="reseau-mes-amis-name">
                              <span>{value[0].nom}</span>{" "}
                              <span>{value[0].prenom}</span>
                          </h5>
                        </div>
                        <div>
                        <div onClick={() => handleDeleteFP(value[0]._id, userData && userData._id)}
                          className="reseau-mes-amis-delete-PARTNER">supprimer</div>
                        </div>
                       </div>
                      ))}
                      
                 </div>

                </div>
{/* /////////////////////////////////////////////////////////////////////////////////// */}

<div style={{marginBottom : "100px"}} className="reseau-mes-amis-res">
                <h4 className="reseau-mes-amis-title">Suggestions d’amis</h4>

                <div className="reseau-mes-amis0">

                {usersData &&
                    usersData[0] &&
                    usersData.map((value, index1) => (
                     <div key={index1} className="reseau-mes-amis-container">
                      <div className="reseau-mes-amis-box">
                        <div className="reseau-mes-amis-img-border">
                        <Link rel="canonical"  to='/profilAmie' state={{ Friend : value}}>
                         <img className="reseau-mes-amis-img" src={value.picture} alt="icon" />
                         </Link>
                        </div>
                        <h5 className="reseau-mes-amis-name">
                          <span>{value.nom}</span> <span>{value.prenom}</span>
                        </h5>
                      </div>
                      <div>
                      <div 
                        //  onClick={() => handleAddFriend(value._id, userData && userData._id)}
                         className="reseau-mes-amis-add">Ajouter</div>
                      </div>
                   </div>
                    ))}


                 </div>

                </div>
{/* ////////////////////////////////////////////////////////////////////////////////////////// */}

              </section>
          </>
        )}
       
      </div>
    </>
  );
}
