import axios from "axios";

export const CONFIGCH_CHALLENGER_NUMBER = "CONFIGCH_CHALLENGER_NUMBER ";
export const GET_CHALLENGER_NUMBER = "GET_CHALLENGER_NUMBER";


export const configCh = (minCh, maxCh , PRACh , PFAChSilver , PFAChGold , souspartnerCh , minFanCh, maxFanCh , nFriendPartnerCh) => {
  return (dispatch) => {
    return axios({
      method: "put",
      url: `/api/superAdmin/configNumberCh`,
      data: {
        minCh ,
        maxCh ,
        PRACh ,
        PFAChSilver ,
        PFAChGold ,
        souspartnerCh,
        minFanCh,
        maxFanCh,
        nFriendPartnerCh
      },
    })
      .then((res) => {
        dispatch({
          type: CONFIGCH_CHALLENGER_NUMBER,
          payload: { minCh, maxCh , PRACh , PFAChSilver  , PFAChGold , souspartnerCh ,minFanCh, maxFanCh , nFriendPartnerCh},
        });
      })
      .catch((err) => console.log(err));
  };
};

export const getConfigCh = () => {
  return (dispatch) => {
    return axios
      .get(`/api/superAdmin/ChConfig`)
      .then((res) => {
        dispatch({ type: GET_CHALLENGER_NUMBER , payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};
