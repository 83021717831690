import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LeftNav from "../LeftNav";
import Assistance from "../Assistance"
import { AddFriend } from "../../actions/addFriend.action";
import { getUser } from "../../actions/user.actions";


const Index = () => {
  const userData = useSelector((state) => state.userReducer);
  const location = useLocation();
  const [counterFriends] = useState(location.state.Friend.friends.length);
  const [counterFP] = useState(location.state.Friend.friendsPartner.length);
  const [partageModal, setPartageModal] = useState(false);
  const [simulateurModal, setSimulateurModal] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  

  const [texting, setTexting] = useState(false)
  const dispatch = useDispatch();



  const handleAddFriend = async (idFriend, idUser) => {
    // await dispatch(AddFriend(idFriend, idUser));
    // dispatch(getUser(idUser));
  };

  const handlesimulateurModal = (e) => {
    setSimulateurModal(true);
  };

  useEffect(() => {
    const changeWidth = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", changeWidth);
    return () => window.removeEventListener("resize", changeWidth);
  }, []);


  return (
    <>
    <div className="profil-page">
    <div >
        <LeftNav />
    </div>
    <section className="profile-amie-container">
      <div className="profile-header">
        <img className="fond-profile-img" src="./img/autumn.jpg" alt="img-log" />

        <div className="user-profile-img">
           <img className="user-profile-img1" src={location.state.Friend.picture} alt="img-log" />
        </div>
        <div className="user-profile-img">
           <img className="connection-green" src="./img/icons/connection_green.svg" alt="img-log" />
        </div>

      </div>

      <div className="profile-information">
        <div className="profile-information1">
        <div 
            onClick={() => setTexting(!texting)}
            className="button-message-amie"
            >
                    <img
                     src="./img/icons/logo-sowayso-icon.svg"
                     alt="copie"
                      /> 
        </div>
        {texting ? (
                <div className="texting-box">
                   <Assistance name={location.state.Friend.nom} close={() => setTexting(false)}/>
                </div> 
                ) : <></>
            }
          <div className="profile-information1-ajuste">{counterFriends} Amis</div>
          <div className="profile-information1-ajuste">{counterFP} FP</div>
          <div className="profile-information1-ajuste">0 Fans</div>
        </div>
        <div className="profile-information2">
          <h3> {location.state.Friend.nom} {location.state.Friend.prenom}</h3>
        </div>
        <div className="profile-information3">
          <div className="profile-information31">
            <div className="profile-information31-box">
            <div className="profile-information311"
                 onClick={handleAddFriend(location.state.Friend._id ,  userData && userData._id)}
                 id="partage">Ajouter
                
            </div>
            </div>
          </div>
          <div className="profile-information32">
            <div onClick={handlesimulateurModal} className="profile-information322">Fan</div>
          </div>
        </div>
      </div>
       
    <div className="post-profile">

      <div className="box-profile">
        <div className="fist-box-profile">

          <div className="fist-box-profile1">
            <img className="fist-box-profile11" src={location.state.Friend.picture} alt="img-log" />
            <input type="text" placeholder="Bio" className="fist-box-profile12" />
          </div>
          <div className="fist-box-profile2">
            <div className="flex-profile2">
              <img className="fist-box-profile21" src="./img/icons/video.svg" alt="camera" />
              <div className="fist-box-profile22">Live Video</div>
            </div>
            <div className="flex-profile2">
              <img className="fist-box-profile23" src="./img/icons/gallery.svg" alt="img-log" />
              <div className="fist-box-profile24">Photo/video</div>
            </div>
          </div>
          <div className="fist-box-profile3">
            <div className="button-publier">Publier</div>
          </div>
        </div>
        <div className="second-box-profile">Introduction</div>
      </div> 

      <div className="box-profile1">
        <div className="fist-box-profile1">
                  <h4 className="h41">SoWaySo</h4>
                  <span></span>
                  <span></span>
                  <h4 className="h42">Votre premier Challenge STARTER</h4>
                  <p>
                     Les 3 premiers Mois: vous devez créer votre réseau d’amis et réseau FRIEND PARTNER.
                    <br />
                    Vous recevez 50 € Gold sur chaque 100 amis ajoutée et 50 € Silver sur les réseaux d’amis de
                    vos FRIEND PARTNER.
                    <br />
                    Pendant les 6 premiers mois: vous devez crée votre réseau des Fans abonnés et Vous recevez 500 € sur chaque 10 000 Fans abonnés et jusqu’a 5 M de Fans.
                  </p>
                  <img
                    width={20}
                    src="./img/icons/share-white.svg"
                    alt="icon"
                  />
        </div>
        <div className="second-box-profile1"></div>
      </div> 

    </div>

    </section>
    </div>
    </>
  );
};

export default Index;

