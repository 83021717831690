import React, { useEffect, useState } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import io from "socket.io-client";
import { v4 as uuidv4 } from 'uuid';

 const socket = io.connect("/");

function Assistance({close , idAuh, name , picture , textToId , textToName , textToPrenom , textToPic}) {
  
  const [currentMessage, setCurrentMessage] = useState("");
  const [messageList, setMessageList] = useState([]);

  const [username] = useState(name);
  const [pictureAuh] = useState(picture);
  const [room1] = useState(idAuh + textToId);
  const [room2] = useState(textToId + idAuh);

  const joinRoom = () => {
    if (pictureAuh !== "" && username !== "" && room1 !== "" && room2 !== "") {
      socket.emit("join_room", room1);
      socket.emit("join_room", room2);
    }
  };


  const sendMessage = async () => {
    joinRoom()
    if (currentMessage !== "") {
      const messageData = {
        messageId: uuidv4(),
        room1: room1,
        room2: room2,
        author: username,
        pic: pictureAuh,
        message: currentMessage,
        time:
          new Date(Date.now()).getHours() +
          ":" +
          new Date(Date.now()).getMinutes(),
      };
       
      await socket.emit("send_message", messageData);
      setMessageList((list) => [...list, messageData]);
      setCurrentMessage("");
      
    }
  };


  useEffect(() => {
    console.log("messageList" , messageList)
    socket.on("receive_message", (data) => {
      setMessageList((list) => [...list, data]
      .filter((value, index, self) =>
              index === self.findIndex((t) => (
                     t.place === value.place && t.messageId === value.messageId
            ))
         )
      );
    });
  }, [socket]);

  return (
    <div className="chat-window">
      <div className="chat-header">
        <div className="chat-header-box">
          <div>
            {
              textToName !== undefined ? (
               <div className="list-amie-message-pic-box">
                <img className="list-amie-message-pic"
                src={textToPic}
                alt="icon"
                />
                </div>
              ):(
                <div className="list-amie-message-pic-box">
                <img className="list-amie-message-pic"
                src="./img/random-user.png"
                alt="icon"
                />
                </div>
              )
            }
             <div className="chat-header-box-connect-box">
                <div className="chat-header-box-connect"></div>
             </div>
             
          </div>
           
           {
             textToName !== undefined ?  <p>{textToName + " " + textToPrenom}</p> : <p>Message</p>
           }
          
        </div>
        <div onClick={close} className="chat-header-x">
          x
        </div>
      </div>
      <div className="chat-body">
        <ScrollToBottom className="message-container">
          {messageList.map((messageContent) => {
            return (
              <div
                className="message"
                id={username === messageContent.author ? "you" : "other"}
              >
                <div>
                  <div className="message-content">


                <div>
                   <img className="message-content-pic"
                        src={messageContent.pic}
                        alt="icon"
                   />
                </div>

                    <p>{messageContent.message}</p>
                  </div>
                  <div className="message-meta">
                    <p id="time">{messageContent.time}</p>
                    <p id="author">{messageContent.author}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </ScrollToBottom>
      </div>
      <div className="chat-footer">
        <input
          type="text"
          value={currentMessage}
          placeholder="Salut..."
          onChange={(event) => {
            setCurrentMessage(event.target.value);
          }}
          onKeyPress={(event) => {
            event.key === "Enter" && sendMessage();
          }}
        />
        <button onClick={sendMessage}>&#9658;</button>
      </div>
    </div>
  );
}

export default Assistance;
