import React , { useContext , useEffect } from 'react'
import { useDispatch } from "react-redux";
import LeftNavSuperAdmin from "../LeftNavSuperAdmin";
import ResautageBody from "./ReseautageBody";
import {  getConfigUser } from "../../actions/configUser.action";
import {  getConfigCh } from "../../actions/configCh.action";
import {  getConfigInf } from "../../actions/configInf.action";
import { UidContext } from "../AppContext";

export default function SuperAdmin() {
   const dispatch = useDispatch();
    const data = useContext(UidContext);
    const superAdminId = data && data._id && data.role === "superAdmin" 
    useEffect(() => {
             dispatch(getConfigUser())
                    .then(() => {
                        dispatch(getConfigUser())  
                    })
                    .catch((err) => {
                        console.log("err" , err)
                    });
            dispatch(getConfigCh())
                    .then(() => { 
                        dispatch(getConfigCh()) 
                    })
                    .catch((err) => {
                        console.log("err" , err)
                    });
            dispatch(getConfigInf())
                    .then(() => {
                        dispatch(getConfigInf()) 
                    })
                    .catch((err) => {
                        console.log("err" , err)
                    });
     }, []);
  return (
    <div className='SuperAdmin-page'>
        {superAdminId && 
        <>
       <div className='SuperAdmin-left-nav'>
          <LeftNavSuperAdmin />
       </div>
       <div className='SuperAdmin-container'> 
          <ResautageBody />
       </div>
       </>
       }
    </div>
  )
}
