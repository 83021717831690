import * as React from "react";
import Box from "@mui/material/Box";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

function createData(Date, Produit, Quantité, Prix, Etat) {
  return { Date, Produit, Quantité, Prix, Etat };
}

const rows = [
  createData("--/--/----", " ", " ", "0,00", " "),
  createData("--/--/----", " ", " ", "0,00", " "),
];
export default function MesTransactions() {
  return (
    <Box sx={{ flexGrow: 1 }} className="Transactions-Container">
      <div className="Row-1">Mes achats</div>
      <div className="Row-2">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow className="Table-Row">
                <TableCell className="Table-Cell">Date</TableCell>
                <TableCell className="Table-Cell" align="right">
                  Produit
                </TableCell>
                <TableCell className="Table-Cell" align="right">
                  Quantité
                </TableCell>
                <TableCell className="Table-Cell" align="right">
                  Prix
                </TableCell>
                <TableCell className="Table-Cell" align="right">
                  Etat
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.Date}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.Date}
                  </TableCell>
                  <TableCell align="right">{row.Produit}</TableCell>
                  <TableCell align="right">{row.Quantité}</TableCell>
                  <TableCell align="right">{row.Prix}</TableCell>
                  <TableCell align="right">{row.Etat}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>{" "}
      </div>
      <div className="Row-3">
        <div>Total des achats</div>
        <div className="Gold">0,00</div>
        <div className="Sliver">0,00</div>
        <div>Mon CashBack</div>
        <div className="Light">0,00</div>
      </div>
      <div className="Row-4">Mes produits en vente</div>
      <div className="Row-5">
        {" "}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow className="Table-Row">
                <TableCell className="Table-Cell">Date</TableCell>
                <TableCell className="Table-Cell" align="right">
                  Produit
                </TableCell>
                <TableCell className="Table-Cell" align="right">
                  Quantité
                </TableCell>
                <TableCell className="Table-Cell" align="right">
                  Prix
                </TableCell>
                <TableCell className="Table-Cell" align="right">
                  Etat
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.Date}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.Date}
                  </TableCell>
                  <TableCell align="right">{row.Produit}</TableCell>
                  <TableCell align="right">{row.Quantité}</TableCell>
                  <TableCell align="right">{row.Prix}</TableCell>
                  <TableCell align="right">{row.Etat}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="Row-6">
        <div>Total des ventes</div>
        <div className="C-box"><span className="Gold">0,00</span><span className="Gold-label">75%</span></div>
        <div className="C-box"><span className="Sliver">0,00</span><span className="Sliver-label">25%</span></div>
      </div>
      <div className="Row-7">Mes garanties de chiffre d’affaire sur SoWaySo</div>
      <div className="Row-8">
        <div>Quantité des Produits</div>
        <div className="Row-8-Box">0</div>
        <div>Nombre des Vues</div>
        <div className="Row-8-Box">0</div>
      </div>
      <div className="Row-9">
        <div>Total de la garantie CA</div>
        <div className="Row-Box"><span className="Gold-label">0,00</span></div>
        <div className="Row-Box"><span className="Sliver-label">0,00</span></div>
      </div>
    </Box>
  );
}
