import React , { useContext } from 'react'
import LeftNavSuperAdmin from "../LeftNavSuperAdmin";
import TableUser from "./SuperAdminBody"
import { UidContext } from "../AppContext";

export default function SuperAdmin() {
    const data = useContext(UidContext);
    const superAdminId = data && data._id && data.role === "superAdmin" 
  return (
    <div className='SuperAdmin-page'>
        {superAdminId && 
        <>
       <div className='SuperAdmin-left-nav'>
          <LeftNavSuperAdmin />
       </div>
       <div className='SuperAdmin-container'> 
          <TableUser />
       </div>
       </>
       }
    </div>
  )
}
