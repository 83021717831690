import React from 'react'
import LeftNav from "../LeftNav";

export default function Groups() {
  return (
    <div className='groups-page'>
       <div className='groups-left-nav'>
          <LeftNav/>
       </div>
       <div className='groups-container'>
          <h1>Groups</h1>  
       </div>
    </div>
  )
}

