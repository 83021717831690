import * as React from "react";
import Box from "@mui/material/Box";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

function createData(Date, Bénéficière, Devise , Valeur_TC_G, Etat) {
  return { Date, Bénéficière, Devise , Valeur_TC_G, Etat };
}

const rows = [
  createData("--/--/----", " ", " ", "0,00", " "),
  createData("--/--/----", " ", " ", "0,00", " "),
];

export default function Convertion() {
  return (
    <Box sx={{ flexGrow: 1 }} className="Conversions-Container">
      <div className="Row-1">Conversion et transfert</div>
      <div className="Row-2">
        <div className="box-container">
          <span className="Label">Solde actuel en TC Gold</span>
          <span className="Gold-box ">0,00</span>
        </div>
        <div className="box-container">
          <span className="Label">Devise de conversion</span>
          <span className="Devise-Box">€</span>
        </div>
      </div>
      <div className="Row-3">
        <div className="box-container">
          <span className="Label">Valeur de transfert</span>
          <span className="Sliver-box">0,00</span>
        </div>
        <div className="box-container">
          <span className="Label">Date d’exécution</span>
          <span className="light-box">--/--/----</span>
        </div>
      </div>
      <div className="Row-4">
        <div className="box-container">
          <span className="Label">Option de transfert</span>
          <span className="light-box">SEPA</span>
          <span className="light-box">SWIFT</span>
        </div>
        <div className="box-container">
        <span className="Label">Bénéficière</span>
          <span className="light-box">IBAN</span>
        </div>
      </div>
      <div className="Row-5">
      <div className="box-container">
          <span className="Label">Vérification</span>
          <span className="light-box">ID</span>
          <span className="light-box">Mot de passe</span>
        </div>
        <div className="box-container">
        <span className="Label-bis">Biométrique</span>
          <button className="Validate-button">Valider le transfert</button>
        </div>
      </div>
      <div className="Row-6">Suivie des transferts en compte bancaire </div>
      <div className="Row-7">  <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow className="Table-Row">
                <TableCell className="Table-Cell">Date</TableCell>
                <TableCell className="Table-Cell" align="right">
                Bénéficière
                </TableCell>
                <TableCell className="Table-Cell" align="right">
                Devise
                </TableCell>
                <TableCell className="Table-Cell" align="right">
                Valeur TC G
                </TableCell>
                <TableCell className="Table-Cell" align="right">
                  Etat
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.Date}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.Date}
                  </TableCell>
                  <TableCell align="right">{row.Bénéficière}</TableCell>
                  <TableCell align="right">{row.Devise}</TableCell>
                  <TableCell align="right">{row.Valeur_TC_G}</TableCell>
                  <TableCell align="right">{row.Etat}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>{" "}</div>
    </Box>
  );
}
